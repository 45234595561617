var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Level } from "@iter-acillba/core";
import SearchSelect from "../../../components/SearchSelect";
import { SiteComponent, connect } from "../../structure";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "../../../structure";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { openPopup } from "../../store/actions";
import { encodeText } from "../../../utils";
var Relation = /** @class */ (function (_super) {
    __extends(Relation, _super);
    function Relation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showForm: false,
            selectedId: null,
            relation: {},
            relations: [],
            characters: [],
        };
        _this.toggleForm = function (show, is_add) {
            if (is_add === void 0) { is_add = false; }
            return function () {
                var user_id = _this.props.character.user_id;
                var _a = _this.props.state.root.user, id = _a.id, level = _a.level;
                var auth = id === user_id || (level && level >= Level.STAFF);
                var selectedId = _this.state.selectedId;
                if (!auth) {
                    _this.dispatch(toast({ text: "권한이 없습니다." }));
                }
                var relation = {};
                if (!is_add && !!show) {
                    relation = _this.state.relations.find(function (v) { return v.id === selectedId; });
                }
                _this.setState({
                    showForm: show,
                    selectedId: is_add ? null : selectedId,
                    relation: relation,
                });
            };
        };
        _this.delete = function () {
            _this.dispatch(openPopup({
                message: (_jsxs(_Fragment, { children: ["\uAD00\uACC4\uB97C \uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?", _jsx("br", {}), " \uC0AD\uC81C\uB41C \uB0B4\uC6A9\uC740 \uBCF5\uAD6C\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."] })),
                type: "confirm",
                confirmFunction: function (res) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!res)
                                    return [2 /*return*/];
                                return [4 /*yield*/, this.callAPI({
                                        url: "/api/character/relation/" + this.state.selectedId,
                                        method: "delete",
                                    })];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, this.updateData()];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); },
            }));
        };
        _this.selectItem = function (id) { return function () {
            var selectedId = _this.state.selectedId === id ? null : id;
            _this.setState({ selectedId: selectedId, showForm: false });
        }; };
        return _this;
    }
    Relation.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Relation.prototype.updateData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var relations, _a, characters;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/character/relation/" + this.props.character.id,
                            method: "get",
                        })];
                    case 1:
                        relations = _b.sent();
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/character/relationCharacter",
                                method: "get",
                            }, true, true)];
                    case 2:
                        _a = ((_b.sent()) || {}).data, characters = _a === void 0 ? [] : _a;
                        this.setState({ relations: relations, characters: characters });
                        return [2 /*return*/];
                }
            });
        });
    };
    Relation.prototype.render = function () {
        var user_id = this.props.character.user_id;
        var _a = this.props.state.root.user, id = _a.id, level = _a.level;
        var _b = this.state, showForm = _b.showForm, selectedId = _b.selectedId;
        var auth = id === user_id || (level && level >= Level.STAFF);
        return (_jsxs("div", __assign({ className: "relation-container" }, { children: [auth ? (_jsx("div", __assign({ className: "add-relation" }, { children: showForm && !selectedId ? (this.form()) : (_jsxs("div", __assign({ className: "add-button", onClick: this.toggleForm(true, true) }, { children: ["\uC0C8\uB85C\uC6B4 \uAD00\uACC4 \uCD94\uAC00", _jsx(AddIcon, {})] }))) }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "relations" }, { children: this.relation() }))] })));
    };
    Relation.prototype.relation = function () {
        var _this = this;
        var user_id = this.props.character.user_id;
        var _a = this.props.state.root.user, id = _a.id, level = _a.level;
        var _b = this.state, showForm = _b.showForm, selectedId = _b.selectedId, relations = _b.relations;
        var characters = __spreadArray(__spreadArray([], __read(this.props.state.root.characters), false), __read(this.state.characters), false);
        var auth = id === user_id || (level && level >= Level.STAFF);
        if (relations.length < 1) {
            return _jsx("div", __assign({ className: "no-relation" }, { children: "\uB4F1\uB85D\uB41C \uAD00\uACC4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." }));
        }
        return relations.map(function (_a, i) {
            var id = _a.id, target_id = _a.target_id, title = _a.title, description = _a.description;
            var character = characters.find(function (v) { return v.id === target_id; });
            if (!character)
                return _jsx("div", {}, i);
            return (_jsx("div", __assign({ className: "relation ".concat(selectedId === id ? "show" : "") }, { children: showForm && selectedId === id ? (_this.form()) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "summary", onClick: _this.selectItem(id) }, { children: [_jsx("img", { className: "character-image", src: character.battle_img, alt: "\uCE90\uB9AD\uD130\uC774\uBBF8\uC9C0" }), _jsx("div", __assign({ className: "title" }, { children: title })), _jsxs("div", __assign({ className: "character-name" }, { children: [_jsx("div", __assign({ className: "text" }, { children: character.name })), _jsx("div", __assign({ className: "eng" }, { children: character.eng_name }))] }))] })), _jsxs("div", __assign({ className: "desc" }, { children: [_jsx("p", { dangerouslySetInnerHTML: { __html: encodeText(description) } }), auth ? (_jsxs("div", __assign({ className: "update-icon" }, { children: [_jsxs("span", __assign({ onClick: _this.toggleForm(true) }, { children: ["\uC218\uC815", _jsx(DriveFileRenameOutlineIcon, {})] })), " ", _jsxs("span", __assign({ onClick: _this.delete }, { children: ["\uC0AD\uC81C", _jsx(DeleteForeverIcon, {})] }))] }))) : (_jsx(_Fragment, {}))] }))] })) }), i));
        });
    };
    Relation.prototype.submitForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var character_id, _a, id, relation;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        character_id = this.props.character.id;
                        _a = this.state.relation, id = _a.id, relation = __rest(_a, ["id"]);
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/character/relation/".concat(id ? "".concat(id) : ""),
                                method: "post",
                                data: __assign(__assign({}, relation), { character_id: character_id }),
                            })];
                    case 1:
                        _b.sent();
                        this.toggleForm(false, !id)();
                        return [4 /*yield*/, this.updateData()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Relation.prototype.form = function () {
        var _this = this;
        var relation = this.state.relation;
        return (_jsx("div", __assign({ className: "relation-form" }, { children: _jsxs("form", __assign({ onSubmit: function (e) {
                    e.preventDefault();
                    _this.submitForm();
                } }, { children: [_jsxs("div", __assign({ className: "input" }, { children: [_jsx("label", __assign({ className: "form-label", htmlFor: "target_id" }, { children: "\uCE90\uB9AD\uD130" })), _jsx(SearchSelect, { id: "target_id", className: "form-control", api: "character/relationCharacter", required: true, selected: relation.target_id, disallowCreate: true, params: {
                                    order: "name",
                                    sort: "ASC",
                                }, onSelect: function (id) {
                                    _this.setState({
                                        relation: __assign(__assign({}, relation), { target_id: id }),
                                    });
                                } })] })), _jsxs("div", __assign({ className: "input" }, { children: [_jsx("label", __assign({ className: "form-label", htmlFor: "order" }, { children: "\uC815\uB82C\uC21C\uC11C" })), _jsx("input", { type: "number", title: "order", id: "order", name: "order", value: relation.order, onChange: this.inputState("relation"), className: "form-control", min: -999, max: 999 })] })), _jsxs("div", __assign({ className: "input" }, { children: [_jsx("label", __assign({ className: "form-label", htmlFor: "title" }, { children: "\uAD00\uACC4\uBA85(\uCD5C\uB300 20\uC790)" })), _jsx("input", { type: "text", title: "title", id: "title", value: relation.title, name: "title", required: true, onChange: this.inputState("relation"), className: "form-control", maxLength: 20 })] })), _jsxs("div", __assign({ className: "input" }, { children: [_jsx("label", __assign({ className: "form-label", htmlFor: "description" }, { children: "\uAD00\uACC4 \uC0C1\uC138" })), _jsx("textarea", { title: "description", id: "description", value: relation.description, name: "description", onChange: this.inputState("relation"), className: "form-control" })] })), _jsxs("div", __assign({ className: "buttons" }, { children: [_jsx("button", __assign({ type: "submit" }, { children: "\uBC18\uC601" })), _jsx("button", __assign({ type: "button", onClick: function () { return _this.setState({ showForm: false }); } }, { children: "\uCDE8\uC18C" }))] }))] })) })));
    };
    return Relation;
}(SiteComponent));
export default connect()(Relation);
