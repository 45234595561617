var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import moment from "moment";
import { useEffect, useState } from "react";
var HIDE_TIIMEOUT = 3000;
export function ChatBubble(_a) {
    var userId = _a.userId, chats = _a.chats;
    var userChat = (chats === null || chats === void 0 ? void 0 : chats.filter(function (c) { return c.userId === userId; })) || [];
    var lastChat = userChat[userChat.length - 1];
    var _b = __read(useState(), 2), chat = _b[0], setChat = _b[1];
    var _c = __read(useState(false), 2), show = _c[0], setShow = _c[1];
    var _d = __read(useState(), 2), timeoutId = _d[0], setTimeoutId = _d[1];
    var _e = __read(useState(false), 2), fade = _e[0], setFade = _e[1];
    useEffect(function () {
        if (!lastChat)
            return;
        var lastTime = moment(lastChat.timestamp);
        var currentTime = moment().subtract(HIDE_TIIMEOUT, "ms");
        if (lastChat.timestamp !== (chat === null || chat === void 0 ? void 0 : chat.timestamp)) {
            if (lastTime.isBefore(currentTime)) {
                return;
            }
            setChat(lastChat);
        }
    }, [userChat]);
    useEffect(function () {
        if (!chat)
            return;
        setShow(true);
        setFade(true);
        setTimeout(function () {
            setFade(false);
        }, 300);
        var timeout = setTimeout(function () {
            setShow(false);
        }, HIDE_TIIMEOUT);
        clearTimeout(timeoutId);
        setTimeoutId(timeout);
    }, [chat]);
    useEffect(function () {
        if (show)
            return;
        setFade(true);
        setTimeout(function () {
            setFade(false);
        }, 300);
    }, [show]);
    if (!chat || (!show && !fade)) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: "chat-bubble top ".concat(fade ? (show ? "fadein" : "fadeout") : "") }, { children: _jsx("div", __assign({ className: "bubble" }, { children: chat.message })) })));
}
