var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChatList } from "./ChatList";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ChatBubble } from "./ChatBubble";
import "./standby.scss";
import SkillSlot from "../Skill/SkillSlot";
export function Standby(_a) {
    var toggleReady = _a.toggleReady, ready = _a.ready, stage = _a.stage, place = _a.place, skills = _a.skills, start = _a.start, exit = _a.exit, isLeader = _a.isLeader, disableStart = _a.disableStart, players = _a.players, chats = _a.chats, focus = _a.focus, onSubmit = _a.onSubmit;
    var leader = players.find(function (p) { return p.isLeader; });
    return (_jsxs("div", __assign({ className: "standby-container" }, { children: [_jsx("div", { className: "bg", style: { backgroundImage: "url(".concat(place.background_image, ")") } }), _jsxs("div", __assign({ className: "contents" }, { children: [_jsxs("div", __assign({ className: "standby-left" }, { children: [_jsxs("div", __assign({ className: "info" }, { children: [_jsx("div", __assign({ className: "title" }, { children: stage.name })), _jsxs("div", __assign({ className: "leader" }, { children: [_jsx("div", __assign({ className: "badge" }, { children: "\uB9AC\uB354" })), _jsx("div", __assign({ className: "name" }, { children: leader === null || leader === void 0 ? void 0 : leader.character.nickname }))] }))] })), _jsx("div", __assign({ className: "chat-list" }, { children: _jsx(ChatList, { focus: focus, chats: chats, onSubmit: onSubmit }) }))] })), _jsxs("div", __assign({ className: "standby-right" }, { children: [_jsx("div", __assign({ className: "player-list" }, { children: players.map(function (_a, i) {
                                    var character = _a.character, isReady = _a.isReady, user = _a.user;
                                    var characterSkill = character.skills
                                        .map(function (id) {
                                        var skill = skills.find(function (s) { return s.id === id; });
                                        if (!skill)
                                            return;
                                        return __assign(__assign({}, skill), { character_id: character.id, activated: 1, skill_id: skill.id });
                                    })
                                        .filter(function (v) { return !!v; });
                                    return (_jsxs("div", __assign({ className: "player" }, { children: [_jsxs("div", __assign({ className: "info" }, { children: [_jsx("div", __assign({ className: "badge ".concat(isReady ? "ready" : "") }, { children: isReady ? "준비완료" : "대기중" })), _jsxs("div", __assign({ className: "name" }, { children: [_jsx("img", { className: "emblem", src: "/static/img/emblem_".concat(character.job, ".png"), alt: character.job }), character.nickname] })), _jsx("div", __assign({ className: "skills" }, { children: _jsx(SkillSlot, { skills: characterSkill, level: 0 }) }))] })), _jsxs("div", __assign({ className: "portrait" }, { children: [_jsx(ChatBubble, { userId: user.id, chats: chats, placement: "top" }), _jsx("img", { className: "img", src: character.battle_img, alt: character.nickname })] }))] }), i));
                                }) })), _jsxs("div", __assign({ className: "buttons" }, { children: [_jsxs("div", { children: [_jsxs("button", __assign({ onClick: toggleReady, className: "".concat(ready ? "active" : "", " ready") }, { children: [_jsx("img", { src: "/static/img/battle_btn_ready.png", alt: "\uC900\uBE44\uC644\uB8CC" }), "\uC900\uBE44 \uC644\uB8CC"] })), _jsx(OverlayTrigger, __assign({ placement: "bottom", overlay: disableStart ? (_jsx(Tooltip, { children: isLeader
                                                        ? "모든 캐릭터가 준비되지 않았습니다."
                                                        : "파티의 리더만 시작 가능합니다." })) : (_jsx(_Fragment, {})) }, { children: _jsxs("button", __assign({ onClick: function () { return disableStart || start(); }, className: "start ".concat(!disableStart ? "active" : "") }, { children: [_jsx("img", { src: "/static/img/battle_btn_start.png", alt: "\uC2DC\uC791" }), "\uC804\uD22C \uC2DC\uC791"] })) }))] }), _jsxs("button", __assign({ onClick: function () { return exit(); }, className: "exit" }, { children: [_jsx("img", { src: "/static/img/battle_btn_exit.png", alt: "\uD1F4\uC7A5" }), "\uB098\uAC00\uAE30"] }))] }))] }))] }))] })));
}
