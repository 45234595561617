var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { connect } from "../structure";
import { useDispatch } from "react-redux";
import { closePopup } from "../store/actions";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";
function Popup() {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.root.popup; }), message = _a.message, type = _a.type, confirmFunction = _a.confirmFunction, numberRange = _a.numberRange;
    var _b = numberRange || { min: 1, max: 999 }, min = _b.min, max = _b.max;
    var _c = __read(useState(min), 2), number = _c[0], setNumber = _c[1];
    var buttonFn = function (res) { return function () {
        dispatch(closePopup());
        if (confirmFunction) {
            confirmFunction(res, number);
        }
    }; };
    useEffect(function () {
        setNumber(min);
    }, [message]);
    if (!message)
        return _jsx(_Fragment, {});
    return (_jsx("div", __assign({ className: "popup-container" }, { children: _jsxs("div", __assign({ className: "popup ".concat(type === "numberConfirm" ? "numberConfirm" : "") }, { children: [_jsxs("div", __assign({ className: "popup-contents" }, { children: [message, type === "numberConfirm" ? (_jsxs("div", __assign({ className: "number-confirm" }, { children: ["\uC218\uB7C9\uC744 \uC120\uD0DD\uD558\uC138\uC694", _jsxs("div", __assign({ className: "numbe-form" }, { children: [_jsx("span", __assign({ role: "button", className: "min", onClick: function () { return setNumber(min); } }, { children: "\uCD5C\uC18C" })), _jsx("span", __assign({ role: "button", onClick: function () { return setNumber(Math.max(min, number - 1)); } }, { children: _jsx(KeyboardArrowLeftIcon, {}) })), _jsx("input", { type: "number", value: number, onChange: function (e) { return setNumber(+e.target.value); } }), _jsx("span", __assign({ role: "button", onClick: function () { return setNumber(Math.min(max, number + 1)); } }, { children: _jsx(KeyboardArrowRightIcon, {}) })), _jsx("span", __assign({ role: "button", className: "max", onClick: function () { return setNumber(max); } }, { children: "\uCD5C\uB300" }))] }))] }))) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "popup-footer" }, { children: [_jsx("span", __assign({ className: "button", onClick: buttonFn(true) }, { children: "\uD655\uC778" })), type === "confirm" || type === "numberConfirm" ? (_jsx("span", __assign({ className: "button", onClick: buttonFn(false) }, { children: "\uCDE8\uC18C" }))) : ("")] }))] })) })));
}
export default connect()(Popup);
