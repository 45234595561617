import { Battle } from ".";
import { DBRecord } from "./common";
import { ItemReward } from "./item";

export type Stage<T = StageReward[]> = {
  name: string;
  open_timestamp: string;
  close_timestamp: string;
  place_id: number;
  bgm_id: number;
  description: string;
  reward: T;
  blocks?: Block[];
  place?: Place;
  primary: number;
} & DBRecord;

export type Block<T = number> = {
  stage_id: number;
  type: string;
  name: string;
  is_start: 0 | 1;
  is_end: 0 | 1;
  top: T;
  right: T;
  bottom: T;
  left: T;
  events?: Partial<ActiveEventEncounter>[];
} & DBRecord;
export type ActiveBlock = Block<Block>;

export type StageReward = ItemReward;

export type EventEncounter = {
  block_id: number;
  percentage: number;
  event_id: number;
  limit_type: "day" | "ever";
  limit: number; // 발생하는 이벤트 최대치, key 이벤트에는 적용되지 않음. 0 설정하면 제한 없음
} & DBRecord;

type ExploreStatus = Battle.Status & {
  buff?: Partial<Battle.Status>;
};

export type Instance<T = number[], U = string[], V = ExploreStatus> = {
  user_id: number;
  start_timestamp: string;
  end_timestamp: string;
  state: "start" | "fail" | "success";
  character_id: number;
  stage_id: number;
  is_test: 0 | 1;
  place_id: number;
  bgm_id: number;
  battle_id: number;
  encounter_id: number;
  event_id: number;
  name: string;
  paths: T;
  keys: U;
  trap_timestamp: string;
  trap_time: number;
  status: V;
} & DBRecord;

export type ActiveEventEncounter = EventEncounter &
  Event<string> & { event_id: number };

export type Place = {
  name: string;
  background_image: string;
  bgm_id: number;
} & DBRecord;

export enum EventType {
  NONE = "none",
  MOVE = "move",
  ITEM = "item",
  COMBAT = "combat",
  KEY = "key",
  TRAP = "trap",
  QUEST = "quest",
}

export type Direction = "left" | "top" | "bottom" | "right";

export const eventTypes: { [key in EventType]: string } = {
  [EventType.NONE]: "일반",
  [EventType.MOVE]: "이동",
  [EventType.ITEM]: "아이템",
  [EventType.COMBAT]: "전투",
  [EventType.KEY]: "열쇠",
  [EventType.TRAP]: "함정",
  [EventType.QUEST]: "퀘스트",
};

export type EventData =
  | MoveEventData
  | ItemEventData
  | CombatEventData
  | TrapEventData
  | QuestEventData;

export type MoveEventData = {
  move: string; // x,y or start or end or prev
};

export type ItemEventData = {
  item_id: number;
  amount: number;
};

export type CombatEventData = {
  stage_id: number;
};

export type KeyEventData = {
  key: string;
  type: "obtain" | "use";
  event_id: number;
};

export type TrapEventData = {
  lost_hp: number;
  release_member: number;
  release_timeout: number; // second 단위
};

export type QuestEventData = {
  type: "finish" | "accept";
  quest_id: number;
};

export type EventScript = {
  character_id?: number;
  text?: string;
  comments?: EventComment[];
};

export type EventComment = {
  text: string;
  result?: "end" | "continue" | number;
};

export type Event<T = EventScript[]> = {
  name: string;
  description: string;
  script: T;
  type: EventType | string;
  data: any;
} & DBRecord;

export type EventCharacter = {
  name: string;
  idle: string;
  smile: string;
  sad: string;
  upset: string;
  surprise: string;
} & DBRecord;
