var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "reflect-metadata";
var REQUIRED_STATE = Symbol("required_state");
var USE_STATE = Symbol("use_state");
var RENDER_ITEM = Symbol("render_item");
export function Component(constructor) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            {
                var requiredStates = Reflect.getMetadata(REQUIRED_STATE, _this) || [];
                var states = requiredStates.reduce(function (acc, tar) {
                    acc[tar] = null;
                    return acc;
                }, {});
                _this.state = __assign(__assign({}, states), _this.state);
            }
            {
                var useStates = Reflect.getMetadata(USE_STATE, _this) || [];
                useStates.forEach(function (_a) {
                    var _b = __read(_a, 3), property = _b[0], state = _b[1], index = _b[2];
                    var component = _this;
                    var method = component[property];
                    component[property] = function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        var component = this;
                        args.splice(index, 1, [
                            component.state[state],
                            function (value) {
                                var _a;
                                return component.setState((_a = {}, _a[state] = value, _a));
                            },
                        ]);
                        return method.apply(this, args);
                    };
                });
            }
            {
                var renders_1 = Reflect.getMetadata(RENDER_ITEM, _this) || [];
                var renderMethod_1 = _this.render;
                _this.render = function () {
                    var _this = this;
                    var component = this;
                    return (_jsxs(_Fragment, { children: [renderMethod_1.call(this), renders_1.map(function (prop) {
                                return __assign(__assign({}, component[prop].call(_this)), { key: prop });
                            })] }));
                };
            }
            return _this;
        }
        return class_1;
    }(constructor));
}
export function Render(target, propertyKey) {
    var renders = Reflect.getMetadata(RENDER_ITEM, target) || [];
    renders.push(propertyKey);
    Reflect.defineMetadata(RENDER_ITEM, renders, target);
}
export function State(name) {
    return function (target, propertyKey, parameterIndex) {
        var states = Reflect.getOwnMetadata(REQUIRED_STATE, target) || [];
        var useStates = Reflect.getOwnMetadata(USE_STATE, target) || [];
        var stateName = name || Symbol();
        if (states.indexOf(stateName) < 0) {
            states.push(stateName);
        }
        useStates.push([propertyKey, stateName, parameterIndex]);
        Reflect.defineMetadata(REQUIRED_STATE, states, target);
        Reflect.defineMetadata(USE_STATE, useStates, target);
    };
}
function findState(state, stateName) {
    var dir = stateName.split(".");
    return dir.reduce(function (acc, tar) {
        if (!acc)
            return acc;
        return acc[tar];
    }, state);
}
export function WatchState(state) {
    return function (target, propertyKey, _) {
        var updateFn = target.componentDidUpdate;
        target.componentDidUpdate = function (prevProps, prevState) {
            updateFn === null || updateFn === void 0 ? void 0 : updateFn.call(this, prevProps, prevState);
            if (this[propertyKey]) {
                var watchFn = this[propertyKey];
                var prev = findState(prevState, state);
                var current = findState(this.state, state);
                if (prev !== current)
                    watchFn.call(this, current, prev);
            }
        };
    };
}
export function WatchProp(prop) {
    return function (target, propertyKey, _) {
        var updateFn = target.componentDidUpdate;
        target.componentDidUpdate = function (prevProps, prevState) {
            updateFn === null || updateFn === void 0 ? void 0 : updateFn.call(this, prevProps, prevState);
            if (this[propertyKey]) {
                var watchFn = this[propertyKey];
                var prev = findState(prevProps, prop);
                var current = findState(this.props, prop);
                if (prev !== current)
                    watchFn.call(this, current, prev);
            }
        };
    };
}
