var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { SiteComponent, connect } from "../structure";
import "./character.scss";
import CharacterView from "./CharacterView";
import CharacterList from "./CharacterList";
import { previewPage } from "../store/actions";
var PreviewPage = /** @class */ (function (_super) {
    __extends(PreviewPage, _super);
    function PreviewPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            characters: [],
            character: undefined,
            selected: 0,
        };
        _this.selectCharacter = function (id) {
            _this.setState({ selected: id });
        };
        _this.deSelectCharacter = function () {
            _this.setState({ selected: 0 });
        };
        return _this;
    }
    PreviewPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dispatch(previewPage());
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/character/applications",
                            }, true, true)];
                    case 1:
                        data = _a.sent();
                        this.setState({ characters: data });
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewPage.prototype.render = function () {
        var _this = this;
        if (!this.state.characters || this.state.characters.length < 1) {
            return (_jsx("div", __assign({ className: "page-404" }, { children: "\uC2E0\uCCAD\uC11C \uC81C\uCD9C \uC644\uB8CC \uD6C4 \uBBF8\uB9AC\uBCF4\uAE30\uAC00 \uAC00\uB2A5\uD569\uB2C8\uB2E4." })));
        }
        return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "page-title" }, { children: [_jsx("div", { className: "title-icon" }), _jsx("div", __assign({ className: "title-text" }, { children: "CHARACTER" }))] })), _jsx("div", __assign({ className: "character-container" }, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/preview/:id", exact: true, render: function (props) { return (_jsx(CharacterView, __assign({}, props, { character: _this.getCharacter(), select: _this.selectCharacter }))); } }), _jsx(Route, { path: "/preview/", render: function (props) { return (_jsx(CharacterList, __assign({}, props, { path: "/preview", select: _this.selectCharacter, deselect: _this.deSelectCharacter, character: _this.getCharacter(), characters: _this.state.characters }))); } })] }) }))] }));
    };
    PreviewPage.prototype.getCharacter = function () {
        var _this = this;
        return this.state.characters.find(function (c) { return +c.id === +_this.state.selected; });
    };
    return PreviewPage;
}(SiteComponent));
export default connect()(PreviewPage);
