import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
export default function BGM(_a) {
    var bgm = _a.bgm, pause = _a.pause;
    if (!bgm || pause)
        return _jsx(_Fragment, {});
    var url = bgm.url, youtube = bgm.youtube;
    if (youtube) {
        var src = "https://www.youtube.com/embed?listType=playlist&mute=0&list=".concat(youtube, "&autoplay=1&disablekb=1&loop=1&playsinline=1&rel=0");
        return (_jsx("iframe", { title: "youtube", className: "bgm", id: "ytplayer", width: "100%", height: "360", src: src, allow: "autoplay; encrypted-media", allowFullScreen: true }));
    }
    if (url) {
        return (_jsx("audio", { className: "bgm", loop: true, src: url, autoPlay: true }));
    }
    return _jsx(_Fragment, {});
}
