var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { itemTypes, } from "@iter-acillba/core";
import ItemIcon from "../../components/Item/ItemIcon";
import Title from "../components/Title";
import { SiteComponent, connect } from "../structure";
import { WatchProp } from "../../structure";
import moment from "moment";
import { times } from "lodash";
var Craft = /** @class */ (function (_super) {
    __extends(Craft, _super);
    function Craft() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            recipes: [],
            crafts: [],
            selected: 0,
            time: 0,
        };
        _this.onCraft = function () { return __awaiter(_this, void 0, void 0, function () {
            var selected;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selected = this.state.selected;
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/shop/craft/" + selected,
                                method: "put",
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.callData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onCancel = function (id) { return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/shop/craft/" + id,
                            method: "delete",
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.callData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.onComplete = function (id) { return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/shop/craft/" + id,
                            method: "post",
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.callData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }; };
        return _this;
    }
    Craft.prototype.onMount = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.callData();
                return [2 /*return*/];
            });
        });
    };
    Craft.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callData()];
                    case 1:
                        _a.sent();
                        this.setState({ time: Date.now() });
                        this.timer = setInterval(function () {
                            _this.setState({ time: Date.now() });
                        }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    Craft.prototype.componentWillUnmount = function () {
        clearInterval(this.timer);
    };
    Craft.prototype.callData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var recipes, crafts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/item/recipe?order=item.name&sort=ASC",
                            method: "get",
                        })];
                    case 1:
                        recipes = (_a.sent()).data;
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/shop/craft",
                                method: "get",
                            })];
                    case 2:
                        crafts = _a.sent();
                        this.setState({ recipes: recipes, crafts: crafts });
                        return [2 /*return*/];
                }
            });
        });
    };
    Craft.prototype.timeFormat = function (seconds, showSecond) {
        if (showSecond === void 0) { showSecond = false; }
        var s = seconds % 60;
        var m = Math.floor((seconds % 3600) / 60);
        var h = Math.floor(seconds / 3600);
        var res = [];
        if (h) {
            res.push("".concat(h, "\uC2DC\uAC04"));
        }
        if (m) {
            res.push("".concat(m, "\uBD84"));
        }
        if (s) {
            res.push("".concat(s, "\uCD08"));
        }
        else if (showSecond) {
            res.push("0\uCD08");
        }
        return res.join(" ");
    };
    Craft.prototype.render = function () {
        var _this = this;
        var selected = this.state.recipes.find(function (r) { return r.id == _this.state.selected; });
        return (_jsxs(_Fragment, { children: [_jsx(Title, { text: "CRAFT", links: [
                        { text: "CRAFT", link: "/shop/craft" },
                        { text: "SHOP", link: "/shop/list" },
                    ] }), _jsxs("div", __assign({ className: "craft-container " }, { children: [_jsxs("div", __assign({ className: "list-container" }, { children: [_jsx("h4", { children: "\uB808\uC2DC\uD53C \uBAA9\uB85D" }), _jsx("div", __assign({ className: "list" }, { children: _jsx("div", __assign({ className: "list-scroller" }, { children: _jsx("div", __assign({ className: "scroll-contents" }, { children: Object.entries(itemTypes).map(function (_a, i) {
                                                var _b = __read(_a, 2), key = _b[0], val = _b[1];
                                                var items = _this.state.recipes.filter(function (r) { return r.type === key; });
                                                if (!items.length)
                                                    return;
                                                return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "filter" }, { children: val })), items.map(function (_a, i) {
                                                            var icon = _a.icon, name = _a.name, time = _a.time, id = _a.id;
                                                            return (_jsxs("div", __assign({ className: "item ".concat(id === (selected === null || selected === void 0 ? void 0 : selected.id) ? "selected" : ""), onClick: function () { return _this.setState({ selected: id }); } }, { children: [_jsxs("div", __assign({ className: "title" }, { children: [_jsx("img", { src: icon, alt: name }), " ", name] })), _jsx("div", __assign({ className: "time" }, { children: _this.timeFormat(time) }))] }), i));
                                                        })] }));
                                            }) })) })) }))] })), _jsxs("div", __assign({ className: "preview-container" }, { children: [_jsx("h4", { children: "\uB808\uC2DC\uD53C \uC0C1\uC138" }), !selected ? (_jsx("div", __assign({ className: "no-selection" }, { children: "\uBAA9\uB85D\uC5D0\uC11C \uB808\uC2DC\uD53C\uB97C \uC120\uD0DD\uD558\uC138\uC694." }))) : (_jsxs("div", __assign({ className: "preview" }, { children: [_jsxs("div", __assign({ className: "ingrediants" }, { children: [_jsx("h5", { children: "\uACB0\uACFC \uC544\uC774\uD15C" }), _jsx(ItemIcon, { item: selected }), _jsx("br", {}), _jsx("h5", { children: "\uC7AC\uB8CC \uC544\uC774\uD15C" }), selected.ingrediants.map(function (item, i) {
                                                    return _jsx(ItemIcon, { item: item }, i);
                                                })] })), _jsxs("div", __assign({ className: "buttons" }, { children: [_jsxs("div", __assign({ className: "time" }, { children: ["\uC18C\uC694 \uC2DC\uAC04", _jsx("br", {}), _jsx("strong", { children: this.timeFormat(selected.time) })] })), _jsx("button", __assign({ className: "button", onClick: this.onCraft }, { children: "\uC81C\uC791\uD558\uAE30" }))] }))] }))), _jsxs("h4", { children: ["\uC870\uD569 \uC2AC\uB86F", _jsx("small", { children: "\uB3D9\uC2DC\uC81C\uC791\uC740 \uCD5C\uB300 3\uAC1C\uAE4C\uC9C0 \uAC00\uB2A5\uD569\uB2C8\uB2E4." })] }), _jsx("div", __assign({ className: "slots" }, { children: times(3, function (i) {
                                        var craft = _this.state.crafts.filter(function (c) { return !c.completed; })[i];
                                        if (!craft) {
                                            return (_jsxs("div", __assign({ className: "slot empty" }, { children: [_jsx("div", {}), _jsx("div", __assign({ className: "info" }, { children: "\uC2AC\uB86F\uC774 \uBE44\uC5C8\uC2B5\uB2C8\uB2E4." })), _jsx("div", {})] })));
                                        }
                                        var id = craft.id, recipe = craft.recipe, created_timestamp = craft.created_timestamp;
                                        var _a = recipe, icon = _a.icon, name = _a.name, time = _a.time;
                                        var completeDate = moment(created_timestamp).add(time, "s");
                                        console.log(created_timestamp);
                                        console.log(time);
                                        var completable = completeDate.isBefore(_this.state.time);
                                        return (_jsxs("div", __assign({ className: "slot crafting" }, { children: [_jsxs("div", __assign({ className: "item" }, { children: [_jsx("img", { src: icon, alt: "" }), " ", name] })), completable ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "info" }, { children: ["\uC644\uB8CC\uC77C\uC2DC: ", completeDate.format("YY/MM/DD HH:mm")] })), _jsxs("div", __assign({ className: "buttons" }, { children: [_jsx("button", __assign({ className: "button", onClick: _this.onCancel(id) }, { children: "\uCDE8\uC18C" })), _jsx("button", __assign({ className: "button", onClick: _this.onComplete(id) }, { children: "\uC218\uB839" }))] }))] })) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "info" }, { children: ["\uB0A8\uC740 \uC2DC\uAC04:", " ", _this.timeFormat(completeDate.diff(_this.state.time, "second"), true)] })), _jsx("div", __assign({ className: "buttons" }, { children: _jsx("button", __assign({ className: "button", onClick: _this.onCancel(id) }, { children: "\uCDE8\uC18C" })) }))] }))] }), i));
                                    }) }))] }))] }))] }));
    };
    __decorate([
        WatchProp("state.root.character.id")
    ], Craft.prototype, "onMount", null);
    return Craft;
}(SiteComponent));
export default connect()(Craft);
