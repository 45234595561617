var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
var MIN_PLAYTIME = 1000;
var FADEOUT_TIME = 300;
export function Loading(_a) {
    var children = _a.children, loading = _a.loading;
    var _b = __read(useState(false), 2), minPlay = _b[0], setMinPlay = _b[1];
    var _c = __read(useState(true), 2), showLoading = _c[0], setShowLoading = _c[1];
    var fadeout = !loading && minPlay;
    useEffect(function () {
        setTimeout(function () {
            setMinPlay(true);
        }, MIN_PLAYTIME);
    }, []);
    useEffect(function () {
        if (fadeout) {
            setTimeout(function () {
                setShowLoading(false);
            }, FADEOUT_TIME);
        }
    }, [fadeout]);
    return (_jsxs(_Fragment, { children: [children, showLoading ? (_jsx("div", __assign({ className: "loading-container  ".concat(fadeout ? "fadeout" : "") }, { children: _jsx("img", { className: "spinner", src: "/static/img/spinner.png", alt: "spinner" }) }))) : (_jsx(_Fragment, {}))] }));
}
