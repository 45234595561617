import * as Battle from "./battle";
import { DBRecord } from "./common";

export type Category = {
  name: string;
  allow_create: 0 | 1;
  is_default: 0 | 1;
  is_updatable: 0 | 1;
  is_application: 0 | 1;
  total?: number;
} & DBRecord;

export enum State {
  DRAFT = "draft",
  SUCCESS = "success",
}

export const stateTitle = {
  [State.DRAFT]: "임시저장",
  [State.SUCCESS]: "완료",
};

export enum Race {
  HUMAN = "human",
  ANIMA = "anima",
  GEMMA = "gemma",
  FLORAN = "floran",
  DRAGON = "dragon",
}

export const raceType = [
  { key: Race.HUMAN, name: "휴먼", title: "창공의 여행자" },
  { key: Race.ANIMA, name: "아니마", title: "적사의 파수꾼" },
  { key: Race.GEMMA, name: "젬마", title: "녹림의 전승자" },
  { key: Race.FLORAN, name: "플로란", title: "흑암의 은둔자" },
  { key: Race.DRAGON, name: "드래곤", title: "여정의 수호자" },
];

export type Status = {
  id: number;
  character_id: number;
} & Battle.Status &
  DBRecord;

export const defaultStatus: Status = {
  id: 0,
  character_id: 0,
  hp: 50,
  max_hp: 50,
  sp: 0,
  max_sp: 5,
  attack: 5,
  spell: 5,
  defense: 5,
  dodge: 5,
  initiative: 5,
  crit_rate: 5,
  crit_dmg: 5,
  created_timestamp: "",
  updated_timestamp: "",
};

export type Data<T = Battle.Job, U = Race> = {
  user_id: number;
  category_id: number;
  is_rep: 0 | 1;
  state: State | string;
  title: string;
  name: string;
  nickname: string;
  eng_name: string;
  gender: string;
  body: string;
  job: T;
  job2: T;
  race: U;
  age: string;
  thumbnail_img: string;
  body_img: string;
  battle_img: string;
  cutscene_img: string;
  running: string;
  quote: string;
  outfit: string;
  personality: string;
  description: string;
  secret: string;
  society: string;
  health: number;
  strong: number;
  int: number;
  dex: number;
  will: number;
  level: number;
  update_count: number;
  submit_timestamp: string;
  outfitData?: Outfit;
} & DBRecord;

export const defaultData: Data = {
  id: 0,
  created_timestamp: "",
  updated_timestamp: "",
  user_id: 0,
  category_id: 0,
  is_rep: 0,
  state: "success",
  title: "",
  name: "체험용 캐릭터",
  nickname: "체험용",
  eng_name: "test",
  gender: "",
  body: "",
  job: Battle.Job.TANK,
  job2: Battle.Job.TANK,
  race: Race.HUMAN,
  age: "",
  thumbnail_img: "/static/img/mob_thumb.png",
  body_img: "/static/img/mob_body.png",
  battle_img: "/static/img/mob_port.png",
  cutscene_img: "",
  running: "",
  quote: "",
  outfit: "",
  personality: "",
  description: "",
  secret: "",
  society: "",
  health: 3,
  strong: 3,
  int: 3,
  dex: 3,
  will: 3,
  level: 1,
  update_count: 3,
  submit_timestamp: "",
};

export type DataField = {
  name: string;
  type: "text" | "textarea" | "image" | "select" | "number";
  values?: [string, string][];
  defaultValue?: string;
  tooltip?: string;
  required?: boolean;
  maxLength?: number;
  placeholder?: string;
};

export const dataFields: {
  [key in keyof Omit<
    Data,
    | "id"
    | "created_timestamp"
    | "updated_timestamp"
    | "is_rep"
    | "state"
    | "level"
    | "update_count"
    | "submit_timestamp"
  >]: DataField;
} = {
  user_id: {
    name: "오너",
    type: "select",
    required: true,
  },
  category_id: {
    name: "카테고리",
    type: "select",
    required: true,
  },
  title: {
    name: "이명",
    type: "text",
    placeholder: "OO의 계승자",
    tooltip:
      "캐릭터의 개성이나 특성을 표현할 수 있는 이명입니다. OO의 계승자 포맷으로 입력해주세요. 너무 길어질 경우 합발폼 등에 생략되어 표시될 수 있습니다.",
    maxLength: 20,
  },
  name: {
    name: "이름",
    type: "text",
    required: true,
    maxLength: 20,
  },
  nickname: {
    name: "단축표기명",
    type: "text",
    maxLength: 5,
    tooltip:
      "전투 컨텐츠 등에서 표기되는 짧은 이름입니다. 최대 5자까지 입력 가능합니다.",
  },
  eng_name: {
    name: "영문명",
    type: "text",
    maxLength: 30,
  },
  gender: {
    name: "성별",
    type: "text",
    maxLength: 10,
  },
  job: {
    name: "클래스",
    type: "select",
    values: Object.entries(Battle.jobs),
  },
  job2: {
    name: "2지망 클래스",
    type: "select",
    values: Object.entries(Battle.jobs),
  },
  race: {
    name: "종족",
    type: "select",
    values: raceType.map(({ key, name }) => [key, name]),
    tooltip: "드래곤 종족은 신청이 불가합니다.",
  },
  age: {
    name: "나이",
    type: "text",
    placeholder: "숫자만 입력해 주세요.",
    tooltip: "신청 가능한 최소 연령은 만 19세입니다.",
    maxLength: 10,
  },
  body: {
    name: "신체",
    type: "text",
    placeholder: "신장/체중",
    tooltip:
      "캐릭터의 신장과 체중을 정확한 수치로 기입해주세요. 단 몸무게는 마름, 표준, 건장 등으로 표기 가능합니다.",
    maxLength: 10,
  },
  thumbnail_img: {
    name: "썸네일 이미지",
    type: "image",
    tooltip: "캐릭터 목록에 표시되는 얼굴 이미지입니다. 가로세로 120px.",
  },
  body_img: {
    name: "전신 이미지",
    type: "image",
    tooltip:
      "캐릭터 목록과 상세보기 페이지에서 표시되는 이미지입니다. 반신도 가능합니다. 가로세로 최대 1200px",
  },
  cutscene_img: {
    name: "컷인 이미지",
    type: "image",
    tooltip:
      "특정 스킬 사용시 표시되는 컷인 이미지입니다. 가로 최대 1200px, 세로 400px.",
  },
  battle_img: {
    name: "반신 이미지",
    type: "image",
    tooltip:
      "다양한 컨텐츠에서 표시되는 상반신 이미지입니다. 가로 400px, 세로 250px",
  },
  quote: {
    name: "한마디",
    type: "text",
    tooltip: "\\n을 입력하면 줄바꿈이 됩니다.",
    maxLength: 100,
  },
  running: {
    name: "현재 준비 중(러닝 중)인 커뮤니티 수",
    type: "text",
    maxLength: 50,
  },
  outfit: {
    name: "외견",
    type: "textarea",
    placeholder:
      "캐릭터의 외관을 자유롭게 서술해주세요. 쓰리 톤을 초과하는 머리색, 혐오감을 줄 수 있는 고어한 외형 등을 제외하고 모든 외형을 허용합니다.",
  },
  personality: {
    name: "성격",
    type: "textarea",
    placeholder: "3~5개의 키워드를 적은 뒤, 아래에 설명을 기입해주세요.",
  },
  society: {
    name: "모형세계에서의 모습",
    type: "textarea",
    placeholder:
      "당신이 모형 세계에서 어떤 모습이었고, 어떤 생활을 하고 있었는지 등을 서술해주세요. 이는 원래 세계의 당신과 상반되는 내용이어도 괜찮습니다.",
  },
  description: {
    name: "그외 설정",
    type: "textarea",
    placeholder:
      "외관과 성격란에 적지 못했던 설정을 적어주세요.키워드를 적은 뒤, 아래에 설명을 기입해주시면 됩니다.\n* 고향 세계 관련 정보는 이곳에 적어주세요. 커뮤니티에서 제공하는 기본 설정 외의 임의의 설정을 사용할 경우,반드시 이곳에 서술해주시길 바랍니다.\n* 세계 설정의 여부는 합격에 영향을 끼치지 않습니다.",
  },
  secret: {
    name: "비밀 설정",
    type: "textarea",
    tooltip: "오너와 운영진에게만 공개되는 정보입니다.",
    defaultValue:
      "아래 항목들은 공란으로 제출이 가능하며, 비밀 설정의 유무는 합격에 영향을 끼치지 않습니다. 러닝 중 사망 요소가 될 수 있는 비밀 설정을 금지합니다.\n\n[러닝 IF]\n캐릭터의 예상되는 러닝 방향을 서술해주세요.\n\n[엔딩 IF]\n[1] 자신을 희생하여 고향 세계를 수복한다.\n[2] 자신을 희생하지 않는다.\n[3] 어떻게 해야 할 지 아직 정하지 못했다.\n[4] 기타 (자유롭게 서술해주세요.)\n\n캐릭터의 예상되는 러닝 방향을 골라 해당하는 항목만 남겨주세요.\n이 답은 어디까지나 가정이며, 실제 러닝 중 변경이 가능합니다.\n간단한 서술을 곁들여주시는 것을 권합니다.",
  },
  health: {
    name: Battle.parameters["health"],
    type: "number",
  },
  strong: {
    name: Battle.parameters["strong"],
    type: "number",
  },
  int: {
    name: Battle.parameters["int"],
    type: "number",
  },
  dex: {
    name: Battle.parameters["dex"],
    type: "number",
  },
  will: {
    name: Battle.parameters["will"],
    type: "number",
  },
};

export type Prop = {
  character_id: number;
  accessory_id: number;
  weapon_id: number;
  armor_id: number;
  money: number;
  ap: number;
  refresh_ap: string;
} & DBRecord;

export type Outfit = {
  id: number;
  name: string;
  character_id: number;
  body_img: string;
  battle_img: string;
  cutscene_img: string;
  thumbnail_img: string;
  outfit: string;
  quote: string;
  offset_x: number;
  offset_y: number;
  is_default: 1 | 0;
  selected: 1 | 0;
};

export const defaultProp: Prop = {
  id: 0,
  character_id: 0,
  updated_timestamp: "",
  created_timestamp: "",
  accessory_id: 0,
  weapon_id: 0,
  armor_id: 0,
  money: 0,
  ap: 0,
  refresh_ap: "",
};

export type Relation = {
  character_id: number;
  target_id: number;
  title: string;
  order: number;
  description: string;
} & DBRecord;

export type RepData = {
  user_id: number;
  id: number;
  name: string;
  nickname: string;
  thumbnail_img: string;
  ap: number;
  money: number;
};
