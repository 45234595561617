var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Battle } from "@iter-acillba/core";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
var MAX_STATUS = 15;
export function BattleStatusInput(_a) {
    var data = _a.data, onChange = _a.onChange;
    var _b = data.health, health = _b === void 0 ? 1 : _b, _c = data.strong, strong = _c === void 0 ? 1 : _c, _d = data.int, int = _d === void 0 ? 1 : _d, _e = data.dex, dex = _e === void 0 ? 1 : _e, _f = data.will, will = _f === void 0 ? 1 : _f;
    var allStat = Number(health) + Number(strong) + Number(int) + Number(dex) + Number(will);
    var up = function (key) { return function () {
        var val = data[key];
        if (!val) {
            onChange({ target: { name: key, value: 1 } });
            return;
        }
        if (allStat >= MAX_STATUS || val == 5)
            return;
        onChange({ target: { name: key, value: val + 1 } });
    }; };
    var down = function (key) { return function () {
        var val = data[key];
        if (!val) {
            onChange({ target: { name: key, value: 1 } });
            return;
        }
        if (val == 1)
            return;
        onChange({ target: { name: key, value: val - 1 } });
    }; };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row g-2 mb-2 status-input" }, { children: [_jsx(OverlayTrigger, __assign({ overlay: _jsx(Tooltip, { children: "\uAC01 \uC2A4\uD0EF\uC5D0 \uCD5C\uC18C 1, \uCD5C\uB300 5\uAE4C\uC9C0 \uC790\uC720\uB86D\uAC8C \uD22C\uC790 \uAC00\uB2A5\uD569\uB2C8\uB2E4." }) }, { children: _jsxs("div", __assign({ className: "col-2 status-control" }, { children: [_jsx("label", { children: "\uC2A4\uD0EFPt" }), _jsx("div", __assign({ className: "status-all" }, { children: MAX_STATUS - allStat }))] })) })), Object.entries(Battle.parameters).map(function (_a, i) {
                    var _b = __read(_a, 2), key = _b[0], val = _b[1];
                    return (_jsx(OverlayTrigger, __assign({ overlay: _jsx(Tooltip, { children: Battle.parameterTooltip[key] }) }, { children: _jsxs("div", __assign({ className: "col-2 status-control" }, { children: [_jsx("label", { children: val }), _jsxs("div", { children: [_jsx(ArrowDropUpIcon, { className: "up", onClick: up(key) }), _jsx("span", __assign({ className: "value" }, { children: data[key] || 0 })), _jsx(ArrowDropDownIcon, { className: "down", onClick: down(key) })] })] })) }), i));
                })] })) }));
}
export function ImageInput(_a) {
    var onChange = _a.onChange, name = _a.name, fieldKey = _a.fieldKey, value = _a.value, showImage = _a.showImage;
    return (_jsx("div", __assign({ className: "row image-input g-2" }, { children: value && typeof value === "string" ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "col-6" }, { children: showImage ? (_jsx("button", __assign({ type: "button", onClick: function () { return showImage(fieldKey); } }, { children: "\uB4F1\uB85D\uB41C \uC774\uBBF8\uC9C0" }))) : (_jsx("a", __assign({ href: "".concat(value), target: "_blank", rel: "noreferrer" }, { children: _jsxs("button", __assign({ type: "button" }, { children: ["\uB4F1\uB85D\uB41C \uC774\uBBF8\uC9C0", _jsx(OpenInNewIcon, {})] })) }))) })), _jsx("div", __assign({ className: "col-6" }, { children: _jsxs("button", __assign({ type: "button", onClick: function () {
                            return onChange({ target: { name: fieldKey, value: "" } });
                        } }, { children: ["\uC774\uBBF8\uC9C0 \uC0AD\uC81C", _jsx(DeleteForeverIcon, {})] })) }))] })) : (_jsx("div", __assign({ className: "col" }, { children: _jsx("input", { onChange: onChange, id: "category_field_".concat(fieldKey), className: "form-control", type: "file", accept: "image/png", alt: name, name: fieldKey }) }))) })));
}
