var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import "./cutin.scss";
export default function Cutin(_a) {
    var data = _a.data;
    if (!data) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: "cutin-container" }, { children: _jsx("div", __assign({ className: "cutin-bg" }, { children: _jsx("div", __assign({ className: "cutin-img" }, { children: _jsx("img", { src: data.character.cutscene_img, alt: "\uCEF7\uC778" }) })) })) })));
}
