var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BuffType } from "@iter-acillba/core";
import { times } from "lodash";
import { useState, useEffect } from "react";
import { ChatBubble } from "./ChatBubble";
import SearchIcon from "@mui/icons-material/Search";
import ShieldIcon from "@mui/icons-material/Shield";
export default function Ally(_a) {
    var allyData = _a.allyData, playerData = _a.playerData, chats = _a.chats, clickInfo = _a.clickInfo, _b = _a.useSp, useSp = _b === void 0 ? 0 : _b;
    var isReady = playerData.isReady, character = playerData.character;
    var nickname = character.nickname, battle_img = character.battle_img, job = character.job;
    var hp = allyData.hp, max_hp = allyData.max_hp, buffs = allyData.buffs, sp = allyData.sp, max_sp = allyData.max_sp;
    var _c = __read(useState(hp), 2), lastHP = _c[0], setLastHP = _c[1];
    var _d = __read(useState(false), 2), damaged = _d[0], setDamaged = _d[1];
    var _e = __read(useState(false), 2), isDead = _e[0], setIsDead = _e[1];
    var maxShield = 0;
    allyData.buffs
        .filter(function (b) { return b.type === BuffType.SHIELD && b.value; })
        .forEach(function (b) { return (maxShield = Math.max(b.value, maxShield)); });
    useEffect(function () {
        if (hp < lastHP) {
            setDamaged(true);
        }
        if (hp === 0) {
            setTimeout(function () {
                setIsDead(true);
            }, 1000);
        }
        else {
            setIsDead(false);
        }
        setLastHP(hp);
    }, [hp]);
    useEffect(function () {
        if (!damaged)
            return;
        setTimeout(function () {
            setDamaged(false);
        }, 1000);
    }, [damaged]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "portrait ".concat(damaged ? "damaged" : "", " ").concat(isDead ? "dead" : "") }, { children: [_jsx("img", { src: battle_img, alt: "\uCE90\uB9AD\uD130" }), isReady ? _jsx("div", { className: "standby-overlay" }) : "", _jsx(ChatBubble, { userId: playerData.user.id, chats: chats, placement: "top" })] })), _jsxs("div", __assign({ className: "info" }, { children: [_jsxs("div", __assign({ className: "hp" }, { children: [_jsx("div", { className: "hp-bar", style: { width: "".concat((hp / max_hp) * 280, "px") } }), _jsxs("span", __assign({ className: "hp-text" }, { children: [_jsxs("strong", { children: [hp, maxShield ? (_jsxs("span", __assign({ className: "shield-text" }, { children: ["(", _jsx(ShieldIcon, {}), maxShield, ")"] }))) : (_jsx(_Fragment, {}))] }), " ", "/ ", max_hp] })), _jsx("div", __assign({ className: "sp-bar" }, { children: times(max_sp, function (i) {
                                    var fill = i < sp;
                                    var use = i >= sp - useSp && fill;
                                    return (_jsx("div", __assign({ className: "sp-dot" }, { children: fill ? (_jsx("div", { className: "fill ".concat(use ? "use" : "") })) : (_jsx(_Fragment, {})) }), i));
                                }) }))] })), _jsx("div", __assign({ className: "name" }, { children: nickname })), _jsx("div", __assign({ className: "buffs" }, { children: buffs
                            .filter(function (b) { return !b.hidden; })
                            .map(function (buff, i) {
                            return (_jsx("div", __assign({ className: "buff ".concat(buff.group) }, { children: _jsx("img", { src: buff.icon_img, alt: buff.name, title: buff.name }) }), i));
                        }) })), _jsxs("div", __assign({ className: "emblem" }, { children: [_jsx("div", { className: "emblem-img ".concat(job) }), _jsx("div", { className: "emblem-overlay" }), _jsx("div", __assign({ className: "view-button", onClick: function () { return clickInfo(playerData.user.id); } }, { children: _jsx(SearchIcon, {}) }))] }))] }))] }));
}
