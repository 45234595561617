var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { connect } from "../structure";
import Title from "../components/Title";
import Craft from "./Craft";
import { Switch, Route, Link } from "react-router-dom";
import "./shop.scss";
import ShopList from "./ShopList";
function Shop() {
    return (_jsx(_Fragment, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/shop/craft", component: Craft }), _jsx(Route, { path: "/shop/list", component: ShopList }), _jsx(Route, __assign({ path: "/shop" }, { children: _jsxs(_Fragment, { children: [_jsx(Title, { text: "SHOP", links: [] }), _jsx("div", __assign({ className: "menu-panels" }, { children: _jsxs("div", __assign({ className: "justify" }, { children: [_jsx("div", __assign({ className: "panel" }, { children: _jsx(Link, __assign({ to: "/shop/craft" }, { children: _jsx("img", { src: "/static/img/panel_craft.png", alt: "craft" }) })) })), _jsx("div", __assign({ className: "panel" }, { children: _jsx(Link, __assign({ to: "/shop/list" }, { children: _jsx("img", { src: "/static/img/panel_shop.png", alt: "shop" }) })) }))] })) }))] }) }))] }) }));
}
export default connect()(Shop);
