var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Battle } from "@iter-acillba/core";
import RoomPopup from "../RoomPopup";
import SkillItem from "../Skill/Skill";
var statusList = Battle.allStatus;
export function InfoPopup(_a) {
    var playerData = _a.playerData, unitData = _a.unitData, skills = _a.skills, show = _a.show, setShow = _a.setShow;
    if (!playerData || !unitData)
        return _jsx(_Fragment, {});
    return (_jsx(RoomPopup, __assign({ width: 450, height: 600, show: show, setShow: setShow }, { children: _jsx("div", __assign({ className: "info" }, { children: _jsx("div", __assign({ className: "scroller" }, { children: _jsxs("div", __assign({ className: "contents" }, { children: [_jsx("h5", { children: "\uC2A4\uD14C\uC774\uD130\uC2A4" }), _jsx("section", __assign({ className: "status" }, { children: statusList.map(function (stat, i) {
                                return (_jsxs("div", __assign({ className: "row stat" }, { children: [_jsx("div", __assign({ className: "col-8 stat-name" }, { children: Battle.statusData[stat].name })), _jsx("div", __assign({ className: "col-4" }, { children: unitData[stat] }))] }), i));
                            }) })), _jsx("hr", {}), _jsx("h5", { children: "\uBC84\uD504" }), _jsxs("section", __assign({ className: "buffs" }, { children: [unitData.buffs
                                    .filter(function (buff) { return !buff.hidden; })
                                    .map(function (buff, i) {
                                    return (_jsxs("div", __assign({ className: "skill-component" }, { children: [_jsx("div", __assign({ className: "skill-img" }, { children: _jsx("div", __assign({ className: "skill-icon" }, { children: _jsx("img", { src: buff.icon_img, alt: buff.name }) })) })), _jsxs("div", __assign({ className: "skill-caption" }, { children: [_jsx("div", __assign({ className: "name" }, { children: buff.name })), _jsxs("div", __assign({ className: "options" }, { children: [buff.expire_type !== "ever" ? (_jsxs(_Fragment, { children: [buff.expire_count, buff.expire_type === "count"
                                                                        ? "회"
                                                                        : "라운드", " ", "\uB0A8\uC74C |"] })) : (_jsx(_Fragment, {})), buff.stack + 1, "\uC2A4\uD0DD(\uCD5C\uB300 ", buff.stackable + 1, ")"] })), _jsx("div", __assign({ className: "desc" }, { children: buff.description }))] }))] }), i));
                                }), !unitData.buffs.length ? (_jsx("div", __assign({ className: "no-buff" }, { children: "\uC801\uC6A9\uB41C \uBC84\uD504\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." }))) : (_jsx(_Fragment, {}))] })), _jsx("hr", {}), _jsx("h5", { children: "\uC2A4\uD0AC" }), _jsx("section", __assign({ className: "skills" }, { children: playerData.character.skills.map(function (id, i) {
                                var data = skills.find(function (s) { return s.id === id; });
                                var activeData = unitData.skills.find(function (a) { return a.skill_id === id; });
                                if (!data)
                                    return;
                                return (_jsx(SkillItem, { data: data, cooldown: activeData === null || activeData === void 0 ? void 0 : activeData.cooldown }, i));
                            }) }))] })) })) })) })));
}
