var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import axios from "axios";
import { SiteComponent, connect } from "./structure";
import { Level } from "@iter-acillba/core";
import { Route, Switch } from "react-router-dom";
import Navigation from "./Navigation";
import Character from "./character/Character";
import Game from "./game/Game";
import { logout, login, setConfig, setMainBgm, setCharacters, } from "./store/actions";
import SettingWindow from "./window/SettingWindow.";
import Login from "./account/Login";
import Popup from "./components/Popup";
import Toast from "./components/Toast";
import { Welcome } from "./account/Welcome";
import Lobby from "./Lobby";
import Application from "./application/Application";
import Preview from "./character/Preview";
import BGM from "../components/BGM";
import Document from "./document/Document";
import { Loading } from "./Loading";
import moment from "moment";
import Background from "./Background";
import Shop from "./shop/Shop";
import { toast } from "../structure";
import MessageWindow from "./window/MessageWindow";
var STORAGE_KEY = "iter-acillba-visit";
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: true,
        };
        return _this;
    }
    App.prototype.getConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/site/config",
                            method: "get",
                        })];
                    case 1:
                        config = _a.sent();
                        this.dispatch(setConfig(config));
                        return [2 /*return*/, config];
                }
            });
        });
    };
    App.prototype.visit = function (store) {
        if (store === void 0) { store = false; }
        return __awaiter(this, void 0, void 0, function () {
            var lastVisit, today;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lastVisit = localStorage.getItem(STORAGE_KEY);
                        today = moment().format("YYYY-MM-DD 00:00:00");
                        if (!(!lastVisit || moment(lastVisit).isBefore(today))) return [3 /*break*/, 2];
                        return [4 /*yield*/, axios({
                                url: "/api/site/visit",
                                method: "post",
                            })];
                    case 1:
                        _a.sent();
                        if (store) {
                            localStorage.setItem(STORAGE_KEY, today);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    App.prototype.access = function () {
        if (this.props.state.root.previewPage)
            return;
        var accessFn = function () {
            axios({
                url: "/api/site/access",
                method: "post",
            });
        };
        setInterval(accessFn, 1000 * 60 * 5);
        accessFn();
    };
    App.prototype.getBgm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var bgm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/site/main_bgm",
                            method: "get",
                        })];
                    case 1:
                        bgm = _a.sent();
                        this.dispatch(setMainBgm(bgm));
                        return [2 /*return*/];
                }
            });
        });
    };
    App.prototype.getCharacters = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/character?category_default=1",
                            method: "get",
                        })];
                    case 1:
                        result = _a.sent();
                        this.dispatch(setCharacters(result.data));
                        return [2 /*return*/];
                }
            });
        });
    };
    App.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var need_login, res, _a, success, data, id, username, email, level, birth, twitter;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.access();
                        return [4 /*yield*/, this.getBgm()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.getConfig()];
                    case 2:
                        need_login = (_b.sent()).need_login;
                        return [4 /*yield*/, axios({
                                url: "/api/user/loginSession",
                                method: "get",
                            })];
                    case 3:
                        res = _b.sent();
                        _a = res.data, success = _a.success, data = _a.data;
                        this.setState({ loading: false });
                        return [4 /*yield*/, this.visit(success)];
                    case 4:
                        _b.sent();
                        if (!success || !data) {
                            this.dispatch(logout());
                        }
                        if (need_login && !success) {
                            this.redirect("/login");
                            return [2 /*return*/];
                        }
                        if (need_login && (!data || data.level < Level.REGULAR)) {
                            this.dispatch(logout());
                            this.dispatch(toast({ text: "정회원만 접근 가능합니다." }));
                            this.redirect("/login");
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.getCharacters()];
                    case 5:
                        _b.sent();
                        id = data.id, username = data.username, email = data.email, level = data.level, birth = data.birth, twitter = data.twitter;
                        this.dispatch(login({ id: id, username: username, email: email, level: level, birth: birth, twitter: twitter }));
                        return [2 /*return*/];
                }
            });
        });
    };
    App.prototype.render = function () {
        var root = this.props.state.root;
        var isLogin = root.user.id;
        var needLogin = root.config.need_login;
        var loading = this.state.loading;
        return (_jsxs(Loading, __assign({ loading: loading }, { children: [!loading ? _jsx(Route, { component: Background }) : _jsx(_Fragment, {}), !root.previewPage ? (_jsx(BGM, { bgm: root.startControl ? root.runBgm : null, pause: root.bgmPause })) : (_jsx(_Fragment, {})), !isLogin && needLogin ? (_jsxs(Switch, { children: [_jsx(Route, { path: "/welcome", component: Welcome }), _jsx(Route, { path: "/", render: function (props) { return _jsx(Login, __assign({}, props, { needLogin: true })); } })] })) : (_jsxs(_Fragment, { children: [_jsxs(Switch, { children: [_jsx(Route, { path: "/welcome", component: Welcome }), _jsx(Route, { path: "/document", component: Document }), _jsx(Route, { path: "/preview", component: Preview }), _jsx(Route, { path: "/login", component: Login }), _jsx(Route, { path: "/shop", component: Shop }), _jsx(Route, { path: "/character", component: Character }), _jsx(Route, { path: "/journey", component: Game }), _jsx(Route, { path: "/application", component: Application }), _jsx(Route, { path: "/", component: Lobby })] }), !root.previewPage && !loading ? (_jsx(Route, { component: Navigation })) : (_jsx(_Fragment, {}))] })), _jsx(MessageWindow, {}), _jsx(SettingWindow, {}), _jsx(Popup, {}), _jsx(Toast, {})] })));
    };
    return App;
}(SiteComponent));
export default connect()(App);
