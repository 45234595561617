var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dungeon } from "@iter-acillba/core";
import { SiteComponent, connect } from "../../structure";
import { openPopup, playMainBgm, setRunBgm, showNavigation, stopBgm, } from "../../store/actions";
import Title from "../../components/Title";
import InfoIcon from "@mui/icons-material/Info";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshIcon from "@mui/icons-material/Refresh";
import PreviewList from "../PreviewList";
import BattleRoomComponent from "../../../components/Battle/BattleRoom";
import moment from "moment";
import { playTime } from "../../../utils";
import { WatchState } from "../../../structure";
var BattleRoom = connect()(BattleRoomComponent);
var BattleLobby = /** @class */ (function (_super) {
    __extends(BattleLobby, _super);
    function BattleLobby() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            character: undefined,
            stages: [],
            rooms: [],
            roomId: undefined,
            instances: [],
        };
        _this.exitRoom = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ roomId: undefined });
                        this.dispatch(playMainBgm());
                        return [4 /*yield*/, this.callData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.start = function (stageId) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (this.state.character.ap < 1) {
                    this.dispatch(openPopup({
                        message: "AP가 소진되어 클리어 보상을 수령할 수 없습니다. 그래도 진행하시겠습니까?",
                        type: "confirm",
                        confirmFunction: function (res) {
                            if (!res)
                                return;
                            _this.createRoom(stageId);
                        },
                    }));
                    return [2 /*return*/];
                }
                this.createRoom(stageId);
                return [2 /*return*/];
            });
        }); };
        return _this;
    }
    BattleLobby.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BattleLobby.prototype.callData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var stages, character, instances, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        stages = this.callAPI({
                            url: "/api/battle/stage",
                            method: "get",
                        });
                        character = this.callAPI({
                            url: "/api/character/rep/" + this.props.state.root.user.id,
                            method: "get",
                        });
                        instances = this.callAPI({
                            url: "/api/battle/instance",
                            method: "get",
                        });
                        _a = this.setState;
                        _b = {};
                        return [4 /*yield*/, stages];
                    case 1:
                        _b.stages = _c.sent();
                        return [4 /*yield*/, character];
                    case 2:
                        _b.character = _c.sent();
                        return [4 /*yield*/, instances];
                    case 3:
                        _a.apply(this, [(_b.instances = _c.sent(),
                                _b)]);
                        return [4 /*yield*/, this.getRooms()];
                    case 4:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BattleLobby.prototype.startStage = function () {
        this.dispatch(showNavigation(!this.state.roomId));
    };
    BattleLobby.prototype.getRooms = function () {
        return __awaiter(this, void 0, void 0, function () {
            var rooms, myRoom;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/battle/room",
                            method: "get",
                        })];
                    case 1:
                        rooms = _a.sent();
                        this.setState({ rooms: rooms });
                        myRoom = rooms.find(function (v) {
                            return v.players.map(function (p) { return p.user.id; }).includes(_this.props.state.root.user.id);
                        });
                        if (myRoom) {
                            this.setState({ roomId: myRoom.roomId });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BattleLobby.prototype.createRoom = function (stageId) {
        return __awaiter(this, void 0, void 0, function () {
            var roomId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/battle/room/" + stageId,
                            method: "post",
                        })];
                    case 1:
                        roomId = _a.sent();
                        this.setState({ roomId: roomId });
                        return [2 /*return*/];
                }
            });
        });
    };
    BattleLobby.prototype.render = function () {
        var _this = this;
        var _a = this.state, rooms = _a.rooms, roomId = _a.roomId, character = _a.character;
        if (roomId) {
            return (_jsx(BattleRoom, { onGameEnd: function () { }, changeMusic: function (bgm) {
                    if (bgm) {
                        _this.dispatch(setRunBgm(bgm));
                    }
                    else {
                        _this.dispatch(stopBgm());
                    }
                }, roomId: roomId, onExit: this.exitRoom, onError: function (message) {
                    return _this.dispatch(openPopup({ message: message, type: "alert" }));
                }, user: this.props.state.root.user }));
        }
        return (_jsxs(_Fragment, { children: [_jsx(Title, { text: "BATTLE", links: [
                        { text: "EXPLORE", link: "/journey/explore" },
                        { text: "BATTLE", link: "/journey/battle" },
                        { text: "QUEST", link: "/journey/quest" },
                    ] }), _jsxs("div", __assign({ className: "game-lobby explore-lobby" }, { children: [_jsxs("div", __assign({ className: "info" }, { children: [_jsxs("div", __assign({ className: "character" }, { children: [_jsx("div", __assign({ className: "badge" }, { children: "\uB300\uD45C \uCE90\uB9AD\uD130" })), _jsx("span", { children: character === null || character === void 0 ? void 0 : character.name })] })), _jsxs("div", __assign({ className: "ap" }, { children: [_jsx("div", __assign({ className: "badge" }, { children: "\uC794\uC5EC AP" })), _jsxs("span", __assign({ className: "ap-number" }, { children: [_jsx("strong", { children: character === null || character === void 0 ? void 0 : character.ap }), "/", this.props.state.root.config.max_ap] })), _jsx(OverlayTrigger, __assign({ placement: "bottom", overlay: _jsx(Tooltip, { children: "\uD0D0\uC0C9\uACFC \uC804\uD22C\uC5D0 \uC0AC\uC6A9\uD560 \uC218 \uC788\uB294 \uD589\uB3D9\uB825\uC785\uB2C8\uB2E4. \uC2A4\uD14C\uC774\uC9C0 \uD074\uB9AC\uC5B4\uC2DC \uCC28\uAC10\uB418\uBA70, \uB9E4\uC77C \uBC24 12\uC2DC\uC5D0 \uB9AC\uD544\uB429\uB2C8\uB2E4." }) }, { children: _jsx(InfoIcon, {}) }))] }))] })), _jsx(PreviewList, { stages: this.state.stages, onClickStart: this.start }), _jsxs("div", __assign({ className: "log-container" }, { children: [_jsxs("h4", { children: ["\uC9C4\uD589\uC911\uC778 \uC804\uD22C", " ", _jsx(RefreshIcon, { className: "refresh", onClick: function () { return _this.getRooms(); } })] }), _jsx("div", __assign({ className: "log-list" }, { children: rooms.map(function (_a) {
                                        var players = _a.players, roomId = _a.roomId, stage = _a.stage, state = _a.state;
                                        var canJoin = state === Dungeon.State.STANDBY;
                                        return (_jsxs("div", __assign({ className: "log" }, { children: [_jsx("div", __assign({ className: "badge" }, { children: canJoin ? "모집중" : "진행중" })), _jsx("div", __assign({ className: "title" }, { children: stage.name })), _jsx("div", __assign({ className: "time" }, { children: players.map(function (v) { return v.character.nickname; }).join(",") })), canJoin && (_jsx("div", __assign({ className: "join", onClick: function () {
                                                        _this.setState({ roomId: roomId });
                                                    } }, { children: _jsx(ArrowForwardIcon, {}) })))] }), roomId));
                                    }) }))] })), _jsxs("div", __assign({ className: "log-container" }, { children: [_jsxs("h4", { children: ["\uC9C0\uB09C \uC804\uD22C \uAE30\uB85D", _jsx("small", { children: "\uAC00\uC7A5 \uCD5C\uADFC 9\uAC1C\uAE4C\uC9C0 \uD45C\uC2DC\uB429\uB2C8\uB2E4." })] }), _jsx("div", __assign({ className: "log-list" }, { children: this.state.instances.map(function (_a, i) {
                                        var state = _a.state, start_timestamp = _a.start_timestamp, end_timestamp = _a.end_timestamp, name = _a.name, player_name = _a.player_name;
                                        return (_jsxs("div", __assign({ className: "log" }, { children: [state === "success" ? (_jsx("div", __assign({ className: "badge success" }, { children: "\uC2B9\uB9AC" }))) : state === "fail" ? (_jsx("div", __assign({ className: "badge fail" }, { children: "\uD328\uBC30" }))) : (_jsx("div", __assign({ className: "badge" }, { children: "\uC9C4\uD589\uC911" }))), _jsx("div", __assign({ className: "title" }, { children: name })), _jsx("div", __assign({ className: "players" }, { children: player_name })), _jsxs("div", __assign({ className: "time" }, { children: [moment(start_timestamp).format("YY/MM/DD"), " \u00B7", " ", playTime(start_timestamp, end_timestamp)] }))] }), i));
                                    }) }))] }))] }))] }));
    };
    __decorate([
        WatchState("roomId")
    ], BattleLobby.prototype, "startStage", null);
    return BattleLobby;
}(SiteComponent));
export default connect()(BattleLobby);
