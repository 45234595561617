var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDrag } from "@use-gesture/react";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "@react-hook/window-size";
var BLOCK_SIZE = 150;
var INTERVAL = 16;
function getOffset(windowSize, pos) {
    var _a = __read(windowSize, 2), width = _a[0], height = _a[1];
    var _b = __read(pos, 2), col = _b[0], row = _b[1];
    var x = Math.ceil(width / 2 - col * BLOCK_SIZE - BLOCK_SIZE / 2);
    var y = Math.ceil(height / 2 - row * BLOCK_SIZE - BLOCK_SIZE / 2);
    return [x, y];
}
var KEY_MAPPING = [
    ["W", "w", "ㅈ"],
    ["A", "a", "ㅁ"],
    ["S", "s", "ㄴ"],
    ["D", "d", "ㅇ"],
];
var DIRECTIONS = [
    [0, -1],
    [-1, 0],
    [0, 1],
    [1, 0],
];
export function ExploreStage(_a) {
    var blocks = _a.blocks, preventMove = _a.preventMove, pos = _a.pos, onMove = _a.onMove, onMoveEnd = _a.onMoveEnd, trapped = _a.trapped, event = _a.event, paths = _a.paths;
    var windowSize = useWindowSize();
    var startOffset = getOffset(windowSize, pos);
    var _b = __read(useState(startOffset), 2), offset = _b[0], setOffset = _b[1];
    var _c = __read(useState(startOffset), 2), coord = _c[0], setCoord = _c[1];
    var _d = __read(useState(startOffset), 2), targetCoord = _d[0], setTargetCoord = _d[1];
    var _e = __read(useState(false), 2), isDragging = _e[0], setIsDragging = _e[1];
    var _f = __read(useState(false), 2), isMoving = _f[0], setIsMoving = _f[1];
    var _g = __read(useState(""), 2), dir = _g[0], setDir = _g[1];
    var _h = __read(useState([0, 0]), 2), lastPos = _h[0], setLastPos = _h[1];
    var _j = __read(useState(false), 2), encounter = _j[0], setEncounter = _j[1];
    var intervalRef = useRef();
    var start = blocks.find(function (_a) {
        var type = _a.block.type;
        return type === "start";
    });
    var end = blocks.filter(function (_a) {
        var type = _a.block.type;
        return type === "end";
    });
    var coordUpdateFn = function () {
        var _a = __read(coord, 2), x = _a[0], y = _a[1];
        var _b = __read(targetCoord, 2), targetX = _b[0], targetY = _b[1];
        var res = [x, y];
        if (x === targetX && y === targetY)
            return;
        if (x !== targetX) {
            var moveX = Math.max(1, Math.abs(targetX - x) / 8);
            res[0] = Math.ceil(x < targetX ? x + moveX : x - moveX);
        }
        if (y !== targetY) {
            var moveY = Math.max(1, Math.abs(targetY - y) / 8);
            res[1] = Math.ceil(y < targetY ? y + moveY : y - moveY);
        }
        setCoord(res);
    };
    var updateRef = useRef(coordUpdateFn);
    var intervalFn = function () {
        updateRef.current();
    };
    var dragProps = useDrag(function (_a) {
        var down = _a.down, _b = __read(_a.movement, 2), x = _b[0], y = _b[1];
        var _c = __read(offset, 2), offsetX = _c[0], offsetY = _c[1];
        if (!down) {
            intervalRef.current = setInterval(intervalFn, INTERVAL);
            setTargetCoord([offsetX, offsetY]);
        }
        else {
            clearInterval(intervalRef.current);
            setCoord([offsetX + x, offsetY + y]);
        }
        setIsDragging(down);
    });
    useEffect(function () {
        var nextOffset = getOffset(windowSize, pos);
        setOffset(nextOffset);
        if (!isDragging) {
            setTargetCoord(nextOffset);
        }
    }, [pos, windowSize]);
    useEffect(function () {
        updateRef.current = coordUpdateFn;
    }, [coord, targetCoord]);
    useEffect(function () {
        intervalRef.current = setInterval(intervalFn, INTERVAL);
        return function () {
            clearInterval(intervalRef.current);
        };
    }, []);
    useEffect(function () {
        var handler = function (e) {
            if (isMoving || preventMove || event)
                return;
            var find = KEY_MAPPING.find(function (keys) { return keys.includes(e.key); });
            var idx = KEY_MAPPING.indexOf(find);
            if (idx < 0)
                return;
            var dir = DIRECTIONS[idx];
            clickBlock(pos[0] + dir[0], pos[1] + dir[1]);
        };
        window.addEventListener("keydown", handler);
        return function () {
            window.removeEventListener("keydown", handler);
        };
    }, [pos, trapped]);
    var clickBlock = function (x, y) {
        var _a = __read(pos, 2), _x = _a[0], _y = _a[1];
        if ((x === _x && y === _y) || isMoving || preventMove || event || trapped)
            return;
        var dir = "";
        if (x < _x) {
            dir = "left";
        }
        if (x > _x) {
            dir = "right";
        }
        if (y < _y) {
            dir = "top";
        }
        if (y > _y) {
            dir = "bottom";
        }
        onMove(dir, x, y);
    };
    useEffect(function () {
        if (event) {
            setEncounter(true);
            setTimeout(function () {
                setEncounter(false);
            }, 1000);
        }
    }, [event]);
    useEffect(function () {
        var _a = __read(lastPos, 2), lastCol = _a[0], lastRow = _a[1];
        var _b = __read(pos, 2), col = _b[0], row = _b[1];
        if (lastCol !== col || lastRow !== row) {
            var moveDir = "";
            if (lastCol > col) {
                moveDir = "left";
            }
            else if (lastCol < col) {
                moveDir = "right";
            }
            else if (lastRow > row) {
                moveDir = "top";
            }
            else if (lastRow < row) {
                moveDir = "bottom";
            }
            setDir(moveDir);
            setIsMoving(true);
            setLastPos(pos);
            setTimeout(function () {
                setIsMoving(false);
                onMoveEnd();
            }, 500);
        }
    }, [pos, lastPos, onMoveEnd]);
    var left = coord[0];
    var top = coord[1];
    return (_jsxs("div", __assign({ className: "explore-stage" }, dragProps(), { children: [_jsx("div", __assign({ className: "perspective" }, { children: _jsx("div", __assign({ className: "blocks-perspective" }, { children: _jsxs("div", __assign({ className: "blocks" }, { children: [blocks.map(function (_a, i) {
                                var x = _a.x, y = _a.y, block = _a.block;
                                var inPath = paths.includes(block.id);
                                var isActive = Math.abs(x - pos[0]) + Math.abs(y - pos[1]) == 1;
                                return (_jsx("div", { onClick: function () { return isActive && clickBlock(x, y); }, className: "block ".concat(block.type ? block.type : "", " ").concat(inPath ? "visit" : "", " ").concat(isActive ? "active" : ""), style: {
                                        top: top + BLOCK_SIZE * y + "px",
                                        left: left + BLOCK_SIZE * x + "px",
                                    } }, i));
                            }), start && (_jsxs("div", __assign({ className: "effect start", style: {
                                    top: top + BLOCK_SIZE * start.y + "px",
                                    left: left + BLOCK_SIZE * start.x + "px",
                                } }, { children: [_jsx("div", { className: "top" }), _jsx("div", { className: "bottom" }), _jsx("div", { className: "left" }), _jsx("div", { className: "right" })] }))), end.map(function (_a, i) {
                                var x = _a.x, y = _a.y;
                                return (_jsxs("div", __assign({ className: "effect end", style: {
                                        top: top + BLOCK_SIZE * y + "px",
                                        left: left + BLOCK_SIZE * x + "px",
                                    } }, { children: [_jsx("div", { className: "top" }), _jsx("div", { className: "bottom" }), _jsx("div", { className: "left" }), _jsx("div", { className: "right" })] }), i));
                            }), _jsx("div", __assign({ className: "character", style: {
                                    top: top + "px",
                                    left: left + "px",
                                    transform: "translate3D(".concat(BLOCK_SIZE * pos[0], "px, ").concat(BLOCK_SIZE * pos[1], "px, 0)"),
                                } }, { children: _jsx("div", { className: "sprite ".concat(dir, " ").concat(isMoving ? "moving" : "", " ").concat(trapped ? "trapped" : ""), style: {
                                        backgroundImage: "url('/static/img/explore_sprite.png')",
                                    } }) }))] })) })) })), encounter && _jsx("div", { className: "encounter" }), !isDragging && trapped && _jsx("div", { className: "encounter trap" })] })));
}
