var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Battle, Level, } from "@iter-acillba/core";
import InventoryComponent from "../../components/Inventory/Inventory";
import { WatchProp, toast } from "../../structure";
import { SiteComponent, connect } from "../structure";
import LockIcon from "@mui/icons-material/Lock";
import "./character-info.scss";
import SkillComponent from "../../components/Skill/Skill";
import SkillSlot from "../../components/Skill/SkillSlot";
import { encodeText } from "../../utils";
import Relation from "./components/Relation";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import ItemIcon from "../../components/Item/ItemIcon";
import QuestList from "./QuestList";
import { OutfitList } from "./Outfit";
import EmailIcon from "@mui/icons-material/Email";
import { showMessage } from "../store/actions";
var Inventory = connect()(InventoryComponent);
var SkillItem = connect()(SkillComponent);
var title = {
    "": "",
    inventory: "인벤토리",
    profile: "프로필",
    equip: "장비 & 스킨",
    skill: "스킬 & 스탯",
    relation: "인간관계",
    quest: "퀘스트",
};
var CharacterInfo = /** @class */ (function (_super) {
    __extends(CharacterInfo, _super);
    function CharacterInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            skillData: {
                skills: [],
                status: {},
                selectSlot: 0,
            },
            equipData: {
                weapon: undefined,
                accessory: undefined,
                armor: undefined,
                outfits: [],
            },
            user: {},
        };
        _this.ref = React.createRef();
        _this.showOutfitPopup = function (id) {
            if (!id) {
                var defaultOutfit = _this.state.equipData.outfits.find(function (o) { return o.is_default; });
                _this.props.showOutfitPopup(defaultOutfit || {});
            }
            else {
                var outfit = _this.state.equipData.outfits.find(function (o) { return o.id === id; });
                _this.props.showOutfitPopup(outfit);
            }
        };
        _this.activeSkill = function (id) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var skills;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!id) {
                            this.dispatch(toast({ text: "기본 스킬은 변경할 수 없습니다." }));
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/character/active_skill/" + this.props.character.id,
                                data: { skill_id: id },
                                method: "post",
                            })];
                    case 1:
                        skills = (_a.sent()).skills;
                        this.setState({ skillData: __assign(__assign({}, this.state.skillData), { skills: skills }) });
                        this.props.updateSkill(skills);
                        return [2 /*return*/];
                }
            });
        }); }; };
        return _this;
    }
    CharacterInfo.prototype.onChangeContent = function () {
        return __awaiter(this, void 0, void 0, function () {
            var content, user, equips, outfits, _a, weapon, accessory, armor, _b;
            var _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        content = this.props.content;
                        if ([""].includes(content))
                            return [2 /*return*/];
                        this.setState({ loading: true });
                        if (!(content === "skill")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.callSkillData()];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2:
                        if (!(content === "profile")) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/user/" + this.props.character.user_id,
                                method: "get",
                            }, true, true)];
                    case 3:
                        user = _e.sent();
                        user && this.setState({ user: user });
                        _e.label = 4;
                    case 4:
                        if (!(content === "equip")) return [3 /*break*/, 7];
                        equips = this.callAPI({
                            url: "/api/inventory/equip/" + this.props.character.id,
                            method: "get",
                        });
                        outfits = this.callAPI({
                            url: "/api/character/outfit/" + this.props.character.id,
                            method: "get",
                        });
                        return [4 /*yield*/, equips];
                    case 5:
                        _a = _e.sent(), weapon = _a.weapon, accessory = _a.accessory, armor = _a.armor;
                        _b = this.setState;
                        _c = {};
                        _d = { weapon: weapon, accessory: accessory, armor: armor };
                        return [4 /*yield*/, outfits];
                    case 6:
                        _b.apply(this, [(_c.equipData = (_d.outfits = _e.sent(), _d),
                                _c)]);
                        _e.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CharacterInfo.prototype.callSkillData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var id, _a, skills, status;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = this.props.character.id;
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/character/combat/" + id,
                                method: "get",
                            })];
                    case 1:
                        _a = _b.sent(), skills = _a.skills, status = _a.status;
                        this.setState({
                            loading: false,
                            skillData: {
                                skills: skills,
                                status: status,
                                selectSlot: 0,
                            },
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    CharacterInfo.prototype.render = function () {
        var content = this.props.content;
        return (_jsxs("div", __assign({ className: "info-popup", ref: this.ref }, { children: [_jsx("div", __assign({ className: "popup-scroll-container" }, { children: _jsx("div", __assign({ className: "popup-scroller" }, { children: _jsx("div", __assign({ className: "popup-contents" }, { children: this.renderPopup() })) })) })), _jsx("div", __assign({ className: "popup-title" }, { children: title[content] }))] })));
    };
    CharacterInfo.prototype.renderPopup = function () {
        var content = this.props.content;
        switch (content) {
            case "inventory":
                return this.inventory();
            case "profile":
                return this.profile();
            case "relation":
                return _jsx(Relation, { character: this.props.character });
            case "equip":
                return this.equip();
            case "skill":
                return this.combat();
            case "quest":
                return this.quest();
            default:
                return _jsx(_Fragment, {});
        }
    };
    CharacterInfo.prototype.inventory = function () {
        var _this = this;
        var _a;
        return (_jsxs("div", __assign({ className: "inventory-container" }, { children: [_jsxs("div", __assign({ className: "mailbox", role: "button", onClick: function () { return _this.dispatch(showMessage(_this.props.character.id)); } }, { children: [_jsx(EmailIcon, {}), " \uC6B0\uD3B8\uD568"] })), _jsx(Inventory, { disableTool: ((_a = this.props.character) === null || _a === void 0 ? void 0 : _a.user_id) !== this.props.state.root.user.id, useItem: true, characterId: this.props.character.id, criteria: this.ref.current })] })));
    };
    CharacterInfo.prototype.equip = function () {
        var _this = this;
        var _a;
        var _b = this.state.equipData, armor = _b.armor, accessory = _b.accessory, weapon = _b.weapon, outfits = _b.outfits;
        var equips = [weapon, armor, accessory];
        var status = equips.reduce(function (stat, equip) {
            if (!equip)
                return stat;
            Object.entries(equip.data).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                if (key === "hp")
                    return;
                stat[key] = (stat[key] || 0) + +value;
            });
            return stat;
        }, {});
        return (_jsxs("div", __assign({ className: "combat-container" }, { children: [this.box("EQUIPMENT", _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "equipments" }, { children: equips.map(function (equip, i) {
                                if (!equip) {
                                    return (_jsx("div", __assign({ className: "item-wrap" }, { children: _jsx("div", __assign({ className: "item-icon" }, { children: _jsxs("div", __assign({ className: "left" }, { children: [_jsx("img", { src: "/static/img/equip".concat(i + 1, ".png"), alt: "\uBBF8\uCC29\uC6A9" }), "\uBBF8\uCC29\uC6A9"] })) })) }), i));
                                }
                                return (_jsx(ItemIcon, { item: equip, offset: _this.ref.current }, i));
                            }) })), _jsx("br", {}), _jsx("h5", { children: "\uC7A5\uBE44 \uC2A4\uD14C\uC774\uD130\uC2A4" }), _jsx("div", __assign({ className: "status" }, { children: _jsx("div", __assign({ className: "row stat-row" }, { children: Object.entries(status).map(function (_a, i) {
                                    var _b = __read(_a, 2), stat = _b[0], value = _b[1];
                                    var statData = Battle.statusData[stat];
                                    if (!statData)
                                        return;
                                    return (_jsx("div", __assign({ className: "col-4" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-8 stat-name" }, { children: statData.name })), _jsx("div", __assign({ className: "col-4 stat-value" }, { children: "".concat(value) }))] })) }), i));
                                }) })) }))] })), this.box("OUTFITS", _jsx(OutfitList, { enableSetting: ((_a = this.props.character) === null || _a === void 0 ? void 0 : _a.user_id) === this.props.state.root.user.id, character: this.props.character, showPopup: this.showOutfitPopup, outfits: outfits || [], onSelect: function (id) { return _this.props.selectOutfit(id); }, onPreview: function (outfit) { return _this.props.previewOutfit(outfit); } }))] })));
    };
    CharacterInfo.prototype.quest = function () {
        return _jsx(QuestList, { character: this.props.character });
    };
    CharacterInfo.prototype.combat = function () {
        var _this = this;
        var _a = this.state.skillData, status = _a.status, skills = _a.skills, selectSlot = _a.selectSlot;
        var statList = [
            ["max_hp", "max_sp", "initiative"],
            ["attack", "spell", "defense"],
            ["dodge", "crit_rate", "crit_dmg"],
        ];
        return (_jsxs("div", __assign({ className: "combat-container" }, { children: [this.box("STATUS", _jsx("div", __assign({ className: "status" }, { children: statList.map(function (cols, i) { return (_jsx("div", __assign({ className: "row stat-row" }, { children: cols.map(function (stat, i) { return (_jsx("div", __assign({ className: "col-4" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-8 stat-name" }, { children: Battle.statusData[stat].name })), _jsx("div", __assign({ className: "col-4 stat-value" }, { children: "".concat(status[stat]) }))] })) }), i)); }) }), i)); }) }))), this.box("SKILLS", _jsxs("div", __assign({ className: "skills" }, { children: [_jsx("div", __assign({ className: "slots" }, { children: _jsx(SkillSlot, { skills: skills, level: this.props.character.level, onClick: function (slot) {
                                    _this.setState({
                                        skillData: __assign(__assign({}, _this.state.skillData), { selectSlot: slot }),
                                    });
                                } }) })), _jsx("div", __assign({ className: "skill-list" }, { children: skills
                                .filter(function (v) { return v.slot === selectSlot; })
                                .map(function (skill, i) { return (_jsx(SkillItem, { onClick: _this.activeSkill(skill.id), data: skill, active: skill.activated }, i)); }) }))] })), "슬롯을 선택하면 해당하는 스킬 목록이 나타납니다.")] })));
    };
    CharacterInfo.prototype.box = function (title, contents, caption) {
        return (_jsxs("div", __assign({ className: "box" }, { children: [_jsxs("div", __assign({ className: "box-title" }, { children: [_jsx("div", __assign({ className: "title" }, { children: title })), caption ? _jsx("div", __assign({ className: "caption" }, { children: caption })) : _jsx(_Fragment, {}), _jsx("div", { className: "line" })] })), _jsx("div", __assign({ className: "box-content" }, { children: contents }))] })));
    };
    CharacterInfo.prototype.profile = function () {
        var _a = this.props.character, outfit = _a.outfit, personality = _a.personality, description = _a.description, society = _a.society, secret = _a.secret, running = _a.running, battle_img = _a.battle_img, cutscene_img = _a.cutscene_img;
        var outfitData = this.props.outfit;
        var _b = this.props.state.root.user, level = _b.level, loginId = _b.id;
        var _c = this.state.user, id = _c.id, username = _c.username, birth = _c.birth, twitter = _c.twitter;
        return (_jsxs("div", __assign({ className: "profile-container" }, { children: [_jsxs("section", { children: [_jsx("h5", { children: "\uC678\uACAC" }), _jsx("p", { dangerouslySetInnerHTML: {
                                __html: encodeText((outfitData === null || outfitData === void 0 ? void 0 : outfitData.outfit) || outfit),
                            } }), _jsxs("div", __assign({ className: "outfit-buttons" }, { children: [_jsxs("a", __assign({ href: (outfitData === null || outfitData === void 0 ? void 0 : outfitData.battle_img) || battle_img, target: "_blank" }, { children: ["\uC0C1\uBC18\uC2E0 \uC774\uBBF8\uC9C0", _jsx(OpenInNewIcon, {})] })), _jsx("br", {}), _jsxs("a", __assign({ href: (outfitData === null || outfitData === void 0 ? void 0 : outfitData.cutscene_img) || cutscene_img, target: "_blank" }, { children: ["\uC2A4\uD0AC \uCEF7\uC778 \uC774\uBBF8\uC9C0", _jsx(OpenInNewIcon, {})] }))] }))] }), _jsxs("section", { children: [_jsx("h5", { children: "\uC131\uACA9" }), _jsx("p", { dangerouslySetInnerHTML: { __html: encodeText(personality) } })] }), _jsxs("section", { children: [_jsx("h5", { children: "\uBAA8\uD615\uC138\uACC4\uC5D0\uC11C\uC758 \uBAA8\uC2B5" }), _jsx("p", { dangerouslySetInnerHTML: { __html: encodeText(society) } })] }), _jsxs("section", { children: [_jsx("h5", { children: "\uADF8\uC678 \uC124\uC815" }), _jsx("p", { dangerouslySetInnerHTML: { __html: encodeText(description) } })] }), (id && loginId === id) || level >= Level.STAFF ? (_jsxs(_Fragment, { children: [_jsxs("section", { children: [_jsx("h5", { children: "\uBE44\uACF5\uAC1C \uC815\uBCF4" }), _jsxs("div", __assign({ className: "desc" }, { children: [_jsx(LockIcon, {}), "\uC6B4\uC601\uC9C4\uACFC \uC624\uB108\uC5D0\uAC8C\uB9CC \uACF5\uAC1C\uB418\uB294 \uD56D\uBAA9\uC785\uB2C8\uB2E4."] })), _jsx("p", { dangerouslySetInnerHTML: { __html: encodeText(secret) } })] }), _jsxs("section", { children: [_jsx("h6", { children: "\uD604\uC7AC \uC900\uBE44 \uC911(\uB7EC\uB2DD \uC911)\uC778 \uCEE4\uBBA4\uB2C8\uD2F0 \uC218" }), _jsx("p", { children: running }), _jsx("h6", { children: "\uC720\uC800 \uC815\uBCF4" }), _jsxs("p", { children: ["\uB2C9\uB124\uC784: ", username, _jsx("br", {}), "\uC0DD\uB144: ", birth, _jsx("br", {}), "\uD2B8\uC704\uD130: ", twitter] })] })] })) : (_jsx(_Fragment, {}))] })));
    };
    __decorate([
        WatchProp("updateTimer"),
        WatchProp("content")
    ], CharacterInfo.prototype, "onChangeContent", null);
    return CharacterInfo;
}(SiteComponent));
export default connect()(CharacterInfo);
