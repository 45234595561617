var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
export default function QuestList(_a) {
    var _b, _c;
    var quests = _a.quests, instances = _a.instances, onSubmit = _a.onSubmit, onGet = _a.onGet, onCancel = _a.onCancel;
    var _d = __read(useState(), 2), selectedId = _d[0], setSelectedId = _d[1];
    var activeId = selectedId || ((_b = quests[0]) === null || _b === void 0 ? void 0 : _b.id) || 0;
    var selected = quests.find(function (v) { return v.id === activeId; });
    var instance = instances.find(function (i) { return i.quest_id === activeId; });
    return (_jsxs("div", __assign({ className: "list-preview" }, { children: [_jsxs("div", __assign({ className: "list-container" }, { children: [_jsx("h4", { children: "\uD018\uC2A4\uD2B8 \uBAA9\uB85D" }), _jsx("div", __assign({ className: "list" }, { children: _jsx("div", __assign({ className: "list-scroller" }, { children: _jsx("div", __assign({ className: "scroll-contents" }, { children: quests.map(function (_a, i) {
                                    var name = _a.name, id = _a.id, place = _a.place, close_timestamp = _a.close_timestamp, open_timestamp = _a.open_timestamp;
                                    var date = "".concat(moment(close_timestamp).format("YY/MM/DD HH:mm"), "\uAE4C\uC9C0");
                                    return (_jsxs("div", __assign({ onClick: function () { return setSelectedId(id); }, className: "item ".concat(id === activeId ? "selected" : "") }, { children: [_jsx("div", __assign({ className: "title" }, { children: name })), _jsx("div", __assign({ className: "date" }, { children: date })), place && (_jsx("img", { className: "bg", src: place.background_image, alt: "\uBC30\uACBD" }))] }), i));
                                }) })) })) }))] })), _jsxs("div", __assign({ className: "preview-container" }, { children: [_jsx("h4", { children: "\uD018\uC2A4\uD2B8 \uC0C1\uC138" }), selected ? (_jsx("div", __assign({ className: "preview-outline" }, { children: _jsxs("div", __assign({ className: "preview", style: {
                                backgroundImage: "url('".concat(selected.place ? selected.place.background_image : "", "')"),
                            } }, { children: [_jsx("div", __assign({ className: "title" }, { children: selected.name })), _jsx("div", __assign({ className: "date" }, { children: moment(selected.open_timestamp).format("YY/MM/DD HH:mm") +
                                        " ~ " +
                                        moment(selected.close_timestamp).format("YY/MM/DD HH:mm") })), _jsx("div", __assign({ className: "desc" }, { children: selected.description })), _jsxs("div", __assign({ className: "rewards" }, { children: [_jsx("div", __assign({ className: "reward-name" }, { children: "\uC644\uC218 \uC870\uAC74" })), _jsxs("p", { children: [selected.condition_description, " ", instance && !instance.completed
                                                    ? "(".concat(instance.completable + selected.amount, "/").concat(selected.amount, ")")
                                                    : "x ".concat(selected.amount)] }), _jsx("div", __assign({ className: "reward-name" }, { children: "\uC644\uC218 \uBCF4\uC0C1" })), (_c = selected.reward) === null || _c === void 0 ? void 0 : _c.map(function (_a, i) {
                                            var item = _a.item, amount = _a.amount;
                                            if (!item)
                                                return;
                                            return (_jsx("img", { className: "reward", title: item.name + " ".concat(amount, "\uAC1C"), src: item.icon, alt: item.name }, i));
                                        })] })), _jsx("div", __assign({ className: "buttons" }, { children: !instance ? (_jsxs("div", __assign({ className: "button large", onClick: function () { return onGet(activeId); } }, { children: ["\uC218\uC8FC\uD558\uAE30", _jsx(ArrowForwardIcon, {})] }))) : instance.completed ? (_jsx(_Fragment, { children: "\uBCF4\uACE0\uB97C \uC644\uB8CC\uD588\uC2B5\uB2C8\uB2E4." })) : (_jsxs(_Fragment, { children: [instance.completable >= 0 ? (_jsxs("div", __assign({ className: "button", onClick: function () { return onSubmit(instance.id); } }, { children: ["\uBCF4\uACE0\uD558\uAE30", _jsx(CheckIcon, {})] }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "button", onClick: function () { return onCancel(instance.id); } }, { children: ["\uCDE8\uC18C\uD558\uAE30", _jsx(ClearIcon, {})] }))] })) }))] })) }))) : (_jsx("div", __assign({ className: "no-selection" }, { children: "\uBAA9\uB85D\uC5D0\uC11C \uD018\uC2A4\uD2B8\uB97C \uC120\uD0DD\uD574\uC8FC\uC138\uC694." })))] }))] })));
}
