var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { debounce } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent, WatchProp, WatchState } from "../structure";
import "./searchSelect.scss";
var SearchSelect = /** @class */ (function (_super) {
    __extends(SearchSelect, _super);
    function SearchSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            search: (_this.props.value || ""),
            data: [],
            active: false,
            selected: _this.props.selected,
            selectedName: _this.props.value,
        };
        _this.ref = React.createRef();
        _this.watchSearch = debounce(function (cur) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callData(cur)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, 200);
        _this.select = function (id, name) {
            var onSelect = _this.props.onSelect;
            onSelect && onSelect(id, name);
            _this.setState({
                search: name,
                active: false,
                selected: id,
                selectedName: name,
            });
        };
        _this.onChange = function (e) {
            var value = e.target.value;
            _this.setState({ search: value });
        };
        _this.createNewItem = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, api, createData, search, id;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, api = _a.api, createData = _a.createData;
                        search = this.state.search;
                        if (!search)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/".concat(api),
                                method: "POST",
                                data: __assign(__assign({}, createData), { name: search.trim() }),
                            })];
                    case 1:
                        id = (_b.sent()).id;
                        this.select(id, search.trim());
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleClickOutside = function (event) {
            if (_this.ref &&
                _this.ref.current &&
                !_this.ref.current.contains(event.target)) {
                _this.setState({ active: false, search: _this.state.selectedName });
            }
        };
        return _this;
    }
    SearchSelect.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        document.addEventListener("mousedown", this.handleClickOutside);
                        return [4 /*yield*/, this.setToSelectedItem()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchSelect.prototype.componentWillUnmount = function () {
        document.removeEventListener("mousedown", this.handleClickOutside);
    };
    SearchSelect.prototype.watchSelected = function (cur) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.state.selected !== this.props.selected)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setToSelectedItem()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SearchSelect.prototype.setToSelectedItem = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, selectedItem;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callData()];
                    case 1:
                        data = _a.sent();
                        selectedItem = data.find(function (_a) {
                            var id = _a.id;
                            return Number(id) === Number(_this.props.selected);
                        });
                        if (selectedItem) {
                            this.setState({
                                selected: selectedItem.id,
                                search: selectedItem.name,
                                selectedName: selectedItem.name,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchSelect.prototype.callData = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, api, params, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, api = _a.api, params = _a.params;
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/".concat(api),
                                params: __assign(__assign({}, params), { search: search }),
                                method: "GET",
                            })];
                    case 1:
                        data = (_b.sent()).data;
                        this.setState({ data: data });
                        return [2 /*return*/, data];
                }
            });
        });
    };
    SearchSelect.prototype.render = function () {
        var _this = this;
        var _a = this.props, containerClass = _a.containerClass, children = _a.children, dispatch = _a.dispatch, disallowCreate = _a.disallowCreate, onSelect = _a.onSelect, value = _a.value, _b = _a.ignoreCreateSearch, ignoreCreateSearch = _b === void 0 ? [] : _b, showListTop = _a.showListTop, createData = _a.createData, props = __rest(_a, ["containerClass", "children", "dispatch", "disallowCreate", "onSelect", "value", "ignoreCreateSearch", "showListTop", "createData"]);
        var _c = this.state, search = _c.search, data = _c.data, active = _c.active;
        var canCreate = !disallowCreate &&
            search &&
            ignoreCreateSearch.indexOf(search) < 0 &&
            !data.some(function (_a) {
                var name = _a.name;
                return search.trim() === name;
            });
        return (_jsxs("div", __assign({ ref: this.ref, className: "".concat(containerClass, " search-select ").concat(active ? "active" : "", " ").concat(showListTop ? "inverse" : "") }, { children: [_jsx("input", __assign({}, props, { autoComplete: "off", value: search || "", onFocus: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            this.setState({ active: true });
                            return [2 /*return*/];
                        });
                    }); }, onChange: this.onChange })), _jsxs("ul", { children: [canCreate ? (_jsxs("li", __assign({ onClick: this.createNewItem }, { children: ["\uC2E0\uADDC \uC0DD\uC131: ", _jsx("span", __assign({ className: "text-primary" }, { children: search }))] }))) : null, children && children(data, this.select), data.map(function (_a) {
                            var name = _a.name, id = _a.id;
                            return (_jsx("li", __assign({ onClick: function () { return _this.select(id, name); } }, { children: name }), id));
                        }), _jsx("li", __assign({ onClick: function () { return _this.select("", ""); } }, { children: "\uC120\uD0DD \uCDE8\uC18C" }))] })] })));
    };
    __decorate([
        WatchProp("selected")
    ], SearchSelect.prototype, "watchSelected", null);
    __decorate([
        WatchState("search")
    ], SearchSelect.prototype, "watchSearch", void 0);
    return SearchSelect;
}(ReactComponent));
export default connect(function () { return ({ state: undefined }); })(SearchSelect);
