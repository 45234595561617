var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { times } from "lodash";
export default function LetterAnimation(_a) {
    var text = _a.text, skip = _a.skip, onFinish = _a.onFinish, _b = _a.muted, muted = _b === void 0 ? false : _b;
    var audioPool = 5;
    var audio = useMemo(function () {
        return times(audioPool, function () {
            var audio = new Audio("/static/audio/keyboard.wav");
            audio.loop = false;
            audio.volume = 0.3;
            return audio;
        });
    }, []);
    var _c = __read(useState(0), 2), idx = _c[0], setIdx = _c[1];
    var finished = useRef(false);
    var _d = __read(useState([]), 2), nodes = _d[0], setNodes = _d[1];
    var _e = __read(useState([]), 2), texts = _e[0], setTexts = _e[1];
    var timeout = useRef();
    var finishCallback = useRef(function () { return onFinish(); });
    useEffect(function () {
        clearTimeout(timeout.current);
        var tokens = text.match(/(.|\n){1}/gi) || [];
        setTexts(tokens);
        setNodes(tokens
            .map(function (t, i) {
            if (t === "\n") {
                return _jsx("br", {}, i);
            }
            return (_jsx("span", __assign({ className: "animate-letter" }, { children: t }), i));
        })
            .flat());
        finished.current = false;
        setIdx(0);
        if (!muted) {
            var audioObj = audio[0];
            audioObj.currentTime = 0;
            audioObj.play();
        }
    }, [text, audio]);
    useEffect(function () {
        if (skip) {
            finished.current = true;
        }
    }, [skip]);
    useEffect(function () {
        if (idx + 1 < texts.length && !finished.current) {
            if (!/(\s|\.)/.test(texts[idx + 1])) {
                if (!muted) {
                    var audioObj = audio[idx % audioPool];
                    audioObj.currentTime = 0;
                    audioObj.play();
                }
            }
            timeout.current = setTimeout(function () {
                setIdx(idx + 1);
            }, 70);
        }
        else if (texts.length > 0) {
            finished.current = true;
            finishCallback.current();
        }
    }, [texts, idx, audio]);
    return (_jsxs(_Fragment, { children: [nodes.slice(0, finished.current ? nodes.length : idx + 1), _jsx("span", __assign({ className: "animate-letter", style: { display: finished.current ? "inline" : "none" } }, { children: _jsx(ArrowDropDownIcon, { className: "next-indicator" }) }))] }));
}
