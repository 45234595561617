var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
export function Welcome() {
    return (_jsx("div", __assign({ className: "login-container" }, { children: _jsxs("div", __assign({ className: "welcome-box" }, { children: [_jsxs("div", __assign({ className: "text" }, { children: ["\uC774\uBA54\uC77C \uC778\uC99D\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.", _jsx("br", {}), "\uD68C\uC6D0\uAC00\uC785\uC744 \uD658\uC601\uD569\uB2C8\uB2E4."] })), _jsx(Link, __assign({ to: "/login" }, { children: _jsx("button", __assign({ type: "button" }, { children: "\uB85C\uADF8\uC778 \uD654\uBA74\uC73C\uB85C" })) }))] })) })));
}
