var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SiteComponent, connect } from "../structure";
import "./character.scss";
import moment from "moment";
var QuestList = /** @class */ (function (_super) {
    __extends(QuestList, _super);
    function QuestList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            quests: [],
            selected: 0,
        };
        _this.onSubmit = function (id) { return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/quest/complete/" + id,
                            method: "post",
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.onCancel = function (id) { return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/quest/instance/" + id,
                            method: "delete",
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.select = function (id) { return function () {
            _this.setState({ selected: id === _this.state.selected ? 0 : id });
        }; };
        return _this;
    }
    QuestList.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestList.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var quests;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/quest/instance/" + this.props.character.id,
                            method: "get",
                        })];
                    case 1:
                        quests = _a.sent();
                        this.setState({ quests: quests });
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestList.prototype.render = function () {
        return (_jsxs("div", __assign({ className: "quest-container" }, { children: [this.list(false), this.list(true)] })));
    };
    QuestList.prototype.list = function (completed) {
        var _this = this;
        var quests = this.state.quests.filter(function (q) { return !!q.completed === completed; });
        return (_jsxs("div", __assign({ className: "box" }, { children: [_jsxs("div", __assign({ className: "box-title" }, { children: [_jsxs("div", __assign({ className: "title" }, { children: [completed ? "완료한" : "진행중인", " \uD018\uC2A4\uD2B8"] })), _jsx("div", { className: "line" })] })), _jsx("div", __assign({ className: "box-content" }, { children: _jsx("div", __assign({ className: "quest-list" }, { children: quests.map(function (_a, i) {
                            var id = _a.id, quest = _a.quest, completable = _a.completable, created_timestamp = _a.created_timestamp, updated_timestamp = _a.updated_timestamp;
                            var _b = quest, name = _b.name, description = _b.description, condition_description = _b.condition_description, amount = _b.amount;
                            var selected = _this.state.selected === id ? "selected" : "";
                            return (_jsxs("div", __assign({ className: "quest ".concat(selected) }, { children: [_jsx("div", __assign({ className: "title", role: "button", onClick: _this.select(id) }, { children: name })), _jsxs("div", __assign({ className: "contents" }, { children: [_jsxs("div", __assign({ className: "date" }, { children: ["\uC218\uC8FC:", " ", moment(created_timestamp).format("YY.MM.DD HH:mm"), completed
                                                        ? " / \uBCF4\uACE0:\n                            ".concat(moment(updated_timestamp).format("YY.MM.DD HH:mm"))
                                                        : ""] })), _jsx("div", __assign({ className: "desc" }, { children: description })), _jsxs("div", __assign({ className: "condition" }, { children: ["\uC644\uC218\uC870\uAC74: ", condition_description, !completed
                                                        ? "(".concat(completable + amount, "/").concat(amount, ")")
                                                        : " x ".concat(amount)] })), completed ? (_jsx(_Fragment, {})) : (_jsxs("div", __assign({ className: "buttons" }, { children: [completable >= 0 ? (_jsx("button", __assign({ onClick: _this.onSubmit(id) }, { children: "\uBCF4\uACE0\uD558\uAE30" }))) : (_jsx(_Fragment, {})), _jsx("button", __assign({ onClick: _this.onCancel(id) }, { children: "\uCDE8\uC18C\uD558\uAE30" }))] })))] }))] }), i));
                        }) })) }))] })));
    };
    return QuestList;
}(SiteComponent));
export default connect()(QuestList);
