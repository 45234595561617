var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
export default function Enemy(_a) {
    var enemyData = _a.enemyData, monsterData = _a.monsterData;
    var name = monsterData.name;
    var hp = enemyData.hp, max_hp = enemyData.max_hp;
    var _b = __read(useState(hp), 2), lastHP = _b[0], setLastHP = _b[1];
    var _c = __read(useState(false), 2), damaged = _c[0], setDamaged = _c[1];
    var _d = __read(useState(false), 2), isDead = _d[0], setIsDead = _d[1];
    useEffect(function () {
        if (hp < lastHP) {
            setDamaged(true);
        }
        if (hp === 0) {
            setTimeout(function () {
                setIsDead(true);
            }, 1000);
        }
        else {
            setIsDead(false);
        }
        setLastHP(hp);
    }, [hp]);
    useEffect(function () {
        if (!damaged)
            return;
        setTimeout(function () {
            setDamaged(false);
        }, 1000);
    }, [damaged]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "sprite ".concat(damaged ? "damaged" : "", " ").concat(isDead ? "dead" : "") }, { children: _jsx("img", { src: monsterData.image, alt: "\uBAAC\uC2A4\uD130" }) })), _jsxs("div", __assign({ className: "info" }, { children: [_jsx("div", __assign({ className: "name" }, { children: name })), _jsx("div", __assign({ className: "buffs" }, { children: enemyData.buffs
                            .filter(function (b) { return !b.hidden; })
                            .map(function (buff, i) {
                            return (_jsxs("div", __assign({ className: "buff ".concat(buff.group) }, { children: [_jsx("img", { src: buff.icon_img, alt: buff.name, title: buff.name }), buff.expire_type !== "ever" ? (_jsx("div", __assign({ className: "counter" }, { children: buff.expire_count }))) : (_jsx(_Fragment, {}))] }), i));
                        }) })), _jsxs("div", __assign({ className: "hp" }, { children: [_jsx("div", { className: "hp-bar", style: { width: "".concat((hp / max_hp) * 220, "px") } }), _jsxs("span", __assign({ className: "hp-text" }, { children: [_jsx("strong", { children: hp }), " / ", max_hp] }))] })), _jsx("div", { className: "arrow" })] }))] }));
}
