var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Battle } from "@iter-acillba/core";
import { useEffect, useRef, useState } from "react";
var TEXT_PRINT_INTERVAL = 200;
var TEXT_CLEAR_TIMEOUT = 3000;
export default function EffectText(_a) {
    var type = _a.type, actions = _a.actions, buffs = _a.buffs, onAnimationEnd = _a.onAnimationEnd;
    var _b = __read(useState([]), 2), texts = _b[0], setTexts = _b[1];
    var updateStateCallback = useRef(function (key, state) { });
    function updateState(key, state) {
        var queue = __spreadArray([], __read(texts), false);
        var target = queue.find(function (v) { return v.printTime === key; });
        var idx = queue.indexOf(target);
        if (idx < 0)
            return;
        queue[idx] = __assign(__assign({}, target), { state: state });
        setTexts(queue);
    }
    useEffect(function () {
        updateStateCallback.current = updateState;
    });
    useEffect(function () {
        if (actions.length === 0)
            return;
        var data = actions.map(function (v) { return ({ data: v.data, state: "" }); });
        data = data.map(function (d, i) {
            var key = "".concat(Date.now(), "_").concat(i);
            setTimeout(function () {
                updateStateCallback.current(key, "display");
            }, TEXT_PRINT_INTERVAL * i);
            setTimeout(function () {
                updateStateCallback.current(key, "hidden");
            }, TEXT_PRINT_INTERVAL * i + TEXT_CLEAR_TIMEOUT);
            return __assign(__assign({}, d), { printTime: key });
        });
        var isHidden = texts.every(function (t) { return t.state === "hidden"; });
        var queue = __spreadArray(__spreadArray([], __read((isHidden ? [] : texts)), false), __read(data), false);
        setTexts(queue);
        onAnimationEnd();
    }, [actions]);
    return (_jsx("div", __assign({ className: "effect-text ".concat(type) }, { children: texts.map(function (v) {
            var _a, _b, _c;
            var printTime = v.printTime, data = v.data, state = v.state;
            if (state === "") {
                return _jsx("div", { style: { display: "none" } }, printTime);
            }
            switch (data.effectType) {
                case Battle.EffectType.GAIN_SP: {
                    return (_jsxs("div", __assign({ className: "sp" }, { children: ["+ ", data.value, " SP"] }), printTime));
                }
                case Battle.EffectType.DAMAGE:
                    if ((_a = data.option) === null || _a === void 0 ? void 0 : _a.dodge) {
                        return (_jsx("div", __assign({ className: "dodge" }, { children: "Dodge" }), printTime));
                    }
                    if (data.value === 0) {
                        return (_jsx("div", __assign({ className: "block" }, { children: "Block" }), printTime));
                    }
                    return (_jsxs("div", __assign({ className: "damage ".concat(((_b = data.option) === null || _b === void 0 ? void 0 : _b.ciritical) ? "crit" : "") }, { children: [((_c = data.option) === null || _c === void 0 ? void 0 : _c.ciritical) ? (_jsx("div", __assign({ className: "crit-text" }, { children: "Critical!" }))) : (_jsx(_Fragment, {})), data.value] }), printTime));
                case Battle.EffectType.REMOVE_BUFF:
                case Battle.EffectType.ADD_BUFF:
                    var buff = buffs.find(function (v) { return v.id === data.value; });
                    if ((buff === null || buff === void 0 ? void 0 : buff.hidden) || !buff)
                        return _jsx(_Fragment, {});
                    return (_jsxs("div", __assign({ className: "buff-item" }, { children: [data.effectType === Battle.EffectType.REMOVE_BUFF ? "-" : "+", _jsx("div", __assign({ className: "buff good" }, { children: _jsx("img", { src: buff === null || buff === void 0 ? void 0 : buff.icon_img, alt: buff === null || buff === void 0 ? void 0 : buff.name }) })), buff === null || buff === void 0 ? void 0 : buff.name] }), printTime));
                case Battle.EffectType.RESURRECTION:
                case Battle.EffectType.HEAL:
                    return (_jsxs("div", __assign({ className: "heal" }, { children: ["+", data.value] }), printTime));
            }
        }) })));
}
