export function htmlToString(html) {
    var el = window.document.createElement("div");
    el.innerHTML = html;
    var arr = Array.from(el.children);
    return arr.map(function (v) { return v.textContent; }).join("\n");
}
export function encodeText(str) {
    return replaceNewline(replaceTokens(str));
}
// #abcd - 일반 링크
// #[abc]abcd - 일반 링크(링크 텍스트 변환)
// #!abcd - 이미지 링크
export function replaceTokens(str) {
    if (!str)
        return "";
    var linkMatch = /\#([\S]+)/i;
    var replaceText = /^\[([\S]+)\]([\S]+)/;
    var replaceImage = /^\!([\S]+)/;
    var result = str;
    var token = result.match(linkMatch);
    while (token) {
        var sliced = result.split(token[0]);
        var value = token[1];
        if (replaceText.test(value)) {
            var matched = value.match(replaceText);
            var text = matched[1];
            var link = matched[2];
            result = sliced.join("<a href=\"".concat(link, "\" target=\"_blank\">").concat(text, "</a>"));
        }
        else if (replaceImage.test(value)) {
            var matched = value.match(replaceImage);
            var src = matched[1];
            result = sliced.join("<img src=\"".concat(src, "\" alt=\"\uCCA8\uBD80\uC774\uBBF8\uC9C0\" />"));
        }
        else {
            result = sliced.join("<a href=\"".concat(value, "\" target=\"_blank\">").concat(value, "</a>"));
        }
        token = result.match(linkMatch);
    }
    return result;
}
export function replaceNewline(str) {
    return str.split("\n").join("<br />");
}
export function hexToRGBA(hex, opacity) {
    if (opacity === void 0) { opacity = 1; }
    var hexCode = hex.replace("#", "");
    var red = parseInt(hexCode[0] + hexCode[1], 16);
    var green = parseInt(hexCode[2] + hexCode[3], 16);
    var blue = parseInt(hexCode[4] + hexCode[5], 16);
    return "rgba(".concat(red, ",").concat(green, ",").concat(blue, ",").concat(opacity, ")");
}
