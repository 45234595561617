var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { skillSlotLevel } from "@iter-acillba/core";
import LockIcon from "@mui/icons-material/Lock";
import "./skill.scss";
export default function SkillSlot(_a) {
    var skills = _a.skills, onClick = _a.onClick, level = _a.level;
    var slotArr = [1, 2, 3, 4, 5, 6];
    var activated = skills.filter(function (v) { return v.activated; });
    return (_jsx(_Fragment, { children: slotArr.map(function (slot, i) {
            var skill = activated.find(function (v) { return v.slot === slot; });
            return (_jsxs("div", __assign({ onClick: function () { return onClick && onClick(slot); }, className: "skill-slot" }, { children: [skill ? (_jsx("div", __assign({ className: "skill-icon" }, { children: _jsx("img", { src: skill.icon_img, alt: skill.name }) }))) : (_jsx(_Fragment, {})), skillSlotLevel[slot] > level ? (_jsx("div", __assign({ className: "lock" }, { children: _jsx(LockIcon, {}) }))) : (_jsx(_Fragment, {}))] }), i));
        }) }));
}
