var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dungeon, SkillType, } from "@iter-acillba/core";
import React from "react";
import { io } from "socket.io-client";
import { ChatList } from "./ChatList";
import Enemy from "./Enemy";
import Ally from "./Ally";
import Unit from "./Unit";
import Command from "./Command";
import CommandList from "./CommandList";
import RoomPopup from "../RoomPopup";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent, WatchState, toast } from "../../structure";
import { Banner } from "../Banner/Banner";
import Cutin from "../Cutin/Cutin";
import "./battle.scss";
import { Standby } from "./Standby";
import { InfoPopup } from "./InfoPopup";
var Message = Dungeon.Message;
var BattleRoom = /** @class */ (function (_super) {
    __extends(BattleRoom, _super);
    function BattleRoom() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animationEndFlag = [];
        _this.state = {
            bgm: undefined,
            state: Dungeon.State.STANDBY,
            roomState: "standby",
            context: undefined,
            skills: [],
            selectedSkill: undefined,
            targets: [],
            actions: [],
            effects: [],
            buffs: [],
            contextId: 0,
            contextQueue: [],
            showPopup: false,
            cutin: 0,
            showBanner: undefined,
            players: [],
            stage: undefined,
            roomId: undefined,
            chats: [],
            viewInfo: undefined,
            focusChat: false,
            confirmCommand: false,
        };
        _this.onAnimationEnd = function (key) { return function () {
            var keys = __spreadArray(__spreadArray([], __read(_this.animationEndFlag), false), [key], false);
            var allUnitAnimationEnd = __spreadArray(__spreadArray([], __read(_this.state.context.allies), false), __read(_this.state.context.enemies), false).map(function (v) { return v.key; })
                .every(function (unit) { return !!keys.find(function (v) { return v === unit; }); });
            var allNotifyEnd = ["command"].every(function (v) { return keys.includes(v); });
            if (allUnitAnimationEnd && allNotifyEnd) {
                _this.forceUpdate(function () {
                    _this.runAnimation();
                });
            }
            else {
                _this.animationEndFlag = keys;
            }
        }; };
        _this.surrender = function () {
            _this.sendMessage(Message.SURRENDER);
            _this.setState({ showPopup: false });
        };
        _this.bannerAnimationEnd = function (type) {
            _this.setState({ showBanner: undefined });
        };
        _this.selectSkill = function (id) {
            if (id === -1) {
                _this.setState({ selectedSkill: undefined, targets: [] });
            }
            else {
                var selectedSkill = _this.state.skills.find(function (v) { return v.id === id; });
                var context = _this.state.context;
                var unit = _this.getPlayerUnit();
                var activeData = unit.skills.find(function (v) { return v.skill_id === id; });
                var targets = [];
                if (!selectedSkill) {
                    return;
                }
                if (activeData && activeData.cooldown > 0) {
                    _this.dispatch(toast({ text: activeData.cooldown + "턴간 사용 불가합니다." }));
                    return;
                }
                if (unit && unit.sp < selectedSkill.sp) {
                    _this.dispatch(toast({ text: "sp가 부족합니다." }));
                    return;
                }
                if (selectedSkill.target_type === "self") {
                    targets = [_this.getPlayerUnit().key];
                }
                else if (["all", "random"].includes(selectedSkill.condition_type)) {
                    var condition = selectedSkill.type === SkillType.RESURRECTION
                        ? function (u) { return u.hp === 0; }
                        : function (u) { return u.hp > 0; };
                    if (selectedSkill.target_type === "enemy") {
                        targets = context.enemies.filter(condition).map(function (v) { return v.key; }) || [];
                    }
                    else if (selectedSkill.target_type === "ally") {
                        targets = context.allies.filter(condition).map(function (v) { return v.key; }) || [];
                    }
                    else {
                        targets = __spreadArray(__spreadArray([], __read(context.allies.map(function (v) { return v.key; })), false), __read(context.enemies.map(function (v) { return v.key; })), false);
                    }
                }
                _this.setState({ selectedSkill: selectedSkill, targets: targets });
            }
        };
        _this.selectTarget = function (key) { return function () {
            var _a = _this.state, selectedSkill = _a.selectedSkill, targets = _a.targets;
            if (["all", "random"].includes(selectedSkill.condition_type))
                return;
            if (targets.includes(key)) {
                _this.setState({ targets: _this.state.targets.filter(function (v) { return v !== key; }) });
            }
            else {
                if (targets.length === (selectedSkill === null || selectedSkill === void 0 ? void 0 : selectedSkill.target_count)) {
                    _this.dispatch(toast({ text: "더이상 선택할 수 없습니다." }));
                }
                else {
                    _this.setState({ targets: __spreadArray(__spreadArray([], __read(targets), false), [key], false) });
                }
            }
        }; };
        _this.confirmCommand = function () {
            var targets = _this.state.targets;
            if (targets.length === 0) {
                _this.dispatch(toast({ text: "타겟을 선택하지 않았습니다." }));
                return;
            }
            _this.setState({
                selectedSkill: undefined,
                targets: [],
                confirmCommand: true,
            });
            _this.sendMessage(Message.COMMAND, {
                skillId: _this.state.selectedSkill.id,
                targets: _this.state.targets,
            });
        };
        _this.wait = function () {
            _this.setState({ confirmCommand: true });
            _this.sendMessage(Message.COMMAND, {});
        };
        _this.onSubmitLog = function (message) {
            if (message.length === 0)
                return;
            _this.sendMessage(Message.CHAT, { message: message });
        };
        _this.sendMessage = function (event, data) {
            _this.socket.emit(event, data);
        };
        return _this;
    }
    BattleRoom.prototype.watchStage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var place;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.stage)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/explore/place/" + this.state.stage.place_id,
                                method: "get",
                            })];
                    case 1:
                        place = _a.sent();
                        this.setState({ place: place });
                        return [4 /*yield*/, this.updateBgm(this.state.stage.bgm_id || place.bgm_id)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BattleRoom.prototype.updateBgm = function (bgm_id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var bgm;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!bgm_id || ((_a = this.state.bgm) === null || _a === void 0 ? void 0 : _a.id) === bgm_id)
                            return [2 /*return*/];
                        if (this.state.roomState !== "battle")
                            return [2 /*return*/];
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/site/bgm/" + bgm_id,
                                method: "get",
                            })];
                    case 1:
                        bgm = _b.sent();
                        this.setState({ bgm: bgm });
                        this.props.changeMusic(bgm);
                        return [2 /*return*/];
                }
            });
        });
    };
    BattleRoom.prototype.watchRoomState = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.state.roomState === "battle")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateBgm(((_a = this.state.stage) === null || _a === void 0 ? void 0 : _a.bgm_id) || ((_b = this.state.place) === null || _b === void 0 ? void 0 : _b.bgm_id))];
                    case 1:
                        _c.sent();
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BattleRoom.prototype.componentDidMount = function () {
        this.initSocket();
        this.socket.emit(Message.ENTER_ROOM, { roomId: this.props.roomId });
    };
    BattleRoom.prototype.initSocket = function () {
        var _this = this;
        if (this.socket)
            return;
        var protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        this.socket = io("".concat(protocol, "//").concat(window.location.hostname, ":81"), {
            withCredentials: true,
            transports: ["websocket"],
        });
        this.socket.on(Message.ENTER_ROOM, function (_a) {
            var room = _a.room, context = _a.context, skills = _a.skills, effects = _a.effects, buffs = _a.buffs;
            var _b = room, players = _b.players, chats = _b.chats, roomId = _b.roomId, stage = _b.stage, state = _b.state;
            _this.setState({
                players: players,
                chats: chats,
                roomId: roomId,
                stage: stage,
                state: state,
                context: context,
                skills: skills,
                effects: effects,
                buffs: buffs,
                roomState: state === Dungeon.State.STANDBY ? "standby" : "battle",
            });
            if (context && context.round === 1) {
                _this.setState({ showBanner: "start" });
            }
        });
        this.socket.on(Message.ERROR, function (_a) {
            var message = _a.message;
            _this.props.onError(message);
        });
        this.socket.on(Message.READY, function (_a) {
            var player = _a.player;
            _this.updatePlayer(__assign(__assign({}, player), { isReady: true }));
        });
        this.socket.on(Message.NO_ROOM, function () {
            _this.props.onExit("exit");
        });
        this.socket.on(Message.UNREADY, function (_a) {
            var player = _a.player;
            _this.updatePlayer(__assign(__assign({}, player), { isReady: false }));
        });
        this.socket.on(Message.EXIT_ROOM, function (_a) {
            var player = _a.player;
            if (player.user.id === _this.props.user.id) {
                _this.props.onExit("exit");
                return;
            }
            var players = __spreadArray([], __read(_this.state.players), false);
            var delIdx = players.findIndex(function (_a) {
                var id = _a.user.id;
                return id === player.user.id;
            });
            players.splice(delIdx, 1);
            _this.setState({ players: players });
        });
        this.socket.on(Message.JOIN_ROOM, function (_a) {
            var player = _a.player;
            _this.setState({ players: __spreadArray(__spreadArray([], __read(_this.state.players), false), [player], false) });
        });
        this.socket.on(Message.CHAT, function (_a) {
            var chat = _a.chat;
            _this.setState({ chats: __spreadArray(__spreadArray([], __read(_this.state.chats), false), [chat], false) });
        });
        this.socket.on(Message.START, function (_a) {
            var context = _a.context;
            _this.setState({ context: context, roomState: "battle", showBanner: "start" });
        });
        this.socket.on(Message.STANDBY_COMMAND, function (_a) {
            var players = _a.players;
            _this.setState({
                state: Dungeon.State.READY,
                players: players,
                confirmCommand: false,
            });
        });
        this.socket.on(Message.FINISH, function () {
            _this.setState({
                state: Dungeon.State.SUCCESS,
                showBanner: "finish",
                bgm: undefined,
            });
            _this.props.onGameEnd("success");
        });
        this.socket.on(Message.FAILED, function () {
            _this.setState({
                state: Dungeon.State.FAILED,
                showBanner: "fail",
                bgm: undefined,
            });
            _this.props.onGameEnd("fail");
        });
        this.socket.on(Message.READY_COMMAND, function (_a) {
            var players = _a.players;
            _this.setState({ players: players });
        });
        this.socket.on(Message.UPDATE_CONTEXT, function (_a) {
            var contexts = _a.contexts, actions = _a.actions, players = _a.players;
            _this.setState({ contextQueue: contexts, actions: actions, players: players });
            _this.forceUpdate(function () {
                _this.runAnimation();
            });
        });
    };
    BattleRoom.prototype.runAnimation = function () {
        var _this = this;
        var skills = this.state.skills;
        var _a = this.state, contextQueue = _a.contextQueue, actions = _a.actions;
        var context = contextQueue.shift();
        var contextId = (context === null || context === void 0 ? void 0 : context.id) || 0;
        var isFinished = [Dungeon.State.FAILED, Dungeon.State.SUCCESS].includes(this.state.state);
        var updateContext = function () {
            if (context) {
                _this.setState({ context: context, contextQueue: contextQueue });
            }
            else if (isFinished) {
                _this.props.changeMusic();
            }
            _this.animationEndFlag = [];
            _this.setState({ contextId: contextId });
        };
        var command = actions.find(function (v) { return v.type === "command" && v.contextId === contextId; });
        if (command) {
            // 컷인 발생 처리
            var skill = skills.find(function (v) { return v.id === command.data.skillId; });
            if (skill && skill.cutin) {
                this.setState({ cutin: command.data.unitKey });
                this.forceUpdate(function () {
                    setTimeout(function () {
                        _this.setState({ cutin: 0 });
                        updateContext();
                    }, 1000);
                });
                return;
            }
        }
        updateContext();
    };
    BattleRoom.prototype.updatePlayer = function (player) {
        var players = __spreadArray([], __read(this.state.players), false);
        var updated = players.find(function (_a) {
            var id = _a.user.id;
            return player.user.id === id;
        });
        if (updated) {
            players[players.indexOf(updated)] = __assign(__assign({}, updated), player);
            this.setState({ players: players });
        }
    };
    BattleRoom.prototype.render = function () {
        var roomState = this.state.roomState;
        if (roomState === "standby") {
            return this.standby();
        }
        if (roomState === "battle") {
            return this.battle();
        }
    };
    BattleRoom.prototype.standby = function () {
        var _this = this;
        var _a;
        var _b = this.state, stage = _b.stage, players = _b.players, chats = _b.chats, place = _b.place;
        var isLeader = players.some(function (_a) {
            var isLeader = _a.isLeader, user = _a.user;
            return isLeader && user.id === _this.props.state.root.user.id;
        });
        var disableStart = !isLeader || players.some(function (_a) {
            var isReady = _a.isReady;
            return !isReady;
        });
        var isReady = !!((_a = this.getPlayer()) === null || _a === void 0 ? void 0 : _a.isReady);
        if (!stage || !place)
            return _jsx(_Fragment, {});
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "page-title" }, { children: [_jsx("div", { className: "title-icon" }), _jsxs("div", __assign({ className: "title-text" }, { children: ["BATTLE - ", stage.name] }))] })), _jsx(Standby, { skills: this.state.skills, stage: this.state.stage, place: this.state.place, focus: function (v) { return _this.setState({ focusChat: v }); }, disableStart: disableStart, isLeader: isLeader, chats: chats, players: players, onSubmit: this.onSubmitLog, toggleReady: function () {
                        if (isReady) {
                            _this.sendMessage(Message.UNREADY);
                        }
                        else {
                            _this.sendMessage(Message.READY);
                        }
                    }, ready: isReady, start: function () { return _this.sendMessage(Message.START); }, exit: function () { return _this.sendMessage(Message.EXIT_ROOM); } })] }));
    };
    BattleRoom.prototype.battle = function () {
        var _this = this;
        var _a, _b;
        var _c = this.state, stage = _c.stage, skills = _c.skills, state = _c.state, chats = _c.chats, showPopup = _c.showPopup, context = _c.context, selectedSkill = _c.selectedSkill, showBanner = _c.showBanner, targets = _c.targets, effects = _c.effects, buffs = _c.buffs, actions = _c.actions, contextId = _c.contextId, players = _c.players, contextQueue = _c.contextQueue;
        var _d = context, allies = _d.allies, enemies = _d.enemies, round = _d.round;
        var selectable = selectedSkill ? selectedSkill.target_type : "";
        var hpFilter = (selectedSkill === null || selectedSkill === void 0 ? void 0 : selectedSkill.type) === SkillType.RESURRECTION
            ? function (u) { return u.hp === 0; }
            : function (u) { return u.hp > 0; };
        var updatePanel = contextQueue.length === 0 && state === Dungeon.State.READY;
        if (!stage) {
            return _jsx(_Fragment, {});
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "page-title" }, { children: [_jsx("div", { className: "title-icon" }), _jsxs("div", __assign({ className: "title-text" }, { children: ["BATTLE - ", stage.name] }))] })), _jsxs("div", __assign({ className: "battle-room" }, { children: [_jsxs("div", __assign({ className: "background" }, { children: [_jsx("img", { alt: "bg", src: (_a = this.state.place) === null || _a === void 0 ? void 0 : _a.background_image }), _jsx("div", { className: "pattern" })] })), _jsxs("div", __assign({ className: "container" }, { children: [_jsx("div", __assign({ className: "enemies" }, { children: enemies.map(function (v) { return (_jsx(Unit, __assign({ unitKey: v.key, contextId: contextId, type: "enemy", effects: effects, buffs: buffs, selectTarget: _this.selectTarget(v.key), isSelected: targets.includes(v.key), couldSelect: hpFilter(v) && ["all", "enemy"].includes(selectable), onAnimationEnd: _this.onAnimationEnd(v.key), actions: actions }, { children: _jsx(Enemy, { enemyData: v, monsterData: _this.getMonsterData(v.monsterId) }) }), v.key)); }) })), _jsx("div", __assign({ className: "allies" }, { children: allies.map(function (v, i) {
                                        var _a;
                                        var player = _this.getPlayer(v.userId);
                                        var isSelf = (player === null || player === void 0 ? void 0 : player.user.id) === _this.props.user.id;
                                        var couldSelect = hpFilter(v) &&
                                            (["all", "ally"].includes(selectable) ||
                                                (selectable === "self" && isSelf));
                                        if (!player)
                                            return _jsx("div", {}, i);
                                        return (_jsx(Unit, __assign({ unitKey: v.key, contextId: contextId, type: "ally", effects: effects, buffs: buffs, selectTarget: _this.selectTarget(v.key), isSelected: targets.includes(v.key), couldSelect: couldSelect, onAnimationEnd: _this.onAnimationEnd(v.key), actions: actions }, { children: _jsx(Ally, { chats: chats, allyData: v, clickInfo: function (id) {
                                                    _this.setState({ viewInfo: id });
                                                }, useSp: (_a = _this.state.selectedSkill) === null || _a === void 0 ? void 0 : _a.sp, playerData: player }) }), v.key));
                                    }) })), _jsxs("div", __assign({ className: "initiatives" }, { children: [_jsx("div", __assign({ className: "title" }, { children: "INITIATIVE" })), _jsx("div", { children: this.getInitiative().map(function (unit, i) {
                                                var _a;
                                                var data = unit.type === "ally"
                                                    ? (_a = _this.getUnitPlayer(unit.key)) === null || _a === void 0 ? void 0 : _a.character
                                                    : _this.getMonsterData(unit.monsterId);
                                                var nameKey = unit.type === "ally" ? "nickname" : "name";
                                                if (!data)
                                                    return _jsx(_Fragment, {});
                                                return (_jsx("div", __assign({ className: "initiative" }, { children: _jsx("span", { children: data[nameKey] }) }), i));
                                            }) })] })), _jsxs("div", __assign({ className: "wave" }, { children: ["WAVE", " ", _jsxs("span", { children: [context === null || context === void 0 ? void 0 : context.wave, " / ", stage.waves.length] })] })), _jsx("div", __assign({ className: "round ".concat(updatePanel ? "counter" : "") }, { children: _jsx("span", { children: round }) })), _jsx("div", __assign({ className: "menu", onClick: function () { return _this.setState({ showPopup: true }); } }, { children: _jsx(MenuIcon, {}) })), _jsx(Command, { actions: actions, contextId: contextId, data: context, skills: skills, players: players, monsters: stage.waves.flat(), onAnimationEnd: this.onAnimationEnd("command") }), _jsx(CommandList, { roomState: state, selectedSkill: selectedSkill, readyCommand: !!((_b = this.getPlayer()) === null || _b === void 0 ? void 0 : _b.isReady) || this.state.confirmCommand, playerSkills: this.getPlayerSkills(), disableKey: this.state.focusChat, isAnimating: this.state.contextQueue.length > 0, onSelectSkill: this.selectSkill, onSelectWait: this.wait, confirmSkill: this.confirmCommand })] })), _jsx(ChatList, { chats: chats, onSubmit: this.onSubmitLog, focus: function (v) { return _this.setState({ focusChat: v }); } }), this.state.cutin ? (_jsx(Cutin, { data: this.getUnitPlayer(this.state.cutin) })) : (_jsx(_Fragment, {})), _jsx(Banner, { rewards: stage.reward, type: "battle", stageName: stage.name, onAnimationEnd: this.bannerAnimationEnd, isAnimating: this.state.contextQueue.length > 0, onClickExit: function () {
                                _this.sendMessage(Message.EXIT_ROOM);
                                _this.props.onExit(showBanner === "finish" ? "success" : "fail");
                            }, showBanner: showBanner }), _jsx(RoomPopup, __assign({ show: showPopup, setShow: function (show) { return _this.setState({ showPopup: show }); }, height: 170 }, { children: _jsxs(_Fragment, { children: [_jsx("div", __assign({ onClick: function () { return _this.sendMessage(Message.EXIT_ROOM); }, className: "menu-button" }, { children: "\uB098\uAC00\uAE30" })), _jsx("div", __assign({ onClick: this.surrender, className: "menu-button" }, { children: "\uD56D\uBCF5\uD558\uAE30" }))] }) })), _jsx(InfoPopup, { show: !!this.state.viewInfo, playerData: this.getPlayer(this.state.viewInfo), unitData: this.getPlayerUnit(this.state.viewInfo), skills: skills, setShow: function () {
                                _this.setState({ viewInfo: undefined });
                            } })] }))] }));
    };
    BattleRoom.prototype.getInitiative = function () {
        var context = this.state.context;
        if (!context)
            return [];
        var units = __spreadArray(__spreadArray([], __read(context.allies), false), __read(context.enemies), false);
        return units.sort(function (a, b) { return b.initiative - a.initiative; });
    };
    BattleRoom.prototype.getUnitPlayer = function (unitKey) {
        var _a;
        var unit = (_a = this.state.context) === null || _a === void 0 ? void 0 : _a.allies.find(function (u) { return u.key === unitKey; });
        if (!unit)
            return;
        var player = this.getPlayer(unit.userId);
        return player;
    };
    BattleRoom.prototype.getPlayerUnit = function (userId) {
        var _a;
        var user = userId || this.props.user.id;
        var player = this.state.players.find(function (p) { return p.user.id === user; });
        var characterId = player === null || player === void 0 ? void 0 : player.character.id;
        var unit = (_a = this.state.context) === null || _a === void 0 ? void 0 : _a.allies.find(function (u) { return u.characterId === characterId; });
        return unit;
    };
    BattleRoom.prototype.getMonsterData = function (monsterId) {
        var stage = this.state.stage;
        return stage.waves.flat().find(function (_a) {
            var id = _a.id;
            return monsterId === id;
        });
    };
    BattleRoom.prototype.getPlayer = function (userId) {
        if (!userId) {
            userId = this.props.user.id;
        }
        var players = this.state.players;
        return players.find(function (_a) {
            var user = _a.user;
            return userId === user.id;
        });
    };
    BattleRoom.prototype.getPlayerSkills = function () {
        var context = this.state.context;
        var userId = this.props.user.id;
        if (!context)
            return [];
        var unit = context.allies.find(function (u) { return u.userId === userId; });
        if (!unit)
            return [];
        return unit.skills;
    };
    __decorate([
        WatchState("stage")
    ], BattleRoom.prototype, "watchStage", null);
    __decorate([
        WatchState("roomState")
    ], BattleRoom.prototype, "watchRoomState", null);
    return BattleRoom;
}(ReactComponent));
export default BattleRoom;
