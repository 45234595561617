var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./skill.scss";
export default function Skill(_a) {
    var data = _a.data, active = _a.active, onClick = _a.onClick, cooldown_count = _a.cooldown;
    var icon_img = data.icon_img, name = data.name, description = data.description, sp = data.sp, cooldown = data.cooldown, passive = data.passive;
    var optionText = [
        passive ? "패시브" : "",
        sp ? "\uC18C\uBAA8 SP : ".concat(sp) : "",
        cooldown ? "\uCFE8\uD0C0\uC784 : ".concat(cooldown, " \uB77C\uC6B4\uB4DC") : "",
    ]
        .filter(function (v) { return !!v; })
        .join(" | ");
    return (_jsxs("div", __assign({ onClick: onClick, className: "skill-component ".concat(onClick ? "clickable" : "") }, { children: [_jsx("div", __assign({ className: "skill-img" }, { children: _jsxs("div", __assign({ className: "skill-icon ".concat(active ? "active" : "") }, { children: [_jsx("img", { src: icon_img, alt: name }), cooldown_count ? (_jsx("div", __assign({ className: "count" }, { children: cooldown_count }))) : (_jsx(_Fragment, {}))] })) })), _jsxs("div", __assign({ className: "skill-caption" }, { children: [_jsx("div", __assign({ className: "name" }, { children: name })), optionText ? _jsx("div", __assign({ className: "options" }, { children: optionText })) : _jsx(_Fragment, {}), _jsx("div", __assign({ className: "desc" }, { children: description }))] }))] })));
}
