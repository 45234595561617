var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SiteComponent, connect } from "../structure";
import { Link } from "react-router-dom";
import { WatchProp } from "../../structure";
var CharacterList = /** @class */ (function (_super) {
    __extends(CharacterList, _super);
    function CharacterList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            filter: "",
            animation: false,
        };
        _this.selectFilter = function (filter) { return function () {
            if (_this.state.filter === filter) {
                _this.setState({ filter: "" });
            }
            else {
                _this.setState({ filter: filter });
            }
        }; };
        _this.toggleCharacter = function (id) { return function () {
            var _a;
            _this.props.deselect();
            if (id !== ((_a = _this.props.character) === null || _a === void 0 ? void 0 : _a.id)) {
                _this.forceUpdate(function () {
                    _this.props.select(id);
                });
            }
        }; };
        return _this;
    }
    CharacterList.prototype.watchCharacter = function () {
        var _this = this;
        if (!this.props.character)
            return;
        this.setState({ animation: true });
        setTimeout(function () {
            _this.setState({ animation: false });
        }, 500);
    };
    CharacterList.prototype.render = function () {
        var _this = this;
        var filter = this.state.filter;
        var characters = filter
            ? this.props.characters.filter(function (v) { return v.job === filter; })
            : this.props.characters;
        return (_jsx("div", __assign({ className: "character-list" }, { children: _jsxs("div", __assign({ className: "list-container" }, { children: [_jsxs("div", __assign({ className: "detail" }, { children: [_jsx("div", { className: "bg" }), this.props.character ? (_jsxs("div", __assign({ className: "character ".concat(this.state.animation ? "show" : "") }, { children: [this.renderCharacterImage(), _jsx("div", __assign({ className: "character-info" }, { children: this.renderCharacterInfo() }))] }))) : (_jsxs("div", __assign({ className: "ui" }, { children: [_jsxs("div", __assign({ className: "title" }, { children: [_jsx("span", __assign({ className: "big" }, { children: "C" })), "HARACTER"] })), _jsx("div", __assign({ className: "desc" }, { children: "\uCE90\uB9AD\uD130\uB97C \uC120\uD0DD\uD558\uC138\uC694" }))] })))] })), _jsxs("div", __assign({ className: "list" }, { children: [_jsxs("div", __assign({ className: "filter" }, { children: [_jsx("button", { onClick: this.selectFilter("tank"), className: "filter-icon tank ".concat(filter === "tank" ? "selected" : "") }), _jsx("button", { onClick: this.selectFilter("dps"), className: "filter-icon dps ".concat(filter === "dps" ? "selected" : "") }), _jsx("button", { onClick: this.selectFilter("heal"), className: "filter-icon heal ".concat(filter === "heal" ? "selected" : "") }), _jsx("button", { onClick: this.selectFilter("sup"), className: "filter-icon sup ".concat(filter === "sup" ? "selected" : "") })] })), _jsx("div", __assign({ className: "items" }, { children: _jsx("div", __assign({ className: "scroller" }, { children: _jsx("div", __assign({ className: "contents" }, { children: characters.map(function (_a) {
                                            var _b;
                                            var id = _a.id, thumbnail_img = _a.thumbnail_img, outfitData = _a.outfitData;
                                            return (_jsx("div", __assign({ onClick: _this.toggleCharacter(id), className: "item ".concat(((_b = _this.props.character) === null || _b === void 0 ? void 0 : _b.id) === id ? "selected" : "") }, { children: _jsx("img", { src: (outfitData === null || outfitData === void 0 ? void 0 : outfitData.thumbnail_img) || thumbnail_img, alt: "thumbnail" }) }), id));
                                        }) })) })) }))] }))] })) })));
    };
    CharacterList.prototype.renderCharacterImage = function () {
        if (!this.props.character)
            return;
        var _a = this.props.character, body_img = _a.body_img, outfitData = _a.outfitData;
        return (_jsx("div", __assign({ className: "image" }, { children: _jsx("div", __assign({ className: "offset" }, { children: _jsx("img", { style: {
                        left: (outfitData === null || outfitData === void 0 ? void 0 : outfitData.offset_x) || 0,
                        top: (outfitData === null || outfitData === void 0 ? void 0 : outfitData.offset_y) || 0,
                    }, src: (outfitData === null || outfitData === void 0 ? void 0 : outfitData.body_img) || body_img, alt: "portrait" }) })) })));
    };
    CharacterList.prototype.renderCharacterInfo = function () {
        if (!this.props.character)
            return _jsx(_Fragment, {});
        var _a = this.props.character, id = _a.id, title = _a.title, name = _a.name, eng_name = _a.eng_name, job = _a.job;
        return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "title" }, { children: title })), _jsx("div", __assign({ className: "name" }, { children: name })), _jsx("div", __assign({ className: "name-eng" }, { children: eng_name === null || eng_name === void 0 ? void 0 : eng_name.toUpperCase() })), _jsx("div", { className: "emblem ".concat(job) }), _jsx(Link, __assign({ className: "go-profile", to: "".concat(this.props.path || "/character", "/").concat(id) }, { children: _jsx("button", {}) }))] }));
    };
    __decorate([
        WatchProp("character")
    ], CharacterList.prototype, "watchCharacter", null);
    return CharacterList;
}(SiteComponent));
export default connect()(CharacterList);
