import { DBRecord } from "./common";
import { Place, StageReward } from "./explore";
import { Buff, Skill } from "./skill";

export type Status = {
  hp: number;
  max_hp: number;
  sp: number;
  max_sp: number;
  attack: number;
  spell: number;
  defense: number;
  dodge: number;
  initiative: number;
  crit_rate: number;
  crit_dmg: number;
};

export type ActiveStatus = {
  heal_bonus: number;
  dmg_bonus: number;
  pierce: number;
  emmun_dmg: number;
  get_heal_bonus: number;
  get_dmg_bonus: number;
} & Status;

export const baseStatus: (keyof Status)[] = [
  "hp",
  "max_hp",
  "sp",
  "max_sp",
  "attack",
  "spell",
  "defense",
  "dodge",
  "initiative",
  "crit_rate",
  "crit_dmg",
];
export const allStatus: (keyof ActiveStatus)[] = [
  ...baseStatus,
  "heal_bonus",
  "dmg_bonus",
  "pierce",
  "emmun_dmg",
  "get_heal_bonus",
  "get_dmg_bonus",
];

export const statusData: {
  [key in keyof ActiveStatus]: { name: string; desc: string };
} = {
  hp: { name: "HP", desc: "" },
  max_hp: { name: "최대 HP", desc: "" },
  sp: { name: "SP", desc: "" },
  max_sp: { name: "최대 SP", desc: "" },
  attack: { name: "공격력", desc: "" },
  spell: { name: "응용력", desc: "" },
  defense: { name: "방어력", desc: "" },
  dodge: { name: "회피율", desc: "" },
  initiative: { name: "속도", desc: "" },
  crit_rate: { name: "치명률", desc: "" },
  crit_dmg: { name: "치명피해", desc: "" },
  heal_bonus: {
    name: "회복 보너스",
    desc: "",
  },
  dmg_bonus: {
    name: "대미지 보너스",
    desc: "",
  },
  pierce: {
    name: "피어싱",
    desc: "",
  },
  emmun_dmg: {
    name: "피해 저항",
    desc: "",
  },
  get_heal_bonus: {
    name: "받는 회복 보너스",
    desc: "",
  },
  get_dmg_bonus: {
    name: "받는 대미지 보너스",
    desc: "",
  },
};

export type JobData<T = Job> = {
  job: T;
  description: string;
  param_max_hp: number;
  param_max_sp: number;
  param_attack: number;
  param_spell: number;
  param_defense: number;
  param_dodge: number;
  param_initiative: number;
  param_crit_rate: number;
  param_crit_dmg: number;
} & Omit<Status, "hp" | "sp"> &
  DBRecord;

export enum Job {
  DPS = "dps",
  HEALER = "heal",
  SUPPORT = "sup",
  TANK = "tank",
}

export const jobColor = {
  [Job.TANK]: "#a94734",
  [Job.DPS]: "#b88f48",
  [Job.HEALER]: "#6c7cb0",
  [Job.SUPPORT]: "#8c68b6",
};

export const jobs: { [key in Job]: string } = {
  [Job.TANK]: "제림닐",
  [Job.DPS]: "레반틴",
  [Job.HEALER]: "이미르",
  [Job.SUPPORT]: "헤르모드",
};

export type Parameter = {
  health: number;
  strong: number;
  int: number;
  dex: number;
  will: number;
};

export const parameterTooltip = {
  health:
    "생생하게 움직이는 힘. 당신은 인류 최후의 성화를 봉송할 임무를 가진다. 마지막까지 지치지 않고 달릴 두 다리는 준비되었는가.",
  strong:
    "무언가를 향해 타오르는 열정. 그것은 분명 끓는 피의 온도로 드러날 것이다.",
  int: "지식과는 또다른, 당신이 세상을 보는 슬기로움의 정도. 영원한 어둠이 지배하는 대절멸의 세계에 어짊의 호롱을 들라.",
  dex: "기술이나 솜씨의 교묘함. 마음은 대범히, 손은 날래게. 승리를 위해서라면 어떤 수단이라도 쓰고 말겠다는 각오로.",
  will: "포기하지 않는 마음. 유일한 생존자로써 보내는 하루하루에 얼마만큼의 의지가 필요한지, 당신은 아직 모른다.",
};

export const statusFromParams = [
  { param: "health", status: ["max_hp", "defense"] },
  { param: "strong", status: ["attack"] },
  { param: "int", status: ["spell"] },
  { param: "dex", status: ["dodge", "initiative"] },
  { param: "will", status: ["crit_rate", "crit_dmg"] },
];

export const parameters: { [key in keyof Omit<Parameter, "job">]: string } = {
  health: "활력",
  strong: "갈망",
  int: "지혜",
  dex: "기교",
  will: "의지",
};

export enum AICondition {
  NONE = "none",
  HP = "hp", // 자신의 HP가 특정 비율 이하일 때
  ALLY_HP = "allyHp", // 자신을 포함, 최소 한 동료의 HP가 특정 비율 이하일 때
  EVERY_ALLY_HP = "everyHp", // 자신을 포함, 모든 동료의 HP가 특정 비율 이하일 때
  ROUND = "round", // 등장후 특정 라운드 도달시
  EVERY_ROUND = "everyRound", // 매 n번째 라운드마다
}

export const aiConditions: { [key in AICondition]: string } = {
  [AICondition.NONE]: "없음",
  [AICondition.HP]: "자신의 HP가 특정 비율 이하",
  [AICondition.ALLY_HP]: "한 동료의 HP가 특정 비율 이하",
  [AICondition.EVERY_ALLY_HP]: "모든 동료의 HP가 특정 비율 이하",
  [AICondition.ROUND]: "등장후 특정 라운드 도달",
  [AICondition.EVERY_ROUND]: "매 n번째 라운드마다",
};

export type AICommand = {
  rate: number;
  skill_id: number;
  order: number;
  condition?: AICondition;
  condition_value?: number;
};

export type ActiveBuff = Buff & {
  userKey: number;
  stack: number;
  expire_count: number;
  value?: number;
};

export type ActiveSkill = Skill & {
  skill_id: number;
  cooldown: number;
  use_count: number;
};

export type Unit = ActiveStatus & {
  key: number;
  state: "alive" | "dead";
  type: "ally" | "enemy" | "bot";
  buffs: ActiveBuff[];
  skills: ActiveSkill[];
};

export type CommandAction = {
  type: "command";
  contextId: number;
  data: Command;
};

export type EffectAction = {
  type: "effect";
  contextId: number;
  data: Effect;
};

export type Action = CommandAction | EffectAction;

export type Ally = Unit & { userId: number; characterId: number; type: "ally" };
export type AIUnit = Unit & {
  commands: AICommand[];
};
export type Bot = AIUnit & { botId: number; type: "bot" };
export type Enemy = AIUnit & { monsterId: number; type: "enemy" };

export type Context = {
  id?: number;
  allies: Unit[];
  enemies: Unit[];
  round: number;
  wave: number;
};

export type Command = {
  id?: number;
  unitKey: number;
  skillId: number;
  targets: number[];
  option?: {
    massive?: boolean;
    taunt?: number;
    meze?: boolean;
  };
};

export enum EffectType {
  DAMAGE = "damage",
  HEAL = "heal",
  ADD_BUFF = "addBuff",
  REMOVE_BUFF = "removeBuff",
  RESURRECTION = "resurrection",
  GAIN_SP = "gainSP",
}

export type Effect = {
  userKey: number;
  targetKey: number;
  effectType: EffectType;
  value: number | string;
  effectId?: number;
  raw?: boolean;
  option?: {
    ciritical?: boolean;
    dodge?: boolean;
    pierce?: number;
    fromBuff?: boolean;
    protected?: number;
    reflect?: boolean;
    self?: boolean;
  };
};

export type Monster<T = AICommand[]> = {
  id: number;
  name: string;
  image: string;
  commands: T;
} & Status;

export type Character = {
  id: number;
  user_id: number;
  battle_img: string;
  cutscene_img: string;
  body_img: string;
  nickname: string;
  job: string;
  skills: number[];
} & Status;

export type Wave = Monster[];

export type Stage<T = Wave[], U = StageReward[]> = {
  id: number;
  name: string;
  background: string;
  waves: T;
  place_id: number;
  bgm_id: number;
  reward: U;
  description: string;
  open_timestamp: string;
  close_timestamp: string;
  place?: Place;
  primary: number;
} & DBRecord;
