var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import { useState } from "react";
export default function PreviewList(_a) {
    var _b, _c, _d, _e;
    var stages = _a.stages, onClickStart = _a.onClickStart;
    var _f = __read(useState(), 2), selectedId = _f[0], setSelectedId = _f[1];
    var activeId = selectedId || ((_b = stages[0]) === null || _b === void 0 ? void 0 : _b.id) || 0;
    var selected = stages.find(function (v) { return v.id === activeId; });
    var now = moment();
    return (_jsxs("div", __assign({ className: "list-preview" }, { children: [_jsxs("div", __assign({ className: "list-container" }, { children: [_jsx("h4", { children: "\uC2A4\uD14C\uC774\uC9C0 \uBAA9\uB85D" }), _jsx("div", __assign({ className: "list" }, { children: _jsx("div", __assign({ className: "list-scroller" }, { children: _jsx("div", __assign({ className: "scroll-contents" }, { children: stages.map(function (_a, i) {
                                    var name = _a.name, id = _a.id, place = _a.place, close_timestamp = _a.close_timestamp, open_timestamp = _a.open_timestamp;
                                    var date = now.isAfter(open_timestamp) && now.isBefore(close_timestamp)
                                        ? "".concat(moment(close_timestamp).format("YY/MM/DD HH:mm"), "\uAE4C\uC9C0")
                                        : "테스트 플레이 가능";
                                    return (_jsxs("div", __assign({ onClick: function () { return setSelectedId(id); }, className: "item ".concat(id === activeId ? "selected" : "") }, { children: [_jsx("div", __assign({ className: "title" }, { children: name })), _jsx("div", __assign({ className: "date" }, { children: date })), place && (_jsx("img", { className: "bg", src: place.background_image, alt: "\uBC30\uACBD" }))] }), i));
                                }) })) })) }))] })), _jsxs("div", __assign({ className: "preview-container" }, { children: [_jsx("h4", { children: "\uBBF8\uB9AC\uBCF4\uAE30" }), selected ? (_jsx("div", __assign({ className: "preview-outline" }, { children: _jsxs("div", __assign({ className: "preview", style: {
                                backgroundImage: "url('".concat(selected.place ? selected.place.background_image : "", "')"),
                            } }, { children: [_jsx("div", __assign({ className: "title" }, { children: selected.name })), _jsx("div", __assign({ className: "date" }, { children: moment(selected.open_timestamp).format("YY/MM/DD HH:mm") +
                                        " ~ " +
                                        moment(selected.close_timestamp).format("YY/MM/DD HH:mm") })), _jsx("div", __assign({ className: "desc" }, { children: selected.description })), _jsxs("div", __assign({ className: "rewards" }, { children: [_jsxs("div", __assign({ className: "reward-name" }, { children: ["\uD68D\uB4DD\uD560 \uC218 \uC788\uB294 \uBCF4\uC0C1", !((_c = selected.reward) === null || _c === void 0 ? void 0 : _c.length) ? " 없음" : "", ((_d = selected.reward) === null || _d === void 0 ? void 0 : _d.length) && selected.primary
                                                    ? "(".concat(selected.primary, "\uD68C \uC218\uB839 \uAC00\uB2A5)")
                                                    : ""] })), (_e = selected.reward) === null || _e === void 0 ? void 0 : _e.map(function (_a, i) {
                                            var item = _a.item, amount = _a.amount;
                                            if (!item)
                                                return;
                                            return (_jsx("img", { className: "reward", title: item.name + " ".concat(amount, "\uAC1C"), src: item.icon, alt: item.name }, i));
                                        })] })), _jsx("div", __assign({ className: "buttons" }, { children: _jsxs("div", __assign({ className: "button large", onClick: function () { return onClickStart(activeId); } }, { children: ["\uC2DC\uC791\uD558\uAE30", _jsx(ArrowForwardIcon, {})] })) }))] })) }))) : (_jsx("div", __assign({ className: "no-selection" }, { children: "\uBAA9\uB85D\uC5D0\uC11C \uC2A4\uD14C\uC774\uC9C0\uB97C \uC120\uD0DD\uD574\uC8FC\uC138\uC694." })))] }))] })));
}
