import { Battle } from ".";
import { Stage } from "./battle";
import { DBRecord } from "./common";

export type Player = {
  user: { id: number; username: string };
  character: Battle.Character;
  isReady: boolean;
  isLeader: boolean;
  surrender: boolean;
};

export enum LogType {
  CHAT = "chat",
  ACT = "act",
}

export type Log = {
  type: "command" | "effect";
  message: string;
  timestamp: number;
  contextId?: number;
};

export type Chat = {
  characterName: string;
  userId: number;
  message: string;
  timestamp: number;
};

export enum State {
  STANDBY = "standby", // 대기상태
  READY = "ready", // 커맨드 대기
  BATTLE = "battle", // 전투 진행
  SUCCESS = "success", // 전투 클리어
  FAILED = "failed", // 전투 패배
}

export type Info = {
  players: Player[];
  stage: Stage;
  roomId: number;
  state: State;
  chats: Chat[];
  enableJoin: boolean;
  test: boolean;
  type: "explore" | "party";
};

export type Instance<T = number[], U = Chat[]> = {
  user_id: number;
  name: string;
  players: T;
  player_name: string;
  is_test: 0 | 1;
  state: "start" | "success" | "fail";
  stage_id: number;
  start_timestamp: string;
  end_timestamp: string;
  chats: U;
  type: "explore" | "party";
  hp: number;
  sp: number;
} & DBRecord;

export enum Message {
  NO_ROOM = "noRoom",
  ERROR = "error",
  ENTER_ROOM = "enterRoom",
  JOIN_ROOM = "joinRoom",
  EXIT_ROOM = "exitRoom",
  READY = "ready",
  UNREADY = "unready",
  LOG = "log",
  CHAT = "chat",
  UPDATE_CONTEXT = "updateContext",
  READY_COMMAND = "readyCommand",
  STANDBY_COMMAND = "standbyCommand",
  COMMAND = "command",
  SURRENDER = "surrender",
  FINISH = "finish",
  FAILED = "failed",
  START = "start",
}
