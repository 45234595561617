var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { connect } from "../structure";
import axios from "axios";
import JoinWindow from "../window/JoinWindow";
import { useDispatch } from "react-redux";
import { toast } from "../../structure";
import "./login.scss";
import { login, setCharacters } from "../store/actions";
import ButtonSpinner from "../../components/ButtonSpinner";
import { Level } from "@iter-acillba/core";
function Login(props) {
    var _this = this;
    var dispatch = useDispatch();
    var _a = __read(useState(""), 2), email = _a[0], setEmail = _a[1];
    var _b = __read(useState(""), 2), password = _b[0], setPassword = _b[1];
    var _c = __read(useState(0), 2), persist = _c[0], setPersist = _c[1];
    var _d = __read(useState(false), 2), loginSuccess = _d[0], setLoginSuccess = _d[1];
    var _e = __read(useState(false), 2), showJoin = _e[0], setShowJoin = _e[1];
    var _f = __read(useState(""), 2), certEmail = _f[0], setCertEmail = _f[1];
    var _g = __read(useState(false), 2), runProgress = _g[0], setRunProgress = _g[1];
    var _h = __read(useState(0), 2), waitingSecond = _h[0], setWaitingSecond = _h[1];
    var _j = __read(useState(false), 2), resetPass = _j[0], setResetPass = _j[1];
    var timeoutCallback = useCallback(function () {
        setWaitingSecond(waitingSecond - 1);
    }, [waitingSecond]);
    var onSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var _a, success, data, characters;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    return [4 /*yield*/, axios({
                            url: "/api/user/loginAsync",
                            method: "post",
                            data: { email: email, password: password, persist: persist },
                        })];
                case 1:
                    _a = (_b.sent()).data, success = _a.success, data = _a.data;
                    if (!success) {
                        dispatch(toast({ text: "로그인 정보가 일치하지 않습니다." }));
                        return [2 /*return*/];
                    }
                    if (data.cert) {
                        setCertEmail(email);
                        return [2 /*return*/];
                    }
                    if (props.needLogin && (!data || data.level < Level.REGULAR)) {
                        dispatch(toast({ text: "정회원만 접근 가능합니다." }));
                        return [2 /*return*/];
                    }
                    setLoginSuccess(true);
                    dispatch(login(data));
                    return [4 /*yield*/, axios({
                            url: "/api/character?category_default=1",
                            method: "get",
                        })];
                case 2:
                    characters = (_b.sent()).data.data.data;
                    dispatch(setCharacters(characters));
                    props.history.push("/");
                    return [2 /*return*/];
            }
        });
    }); };
    var sendEmail = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, success, notice;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (waitingSecond > 0)
                        return [2 /*return*/];
                    setRunProgress(true);
                    return [4 /*yield*/, axios({ url: "/api/user/certEmail/".concat(certEmail) })];
                case 1:
                    _a = (_b.sent()).data, success = _a.success, notice = _a.notice;
                    setRunProgress(false);
                    if (!success) {
                        dispatch(toast(notice));
                    }
                    else {
                        setWaitingSecond(30);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var sendPass = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, success, notice;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (waitingSecond > 0)
                        return [2 /*return*/];
                    setRunProgress(true);
                    return [4 /*yield*/, axios({
                            url: "/api/user/resetPass/",
                            data: { email: email },
                            method: "post",
                        })];
                case 1:
                    _a = (_b.sent()).data, success = _a.success, notice = _a.notice;
                    setRunProgress(false);
                    if (!success) {
                        dispatch(toast(notice));
                    }
                    else {
                        setWaitingSecond(30);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (waitingSecond < 1)
            return;
        var timeout = setTimeout(timeoutCallback, 1000);
        return function () {
            clearTimeout(timeout);
        };
    }, [waitingSecond]);
    if (resetPass) {
        return (_jsx("div", __assign({ className: "login-container" }, { children: _jsxs("div", __assign({ className: "cert-box pass" }, { children: [_jsxs("div", __assign({ className: "text" }, { children: ["\uAC00\uC785\uC2DC \uC785\uB825\uD55C \uC774\uBA54\uC77C\uB85C \uC0C8\uB85C\uC6B4 \uBE44\uBC00\uBC88\uD638\uB97C \uC804\uC1A1\uD569\uB2C8\uB2E4. ", _jsx("br", {}), "\uBA54\uC77C\uC744 \uD655\uC778\uD560 \uC218 \uC5C6\uB294 \uACBD\uC6B0 \uBC84\uD2BC\uC744 \uB2E4\uC2DC \uB20C\uB7EC \uC7AC\uC804\uC1A1\uD558\uC138\uC694."] })), _jsx("input", { placeholder: "e-mail", type: "email", name: "email", autoComplete: "on", value: email, onChange: function (e) { return setEmail(e.target.value); }, className: "text-input", maxLength: 120 }), _jsx("br", {}), _jsx("button", __assign({ type: "button", className: "submit", onClick: sendPass }, { children: runProgress ? (_jsx(ButtonSpinner, {})) : waitingSecond > 0 ? ("\uC804\uC1A1\uB418\uC5C8\uC2B5\uB2C8\uB2E4. ".concat(waitingSecond, "s")) : ("비밀번호 재설정") })), _jsx("button", __assign({ type: "button", onClick: function () { return setResetPass(false); } }, { children: "\uB85C\uADF8\uC778 \uD654\uBA74\uC73C\uB85C" }))] })) })));
    }
    if (certEmail) {
        return (_jsx("div", __assign({ className: "login-container" }, { children: _jsxs("div", __assign({ className: "cert-box" }, { children: [_jsxs("div", __assign({ className: "text" }, { children: ["\uAC00\uC785 \uC2DC \uC785\uB825\uD55C \uC774\uBA54\uC77C ", _jsx("strong", { children: certEmail }), "\uB85C \uC778\uC99D \uB9C1\uD06C\uAC00 \uBC1C\uC1A1\uB418\uC5C8\uC2B5\uB2C8\uB2E4. ", _jsx("br", {}), "\uBA54\uC77C\uC744 \uD655\uC778\uD560 \uC218 \uC5C6\uB294 \uACBD\uC6B0 \uC544\uB798\uC758 \uBC84\uD2BC\uC744 \uB20C\uB7EC \uC7AC\uC804\uC1A1\uD558\uC138\uC694."] })), _jsx("button", __assign({ type: "button", onClick: sendEmail }, { children: runProgress ? (_jsx(ButtonSpinner, {})) : waitingSecond > 0 ? ("\uC804\uC1A1\uB418\uC5C8\uC2B5\uB2C8\uB2E4. ".concat(waitingSecond, "s")) : ("확인메일 재전송") })), _jsx("br", {}), _jsx("button", __assign({ type: "button", onClick: function () { return setCertEmail(""); } }, { children: "\uB85C\uADF8\uC778 \uD654\uBA74\uC73C\uB85C" }))] })) })));
    }
    return (_jsxs("div", __assign({ className: "login-container" }, { children: [_jsx("form", __assign({ onSubmit: onSubmit, method: "post" }, { children: _jsxs("div", __assign({ className: "login-form ".concat(loginSuccess ? "fadeout" : "") }, { children: [_jsxs("div", __assign({ className: "d-flex mb-2" }, { children: [_jsxs("div", { children: [_jsx("div", __assign({ className: "mb-2" }, { children: _jsx("input", { placeholder: "e-mail", type: "email", name: "email", autoComplete: "on", value: email, onChange: function (e) { return setEmail(e.target.value); }, className: "text-input", maxLength: 120 }) })), _jsx("div", { children: _jsx("input", { placeholder: "password", name: "password", value: password, onChange: function (e) { return setPassword(e.target.value); }, className: "text-input", type: "password", minLength: 4, maxLength: 20 }) })] }), _jsx("div", __assign({ className: "right" }, { children: _jsx("button", __assign({ type: "submit", className: "login-btn" }, { children: "LOGIN" })) }))] })), _jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx("div", { children: _jsxs("label", { children: [_jsx("input", { name: "persist", onChange: function (e) {
                                                    setPersist(e.target.checked ? 1 : 0);
                                                }, value: persist, type: "checkbox" }), " ", "\uB85C\uADF8\uC778 \uC720\uC9C0"] }) }), _jsxs("div", { children: [_jsx("a", __assign({ href: "#", onClick: function (e) {
                                                e.preventDefault();
                                                setShowJoin(true);
                                            } }, { children: "\uD68C\uC6D0\uAC00\uC785" })), " · ", " ", _jsx("a", __assign({ href: "#", onClick: function () { return setResetPass(true); } }, { children: "\uBE44\uBC00\uBC88\uD638 \uBD84\uC2E4" }))] })] }))] })) })), _jsx(JoinWindow, { show: showJoin, hide: function () { return setShowJoin(false); }, success: function (email) { return setCertEmail(email); } })] })));
}
export default connect()(Login);
