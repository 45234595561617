var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./spinner.scss";
var MIN_PLAYTIME = 1;
var FADEOUT_TIME = 300;
export function Spinner(_a) {
    var children = _a.children, loading = _a.loading, _b = _a.progress, progress = _b === void 0 ? 0 : _b, _c = _a.spinnerType, spinnerType = _c === void 0 ? "eclipse" : _c;
    var _d = __read(useState(true), 2), minPlay = _d[0], setMinPlay = _d[1];
    var _e = __read(useState(true), 2), showLoading = _e[0], setShowLoading = _e[1];
    var fadeout = !loading && minPlay;
    useEffect(function () {
        if (loading) {
            setShowLoading(true);
        }
    }, [loading]);
    useEffect(function () {
        if (fadeout) {
            setTimeout(function () {
                setShowLoading(false);
            }, FADEOUT_TIME);
        }
    }, [fadeout]);
    if (showLoading) {
        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "spinner-component ".concat(fadeout ? "fadeout" : "") }, { children: [_jsx("img", { className: "spinner", src: "/static/img/spinner_transparent.png", alt: "spinner" }), _jsx("div", __assign({ className: "progress-bar" }, { children: _jsx("div", { className: "bar", style: { width: Math.round(200 * progress) + "px" } }) }))] })) }));
    }
    return _jsx("div", __assign({ className: "spinner-contents" }, { children: children }));
}
