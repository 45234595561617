var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { connect } from "../structure";
import { useEffect, useRef, useState } from "react";
function Toast() {
    var toastData = useSelector(function (state) { return state.toast.data; });
    var _a = __read(useState([]), 2), toasts = _a[0], setToasts = _a[1];
    var updateStateCallback = useRef(function (key, state) { });
    var isHide = toasts.every(function (v) { return v.state === "removed"; });
    function updateState(key, state) {
        var queue = __spreadArray([], __read(toasts), false);
        var target = queue.find(function (v) { return v.printTime === key; });
        var idx = queue.indexOf(target);
        queue[idx] = __assign(__assign({}, target), { state: state });
        setToasts(queue);
    }
    useEffect(function () {
        updateStateCallback.current = updateState;
    });
    useEffect(function () {
        if (!toastData.text)
            return;
        var queue = isHide ? toasts.filter(function (v) { return v.state !== "removed"; }) : toasts;
        var key = Date.now();
        setToasts(__spreadArray(__spreadArray([], __read(queue), false), [{
                toast: toastData,
                printTime: key,
                state: "show",
            }], false));
        setTimeout(function () {
            updateStateCallback.current(key, "");
        }, 300);
        setTimeout(function () {
            updateStateCallback.current(key, "hide");
        }, 3000);
        setTimeout(function () {
            updateStateCallback.current(key, "removed");
        }, 3300);
    }, [toastData]);
    return isHide ? _jsx(_Fragment, {}) : _jsx("div", __assign({ className: "site-toast-container" }, { children: toasts.map(function (_a) {
            var toast = _a.toast, printTime = _a.printTime, state = _a.state;
            return _jsxs("div", __assign({ className: "site-toast ".concat(state) }, { children: [_jsx("div", { className: "box" }), _jsx("div", __assign({ className: "text" }, { children: toast === null || toast === void 0 ? void 0 : toast.text }))] }), printTime);
        }) }));
}
export default connect()(Toast);
