var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WatchProp, WatchState } from "../../structure";
import { SiteComponent, connect } from "../structure";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import MailIcon from "@mui/icons-material/Mail";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { showMessage } from "../store/actions";
import SearchSelect from "../../components/SearchSelect";
import DraftsIcon from "@mui/icons-material/Drafts";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
var SettingWindow = /** @class */ (function (_super) {
    __extends(SettingWindow, _super);
    function SettingWindow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            messages: [],
            show: "list",
            message: {},
            viewId: 0,
            inventory: [],
        };
        _this.increase = function () {
            _this.setState({
                message: __assign(__assign({}, _this.state.message), { amount: Math.min(99, (_this.state.message.amount || 0) + 1) }),
            });
        };
        _this.decrease = function () {
            _this.setState({
                message: __assign(__assign({}, _this.state.message), { amount: Math.max(0, (_this.state.message.amount || 0) - 1) }),
            });
        };
        return _this;
    }
    SettingWindow.prototype.initData = function (show) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!show)
                    return [2 /*return*/];
                this.setState({ show: "list", viewId: 0 });
                this.updateData();
                return [2 /*return*/];
            });
        });
    };
    SettingWindow.prototype.callInventoryData = function (show) {
        return __awaiter(this, void 0, void 0, function () {
            var inventory;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (show !== "form") {
                            this.updateData();
                            return [2 /*return*/];
                        }
                        if (!this.isMyBox())
                            return [2 /*return*/];
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/inventory/" + this.props.state.root.character.id,
                                method: "get",
                            })];
                    case 1:
                        inventory = (_a.sent()).items;
                        this.setState({ inventory: inventory });
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingWindow.prototype.updateData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var id, messages;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = this.props.state.root.showMessage;
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/message/" + id,
                                method: "get",
                            })];
                    case 1:
                        messages = _a.sent();
                        this.setState({ messages: messages });
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingWindow.prototype.render = function () {
        if (!this.props.state.root.showMessage)
            return _jsx(_Fragment, {});
        var character = this.getCharacter();
        return (_jsx("div", __assign({ className: "popup-container message" }, { children: _jsxs("div", __assign({ className: "popup window", style: { height: 600 } }, { children: [_jsxs("h4", { children: [character === null || character === void 0 ? void 0 : character.nickname, "\uC758 \uC6B0\uD3B8\uD568", _jsx("small", { children: this.menuTitle() })] }), this.contents(), this.menus()] })) })));
    };
    SettingWindow.prototype.menuTitle = function () {
        switch (this.state.show) {
            case "form":
                return "새 편지";
            case "list":
                return "받은 편지함";
            case "send":
                return "보낸 편지함";
        }
    };
    SettingWindow.prototype.menus = function () {
        var _this = this;
        return (_jsxs("div", __assign({ className: "menus" }, { children: [_jsx("div", __assign({ role: "button", onClick: function () { return _this.setState({ show: "list", viewId: 0 }); } }, { children: _jsxs("div", __assign({ className: "move" }, { children: [_jsx("div", __assign({ className: "text" }, { children: "\uBC1B\uC740 \uD3B8\uC9C0\uD568" })), _jsx(MailOutlineIcon, {})] })) })), _jsx("div", __assign({ role: "button", onClick: function () { return _this.setState({ show: "send", viewId: 0 }); } }, { children: _jsxs("div", __assign({ className: "move" }, { children: [_jsx("div", __assign({ className: "text" }, { children: "\uBCF4\uB0B8 \uD3B8\uC9C0\uD568" })), _jsx(ForwardToInboxIcon, {})] })) })), this.isMyBox() ? (_jsx("div", __assign({ role: "button", onClick: function () { return _this.setState({ show: "form" }); } }, { children: _jsxs("div", __assign({ className: "move" }, { children: [_jsx("div", __assign({ className: "text" }, { children: "\uC0C8 \uD3B8\uC9C0" })), _jsx(DriveFileRenameOutlineIcon, {})] })) }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ role: "button", onClick: function () { return _this.dispatch(showMessage(0)); } }, { children: _jsxs("div", __assign({ className: "move" }, { children: [_jsx("div", __assign({ className: "text" }, { children: "\uB2EB\uAE30" })), _jsx(CloseIcon, {})] })) }))] })));
    };
    SettingWindow.prototype.getCharacter = function () {
        var _a = this.props.state.root, characters = _a.characters, showMessage = _a.showMessage;
        return characters.find(function (c) { return c.id === showMessage; });
    };
    SettingWindow.prototype.getCharacterById = function (id) {
        return this.props.state.root.characters.find(function (c) { return c.id === id; });
    };
    SettingWindow.prototype.contents = function () {
        var el;
        switch (this.state.show) {
            case "form":
                el = this.form();
                break;
            case "list":
                if (this.state.viewId) {
                    el = this.view();
                }
                else {
                    el = this.list();
                }
                break;
            case "send":
                if (this.state.viewId) {
                    el = this.view(true);
                }
                else {
                    el = this.list(true);
                }
                break;
        }
        return _jsx("div", __assign({ className: "message-contents" }, { children: el }));
    };
    SettingWindow.prototype.list = function (send) {
        var _this = this;
        if (send === void 0) { send = false; }
        var list = [];
        var characterId = this.props.state.root.showMessage;
        if (send) {
            list = this.state.messages.filter(function (m) { return m.from_id === characterId; });
        }
        else {
            list = this.state.messages.filter(function (m) { return m.to_id === characterId; });
        }
        return (_jsxs(_Fragment, { children: [_jsx("small", { children: "\uCD5C\uADFC \uBA54\uC138\uC9C0\uBD80\uD130 \uD45C\uC2DC\uB429\uB2C8\uB2E4." }), _jsx("div", __assign({ className: "message-list" }, { children: _jsx("div", __assign({ className: "scroller" }, { children: _jsx("div", __assign({ className: "scroll-contents" }, { children: list.map(function (_a, i) {
                                var _b;
                                var title = _a.title, read = _a.read, from_name = _a.from_name, id = _a.id, created_timestamp = _a.created_timestamp, item_id = _a.item_id, obtain = _a.obtain, to_id = _a.to_id;
                                return (_jsxs("div", __assign({ onClick: function () {
                                        _this.setState({ viewId: id });
                                        send || _this.readMessage(id);
                                    }, className: "message ".concat(read ? "read" : ""), role: "button" }, { children: [_jsxs("div", __assign({ className: "title" }, { children: [read ? _jsx(DraftsIcon, {}) : _jsx(MailIcon, {}), _jsxs("div", { children: [title, " ", send ? (_jsxs("span", __assign({ className: "from" }, { children: ["to.", (_b = _this.getCharacterById(to_id)) === null || _b === void 0 ? void 0 : _b.nickname] }))) : (_jsxs("span", __assign({ className: "from" }, { children: ["from.", from_name] }))), " ", item_id ? _jsx(AttachFileIcon, {}) : _jsx(_Fragment, {})] })] })), _jsx("div", __assign({ className: "date" }, { children: moment(created_timestamp).format("MM/DD") }))] }), i));
                            }) })) })) }))] }));
    };
    SettingWindow.prototype.view = function (send) {
        var _this = this;
        var _a;
        if (send === void 0) { send = false; }
        var message = this.state.messages.find(function (m) { return m.id === _this.state.viewId; });
        if (!message)
            return;
        var id = message.id, title = message.title, from_name = message.from_name, created_timestamp = message.created_timestamp, read = message.read, item = message.item, amount = message.amount, contents = message.contents, obtain = message.obtain, to_id = message.to_id;
        return (_jsxs("div", __assign({ className: "message-view" }, { children: [_jsx("div", __assign({ className: "scroll-container" }, { children: _jsx("div", __assign({ className: "scroller" }, { children: _jsxs("div", __assign({ className: "scroll-contents" }, { children: [_jsxs("div", __assign({ className: "title" }, { children: [read ? _jsx(DraftsIcon, {}) : _jsx(MailIcon, {}), _jsx("div", { children: title })] })), _jsxs("div", __assign({ className: "info" }, { children: [send ? (_jsxs("div", __assign({ className: "name" }, { children: ["to.", (_a = this.getCharacterById(to_id)) === null || _a === void 0 ? void 0 : _a.nickname] }))) : (_jsxs("div", __assign({ className: "name" }, { children: ["from.", from_name] }))), _jsx("div", __assign({ className: "date" }, { children: moment(created_timestamp).format("YYYY/MM/DD HH:mm") }))] })), _jsx("div", __assign({ className: "contents" }, { children: contents })), item ? (_jsxs("div", __assign({ className: "items" }, { children: [_jsx("strong", { children: "\uCCA8\uBD80 \uC544\uC774\uD15C" }), _jsxs("div", __assign({ className: "item" }, { children: [_jsxs("div", { children: [_jsx("img", { src: item.icon }), item.name, " x ", amount] }), this.isMyBox() && !send ? (obtain ? (_jsx(_Fragment, { children: "\uC218\uB839\uC644\uB8CC" })) : (_jsx("button", __assign({ type: "button", role: "button", onClick: function () { return _this.obtain(id); } }, { children: "\uC218\uB839\uD558\uAE30" })))) : (_jsx(_Fragment, {}))] }))] }))) : (_jsx(_Fragment, {}))] })) })) })), _jsx("div", __assign({ className: "form-buttons" }, { children: _jsx("button", __assign({ type: "button", onClick: function () { return _this.setState({ viewId: 0 }); } }, { children: "\uBAA9\uB85D\uC73C\uB85C" })) }))] })));
    };
    SettingWindow.prototype.form = function () {
        var _this = this;
        var _a = this.state.message, title = _a.title, contents = _a.contents, amount = _a.amount, item_id = _a.item_id;
        return (_jsxs("form", __assign({ className: "join-form", onSubmit: function (e) {
                e.preventDefault();
                _this.submitForm();
            } }, { children: [_jsxs("div", __assign({ className: "form-item" }, { children: [_jsx("label", __assign({ htmlFor: "to_id" }, { children: "\uC218\uC2E0\uC778" })), _jsx(SearchSelect, { value: title, required: true, id: "to_id", name: "to_id", api: "character", placeholder: "", disallowCreate: true, onSelect: function (id) {
                                _this.setState({ message: __assign(__assign({}, _this.state.message), { to_id: id }) });
                            }, params: { category_default: 1 } })] })), _jsxs("div", __assign({ className: "form-item" }, { children: [_jsx("label", __assign({ htmlFor: "title" }, { children: "\uC81C\uBAA9" })), _jsx("input", { type: "text", required: true, minLength: 1, maxLength: 50, value: title, id: "title", name: "title", onChange: this.inputState("message"), placeholder: "\uCD5C\uB300 50\uC790" })] })), _jsxs("div", __assign({ className: "form-item" }, { children: [_jsx("label", __assign({ htmlFor: "contents" }, { children: "\uB0B4\uC6A9" })), _jsx("textarea", { required: true, rows: 7, value: contents, id: "contents", name: "contents", onChange: this.inputState("message"), placeholder: "\uC6B0\uD3B8\uC758 \uB0B4\uC6A9\uC740 \uBAA8\uB4E0 \uBA64\uBC84\uAC00 \uD655\uC778 \uAC00\uB2A5\uD569\uB2C8\uB2E4" })] })), _jsxs("div", __assign({ className: "form-item" }, { children: [_jsx("label", __assign({ htmlFor: "item_id" }, { children: "\uCCA8\uBD80 \uC544\uC774\uD15C(\uC635\uC158)" })), _jsxs("select", __assign({ name: "item_id", id: "item_id", value: item_id, onChange: this.inputState("message") }, { children: [_jsx("option", __assign({ value: "" }, { children: "\uC120\uD0DD" })), this.state.inventory.map(function (_a, i) {
                                    var name = _a.name, icon = _a.icon, item_id = _a.item_id;
                                    return (_jsxs("option", __assign({ value: item_id }, { children: [_jsx("img", { src: icon, alt: name }), " ", name] }), i));
                                })] }))] })), _jsxs("div", __assign({ className: "form-item" }, { children: [_jsx("label", __assign({ htmlFor: "amount" }, { children: "\uC544\uC774\uD15C \uC218\uB7C9(\uC635\uC158)" })), _jsxs("div", __assign({ className: "counter" }, { children: [_jsx(KeyboardArrowLeftIcon, { role: "button", onClick: this.decrease }), _jsx("input", { id: "amount", type: "number", className: "amount", value: amount || 0, onChange: this.inputState("message"), name: "amount" }), _jsx(KeyboardArrowRightIcon, { role: "button", onClick: this.increase })] }))] })), _jsx("div", __assign({ className: "form-buttons" }, { children: _jsx("button", __assign({ type: "submit" }, { children: "\uC804\uC1A1\uD558\uAE30" })) }))] })));
    };
    SettingWindow.prototype.obtain = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isMyBox())
                            return [2 /*return*/];
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/message/obtain/" + id,
                                method: "post",
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingWindow.prototype.isMyBox = function () {
        var _a = this.props.state.root, showMessage = _a.showMessage, character = _a.character;
        return showMessage === character.id;
    };
    SettingWindow.prototype.readMessage = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isMyBox())
                            return [2 /*return*/];
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/message/" + id,
                                method: "post",
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingWindow.prototype.submitForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/message",
                            method: "post",
                            data: this.state.message,
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateData()];
                    case 2:
                        _a.sent();
                        this.setState({
                            message: {},
                            show: "send",
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        WatchProp("state.root.showMessage")
    ], SettingWindow.prototype, "initData", null);
    __decorate([
        WatchState("show")
    ], SettingWindow.prototype, "callInventoryData", null);
    return SettingWindow;
}(SiteComponent));
export default connect()(SettingWindow);
