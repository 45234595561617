var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { shopItemLimitRules, } from "@iter-acillba/core";
import { SiteComponent, connect } from "../structure";
import Title from "../components/Title";
import ItemIcon from "../../components/Item/ItemIcon";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { groupBy } from "lodash";
import LetterAnimation from "../../components/EventScript/LetterAnimation";
var ENTER_COMMENT = [
    "어서 와요...(나른한 눈이 당신을 천천히 훑는다.)",
    "음... 좋은 때 왔네... (물건이 쌓인 곳으로 고개를 까딱한다. 아마 새 재고들이 들어온 듯 싶다.)",
    "...아, 올 때라고 생각했어... (팔짱을 끼고 당신을 바라본다. 입에 살짝 미소가 걸려있다.)",
];
var BUY_COMMENT = [
    "응, 좋은 걸 골랐네... 자, 여기...물건. (응...? 메이사와 손끝이 스쳤다...)",
    "감사합니다... ... (나른한 눈이 좁아지며 반달 모양으로 휜다.)",
    "... ...이제, 용무가 끝났으니 갈 거지? (음...? 어쩐지 아쉬운 기색인데...)",
];
var STOCK_COMMENT = [
    "지금은 재고가 없는데... ...의외로 물건에 욕심을 내는 편?",
    "...남은 물건이 없어. 원한다면 구해볼테니... 저쪽에 원하는 걸 메모해 둬.",
    "음... 미안. 다 팔리고 없는 것 같아...",
];
var MONEY_COMMENT = [
    "돈이 부족한데... 다른 걸 대가로 줄 생각일까? (턱에 손가락을 댄다.)",
    "... ...돈이 부족해... 미안. 일일 잡일꾼이라도 되어주면 물건 한두개쯤 못 줄것도 아니지만... (당신을 위아래로 살펴본다.)",
    "으음... 미안... 우리도 땅을 파서 장사하는 건 아니라... 돈이 있어야 물건을 줄 수 있어... ",
];
var ShopList = /** @class */ (function (_super) {
    __extends(ShopList, _super);
    function ShopList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            items: [],
            character: undefined,
            selected: 0,
            comment: "",
            category: "",
            amount: 1,
        };
        _this.buy = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, selected, amount, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.state, selected = _a.selected, amount = _a.amount;
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/shop/buy/".concat(selected, "?amount=").concat(amount),
                                method: "post",
                            })];
                    case 1:
                        result = _b.sent();
                        if (!(result === "buy")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.callData()];
                    case 2:
                        _b.sent();
                        this.setState({
                            amount: 1,
                        });
                        _b.label = 3;
                    case 3:
                        this.comment(result);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.select = function (id) { return function () {
            var selected = _this.state.items.find(function (r) { return r.id == id; });
            _this.setState({
                selected: _this.state.selected === id ? 0 : id,
                amount: 1,
            });
            if (selected === null || selected === void 0 ? void 0 : selected.comment) {
                _this.setState({
                    comment: selected.comment,
                });
            }
        }; };
        _this.selectCategory = function (key) { return function () {
            _this.setState({ category: key });
        }; };
        _this.increase = function () {
            var selected = _this.state.items.find(function (r) { return r.id == _this.state.selected; });
            if (!selected)
                return;
            var amount = _this.state.amount;
            var max = (selected.limit_rule && selected.stock) || 99;
            _this.setState({ amount: Math.min(amount + 1, max) });
        };
        _this.decrease = function () {
            var amount = _this.state.amount;
            _this.setState({ amount: Math.max(amount - 1, 1) });
        };
        _this.changeAmount = function (value) {
            var selected = _this.state.items.find(function (r) { return r.id == _this.state.selected; });
            if (!selected)
                return;
            var max = (selected.limit_rule && selected.stock) || 99;
            _this.setState({ amount: Math.min(+value, max) });
        };
        return _this;
    }
    ShopList.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callData()];
                    case 1:
                        _a.sent();
                        this.comment("enter");
                        return [2 /*return*/];
                }
            });
        });
    };
    ShopList.prototype.callData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var items, character, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        items = this.callAPI({
                            url: "/api/shop/list",
                            method: "get",
                        });
                        character = this.callAPI({
                            url: "/api/character/rep/" + this.props.state.root.user.id,
                            method: "get",
                        });
                        _a = this.setState;
                        _b = {};
                        return [4 /*yield*/, items];
                    case 1:
                        _b.items = _c.sent();
                        return [4 /*yield*/, character];
                    case 2:
                        _a.apply(this, [(_b.character = _c.sent(), _b)]);
                        return [2 /*return*/];
                }
            });
        });
    };
    ShopList.prototype.render = function () {
        var _this = this;
        var _a;
        var selected = this.state.items.find(function (r) { return r.id == _this.state.selected; });
        return (_jsxs(_Fragment, { children: [_jsx(Title, { text: "SHOP", links: [
                        { text: "CRAFT", link: "/shop/craft" },
                        { text: "SHOP", link: "/shop/shop" },
                    ] }), _jsx("div", __assign({ className: "shop-bg" }, { children: _jsxs("div", __assign({ className: "shop-container" }, { children: [_jsxs("div", __assign({ className: "contents" }, { children: [_jsxs("div", __assign({ className: "contents-left" }, { children: [_jsxs("div", __assign({ className: "categories" }, { children: [_jsx("div", __assign({ className: "category ".concat(!this.state.category ? "selected" : ""), role: "button", onClick: this.selectCategory("") }, { children: "\uC804\uCCB4" })), Object.keys(groupBy(this.state.items, "category")).map(function (key, i) {
                                                        return (_jsx("div", __assign({ className: "category ".concat(_this.state.category === key ? "selected" : ""), role: "button", onClick: _this.selectCategory(key) }, { children: key }), i));
                                                    })] })), _jsx("div", __assign({ className: "list" }, { children: _jsx("div", __assign({ className: "scroller" }, { children: _jsx("div", __assign({ className: "scroll-contents" }, { children: this.state.items
                                                            .filter(function (_a) {
                                                            var category = _a.category;
                                                            if (!_this.state.category)
                                                                return true;
                                                            return _this.state.category === category;
                                                        })
                                                            .map(function (_a, i) {
                                                            var limit = _a.limit, limit_rule = _a.limit_rule, discount = _a.discount, item = __rest(_a, ["limit", "limit_rule", "discount"]);
                                                            return (_jsxs("div", __assign({ className: "item ".concat((selected === null || selected === void 0 ? void 0 : selected.id) === item.id ? "selected" : ""), role: "button", onClick: _this.select(item.id) }, { children: [_jsx(ItemIcon, { item: item }), limit_rule ? (_jsxs("div", __assign({ className: "limit" }, { children: [shopItemLimitRules[limit_rule], " ", limit, "\uAC1C"] }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "price" }, { children: [discount ? "할인가 " : "", Math.ceil((item.buy * (100 - discount)) / 100), "G"] }))] }), item.id));
                                                        }) })) })) })), _jsxs("div", __assign({ className: "npc-message" }, { children: [_jsx("img", { src: "/static/img/shop_message.png" }), _jsx("div", __assign({ className: "text" }, { children: this.state.comment ? (_jsx(LetterAnimation, { muted: true, text: this.state.comment, onFinish: function () { }, skip: false })) : (_jsx(_Fragment, {})) }))] }))] })), _jsxs("div", __assign({ className: "contents-right" }, { children: [_jsxs("div", __assign({ className: "money" }, { children: ["\uC18C\uC9C0\uAE08: ", _jsx("strong", { children: (_a = this.state.character) === null || _a === void 0 ? void 0 : _a.money }), "G"] })), _jsx("div", __assign({ className: "tool" }, { children: selected ? (_jsxs(_Fragment, { children: [selected.limit_rule ? (_jsxs("div", __assign({ className: "stock" }, { children: ["\uC7AC\uACE0: ", selected.stock, "\uAC1C"] }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "counter" }, { children: [_jsx("strong", { children: "\uAD6C\uB9E4 \uC218\uB7C9" }), _jsx("br", {}), _jsx(KeyboardArrowLeftIcon, { role: "button", onClick: this.decrease }), _jsx("input", { type: "number", className: "amount", value: this.state.amount, onChange: function (e) { return _this.changeAmount(e.target.value); }, name: "amount" }), _jsx(KeyboardArrowRightIcon, { role: "button", onClick: this.increase })] })), _jsxs("div", __assign({ className: "cost" }, { children: ["\uD569\uACC4", " ", _jsx("strong", { children: Math.ceil((selected.buy * (100 - selected.discount)) / 100) * this.state.amount }), "G"] })), _jsx("button", __assign({ role: "button", onClick: this.buy }, { children: "\uAD6C\uB9E4\uD558\uAE30" }))] })) : (_jsx(_Fragment, {})) }))] }))] })), _jsx("div", __assign({ className: "npc" }, { children: _jsx("img", { src: "/static/img/shop_npc.png" }) }))] })) }))] }));
    };
    ShopList.prototype.comment = function (type) {
        var comments = [];
        switch (type) {
            case "buy":
                comments = BUY_COMMENT;
                break;
            case "enter":
                comments = ENTER_COMMENT;
                break;
            case "money":
                comments = MONEY_COMMENT;
                break;
            case "stock":
                comments = STOCK_COMMENT;
                break;
        }
        var randomKey = Math.floor(Math.random() * comments.length);
        this.setState({ comment: comments[randomKey] });
    };
    return ShopList;
}(SiteComponent));
export default connect()(ShopList);
