var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./roomPopup.scss";
export default function RoomPopup(_a) {
    var children = _a.children, height = _a.height, show = _a.show, width = _a.width, setShow = _a.setShow;
    return show ? (_jsx("div", __assign({ className: "room-popup-container" }, { children: _jsx("div", __assign({ className: "room-popup", style: { height: height, width: width } }, { children: _jsxs("div", __assign({ className: "room-menu" }, { children: [children, _jsx("div", __assign({ className: "menu-button", onClick: function () { return setShow(false); } }, { children: "\uB2EB\uAE30" }))] })) })) }))) : (_jsx(_Fragment, {}));
}
