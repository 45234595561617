var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./Banner.scss";
var TIMEOUT = 2000;
var SHOW_DURATION = 500;
var HIDE_DURATION = 700;
export function Banner(_a) {
    var type = _a.type, stageName = _a.stageName, showBanner = _a.showBanner, onAnimationEnd = _a.onAnimationEnd, onClickExit = _a.onClickExit, isAnimating = _a.isAnimating, rewards = _a.rewards;
    var _b = __read(useState(false), 2), display = _b[0], setDisplay = _b[1];
    var _c = __read(useState(""), 2), className = _c[0], setClassName = _c[1];
    var _d = __read(useState(""), 2), bgm = _d[0], setBgm = _d[1];
    useEffect(function () {
        if (!showBanner)
            return;
        if (showBanner === "start") {
            setBgm("battle_start.wav");
        }
        if (showBanner === "finish") {
            setBgm("battle_end.mp3");
        }
        if (showBanner === "fail") {
            setBgm("");
        }
    }, [showBanner]);
    useEffect(function () {
        if (!showBanner || isAnimating)
            return;
        setDisplay(true);
        setClassName("show");
        setTimeout(function () {
            setClassName("");
        }, SHOW_DURATION);
        if (showBanner === "start") {
            setTimeout(function () {
                setClassName("hide");
            }, TIMEOUT);
            setTimeout(function () {
                setDisplay(false);
                onAnimationEnd(showBanner);
            }, TIMEOUT + HIDE_DURATION);
        }
    }, [showBanner, isAnimating]);
    var title = "";
    if (showBanner === "start")
        title = type === "battle" ? "전투 개시" : "탐험 시작";
    if (showBanner === "finish")
        title = type === "battle" ? "전투 승리" : "탐험 성공";
    if (showBanner === "fail")
        title = type === "battle" ? "전투 패배" : "탐험 실패";
    return display ? (_jsxs("div", __assign({ className: "game-banner ".concat(className) }, { children: [_jsx("div", { className: "drop-shadow" }), _jsx("div", { className: "emblem" }), _jsx("div", { className: "box" }), _jsxs("div", __assign({ className: "text" }, { children: [_jsx("div", __assign({ className: "title" }, { children: _jsx("span", { children: title }) })), showBanner === "start" ? (_jsx("div", __assign({ className: "desc" }, { children: _jsx("span", { children: stageName }) }))) : (_jsx("button", __assign({ className: "exit", onClick: onClickExit }, { children: "\uB098\uAC00\uAE30" })))] })), showBanner === "finish" && rewards && rewards.length ? (_jsxs("div", __assign({ className: "rewards" }, { children: [_jsx("div", __assign({ className: "reward-title" }, { children: "REWARDS" })), rewards === null || rewards === void 0 ? void 0 : rewards.map(function (_a, i) {
                        var item = _a.item, amount = _a.amount;
                        if (!item)
                            return;
                        return (_jsxs("div", __assign({ className: "reward s".concat(i) }, { children: [_jsx("img", { src: item.icon }), " ", item.name, " x ", amount] }), i));
                    })] }))) : (_jsx(_Fragment, {})), _jsx("audio", { src: "/static/audio/".concat(bgm), autoPlay: true })] }))) : (_jsx(_Fragment, {}));
}
