var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showNavigation } from "./store/actions";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "./structure";
import moment from "moment";
function Lobby() {
    var dispatch = useDispatch();
    var config = useSelector(function (v) { return v.root.config; });
    useEffect(function () {
        dispatch(showNavigation(true));
    }, []);
    return (_jsxs("div", __assign({ className: "lobby" }, { children: [_jsx("div", { className: "bg-emblem" }), _jsx("div", { className: "logo ".concat(config.show_application ? "withbanner" : "") }), _jsx("div", __assign({ className: "banner" }, { children: config.show_application ? (_jsxs("div", __assign({ className: "application-banner" }, { children: [_jsx("div", __assign({ className: "title" }, { children: "APPLICATION" })), _jsxs("div", __assign({ className: "date" }, { children: [moment(config.application_start).format("YY.MM.DD HH:mm"), " ~", " ", moment(config.application_end).format("YY.MM.DD HH:mm")] })), _jsx(Link, __assign({ to: "/application" }, { children: _jsx("div", { className: "link-btn" }) }))] }))) : (_jsx(_Fragment, {})) }))] })));
}
export default connect()(Lobby);
