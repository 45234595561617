var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InventoryType, ItemType, inventoryData, itemTypes, } from "@iter-acillba/core";
import { ReactComponent } from "../../structure";
import ItemIcon from "../Item/ItemIcon";
import "./inventory.scss";
import { openPopup } from "../../site/store/actions";
var Inventory = /** @class */ (function (_super) {
    __extends(Inventory, _super);
    function Inventory(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            tab: InventoryType.ALL,
            items: [],
            props: undefined,
        };
        _this.unequipItem = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/inventory/equip/" + item.type,
                            method: "delete",
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.equipItem = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/inventory/equip/" + item.id,
                            method: "post",
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.useConsume = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var consumeFn;
            var _this = this;
            return __generator(this, function (_a) {
                consumeFn = this.props.consumeFn;
                if (item.type !== ItemType.CONSUME) {
                    this.dispatch(openPopup({
                        type: "alert",
                        message: "\uC0AC\uC6A9\uD560 \uC218 \uC5C6\uB294 \uC544\uC774\uD15C\uC785\uB2C8\uB2E4.",
                    }));
                }
                else if (!consumeFn) {
                    this.dispatch(openPopup({
                        type: "alert",
                        message: "\uC544\uC774\uD15C\uC744 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.",
                    }));
                }
                else {
                    this.dispatch(openPopup({
                        type: "confirm",
                        message: "아이템을 사용하시겠습니까?",
                        confirmFunction: function (res) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!res)
                                            return [2 /*return*/];
                                        return [4 /*yield*/, consumeFn(item.id)];
                                    case 1:
                                        _a.sent();
                                        return [4 /*yield*/, this.updateData()];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); },
                    }));
                }
                return [2 /*return*/];
            });
        }); }; };
        _this.useItem = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var useItem;
            var _this = this;
            return __generator(this, function (_a) {
                useItem = this.props.useItem;
                if (!useItem) {
                    this.dispatch(openPopup({
                        type: "alert",
                        message: "\uC544\uC774\uD15C\uC744 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.",
                    }));
                }
                else {
                    this.dispatch(openPopup({
                        type: "confirm",
                        message: "아이템을 사용하시겠습니까?",
                        confirmFunction: function (res) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!res)
                                            return [2 /*return*/];
                                        return [4 /*yield*/, this.callAPI({
                                                url: "/api/inventory/use/" + item.id,
                                                method: "post",
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [4 /*yield*/, this.updateData()];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); },
                    }));
                }
                return [2 /*return*/];
            });
        }); }; };
        _this.sellItem = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (item.sell < 0) {
                    this.dispatch(openPopup({
                        type: "alert",
                        message: "\uD310\uB9E4\uD560 \uC218 \uC5C6\uB294 \uC544\uC774\uD15C\uC785\uB2C8\uB2E4.",
                    }));
                    return [2 /*return*/];
                }
                this.dispatch(openPopup({
                    type: "numberConfirm",
                    numberRange: { min: 1, max: item.amount },
                    message: (_jsxs(_Fragment, { children: ["\uC544\uC774\uD15C\uC744 \uD310\uB9E4\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C? ", _jsx("br", {}), " \uD310\uB9E4\uAC00\uB294 \uAC1C\uB2F9 ", item.sell, "G\uC785\uB2C8\uB2E4."] })),
                    confirmFunction: function (res, amount) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!res)
                                        return [2 /*return*/];
                                    return [4 /*yield*/, this.callAPI({
                                            url: "/api/shop/sell/" + item.id,
                                            params: { amount: amount },
                                            method: "post",
                                        })];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, this.updateData()];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                }));
                return [2 /*return*/];
            });
        }); }; };
        _this.deleteItem = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.dispatch(openPopup({
                    type: "numberConfirm",
                    numberRange: { min: 1, max: item.amount },
                    message: (_jsxs(_Fragment, { children: ["\uC544\uC774\uD15C\uC744 \uBC84\uB9AC\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?", _jsx("br", {}), "\uC0AD\uC81C\uB41C \uC544\uC774\uD15C\uC740 \uBCF5\uAD6C\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."] })),
                    confirmFunction: function (res, amount) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!res)
                                        return [2 /*return*/];
                                    return [4 /*yield*/, this.callAPI({
                                            url: "/api/inventory/" + item.id,
                                            params: { amount: amount },
                                            method: "delete",
                                        })];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, this.updateData()];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                }));
                return [2 /*return*/];
            });
        }); }; };
        _this.state.tab = props.tab || InventoryType.ALL;
        return _this;
    }
    Inventory.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Inventory.prototype.updateData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, items, props;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/inventory/" + this.props.characterId,
                            method: "get",
                        })];
                    case 1:
                        _a = _b.sent(), items = _a.items, props = _a.props;
                        this.setState({ items: items, props: props });
                        return [2 /*return*/];
                }
            });
        });
    };
    //
    Inventory.prototype.render = function () {
        var _this = this;
        if (!this.state.props) {
            return _jsx(_Fragment, {});
        }
        var _a = this.state, props = _a.props, items = _a.items, tab = _a.tab;
        var _b = this.props.disable, disable = _b === void 0 ? [] : _b;
        var tabs = inventoryData.filter(function (v) { return !disable.includes(v.type); });
        var tabData = tabs.find(function (_a) {
            var type = _a.type;
            return type === tab;
        }) || tabs[0];
        var filter = tabData.filter;
        var equiped = [
            props === null || props === void 0 ? void 0 : props.accessory_id,
            props === null || props === void 0 ? void 0 : props.armor_id,
            props === null || props === void 0 ? void 0 : props.weapon_id,
        ].filter(function (v) { return !!v; });
        return (_jsxs("div", __assign({ className: "inventory" }, { children: [_jsxs("div", __assign({ className: "money" }, { children: [_jsx("div", __assign({ className: "title" }, { children: "\uC18C\uC9C0\uAE08" })), _jsxs("div", __assign({ className: "buget" }, { children: [props.money || 0, _jsx("strong", { children: "G" })] }))] })), _jsx("div", __assign({ className: "tabs" }, { children: tabs.map(function (_a, i) {
                        var type = _a.type, name = _a.name;
                        var selected = type === tab ? "selected" : "";
                        return (_jsx("div", __assign({ onClick: function () { return _this.setState({ tab: type }); }, className: "tab ".concat(selected) }, { children: name }), i));
                    }) })), _jsx("div", __assign({ className: "item-list" }, { children: items
                        .filter(function (i) { return filter.includes(i.type); })
                        .map(function (inv, i) { return (_jsx(ItemIcon, { equiped: equiped.includes(inv.id), item: inv, offset: _this.props.criteria, tool: _this.itemTools(inv) }, i)); }) }))] })));
    };
    Inventory.prototype.itemTools = function (item) {
        if (this.props.disableTool)
            return null;
        var _a = this.props, useItem = _a.useItem, consumeFn = _a.consumeFn;
        var props = this.state.props;
        var equip = [ItemType.WEAPON, ItemType.ACCESSORY, ItemType.ARMOR];
        var useItems = [ItemType.SKILLBOOK, ItemType.GACHA, ItemType.OUTFIT];
        var disableDelete = [itemTypes.quest];
        var type = item.type;
        var equiped = [
            props === null || props === void 0 ? void 0 : props.accessory_id,
            props === null || props === void 0 ? void 0 : props.armor_id,
            props === null || props === void 0 ? void 0 : props.weapon_id,
        ].filter(function (v) { return !!v; });
        if (equiped.includes(item.id)) {
            return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "tool-item", onClick: this.unequipItem(item) }, { children: "\uCC29\uC6A9\uD574\uC81C" })) }));
        }
        return (_jsxs(_Fragment, { children: [useItem && useItems.includes(type) ? (_jsx("div", __assign({ className: "tool-item", onClick: this.useItem(item) }, { children: "\uC0AC\uC6A9\uD558\uAE30" }))) : (_jsx(_Fragment, {})), consumeFn && type === ItemType.CONSUME ? (_jsx("div", __assign({ className: "tool-item", onClick: this.useConsume(item) }, { children: "\uC0AC\uC6A9\uD558\uAE30" }))) : (_jsx(_Fragment, {})), equip.includes(type) ? (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "tool-item", onClick: this.equipItem(item) }, { children: "\uCC29\uC6A9\uD558\uAE30" })) })) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "tool-item", onClick: this.sellItem(item) }, { children: "\uD310\uB9E4\uD558\uAE30" })), !disableDelete.includes(type) ? (_jsx("div", __assign({ onClick: this.deleteItem(item), className: "tool-item" }, { children: "\uBC84\uB9AC\uAE30" }))) : (_jsx(_Fragment, {}))] }));
    };
    return Inventory;
}(ReactComponent));
export default Inventory;
