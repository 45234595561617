var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { createStore } from "../structure";
import { rootReducer } from "./store/root";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/main.scss";
import App from "./App";
import { debounce } from "lodash";
import { showNavigation, startControl } from "./store/actions";
import { BrowserRouter, Route } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
var Main = /** @class */ (function () {
    function Main(rootId) {
        var _this = this;
        this.store = createStore({ root: rootReducer });
        this.onClick = function () {
            _this.store.dispatch(startControl(true));
            window.removeEventListener("click", _this.onClick);
        };
        this.onScroll = debounce(function () {
            var current = window.scrollY;
            if (current > 0) {
                _this.store.dispatch(showNavigation(false));
            }
            else {
                _this.store.dispatch(showNavigation(true));
            }
        }, 16);
        moment.tz.setDefault("Asia/Seoul");
        this.setScrollEvent();
        window.addEventListener("click", this.onClick);
        var root = ReactDOM.createRoot(document.getElementById(rootId));
        root.render(_jsx(Provider, __assign({ store: this.store }, { children: _jsx(BrowserRouter, { children: _jsx(Route, { component: App }) }) })));
    }
    Main.prototype.setScrollEvent = function () {
        var _this = this;
        window.addEventListener("scroll", function () {
            _this.onScroll();
        });
    };
    return Main;
}());
export { Main };
