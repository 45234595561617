import moment from "moment";
import "moment-duration-format";
export function DBFormat(value) {
    return moment(value).format("YYYY-MM-DD HH:mm:ss");
}
export function getLastUpdate(updateTime) {
    var time = moment(updateTime);
    var now = moment();
    if (time.add(60, "seconds") > now) {
        return time.diff(now, "seconds") + "초 전";
    }
    if (time.add(60, "minutes") > now) {
        return time.diff(now, "minutes") + "분 전";
    }
    if (time.add(24, "h") > now) {
        return time.diff(now, "h") + "시간 전";
    }
    return time.format("MM/DD");
}
export function playTime(start, end) {
    var momentTime = moment(end).diff(start, "second");
    return moment.duration(momentTime, "second").format("h[h] m[m] s[s]");
}
