var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Battle, ItemType, itemTypes, } from "@iter-acillba/core";
import React from "react";
import SkillComponent from "../Skill/Skill";
var EQUIP_TYPE = [ItemType.ACCESSORY, ItemType.ARMOR, ItemType.WEAPON];
var ItemPopup = /** @class */ (function (_super) {
    __extends(ItemPopup, _super);
    function ItemPopup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = React.createRef();
        return _this;
    }
    ItemPopup.prototype.componentDidMount = function () {
        var _a;
        this.props.onShow(((_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || {
            width: 0,
            height: 0,
        });
    };
    ItemPopup.prototype.render = function () {
        var _a = this.props, style = _a.style, visible = _a.visible, data = _a.data;
        var _b = data, name = _b.name, description = _b.description, type = _b.type, sell = _b.sell, buy = _b.buy, value = _b.data, skills = _b.skills;
        var parsed = (typeof value === "string" ? JSON.parse(value) : value) || {};
        return (_jsxs("div", __assign({ ref: this.ref, className: "item-popup ".concat(visible ? "visible" : ""), style: style }, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("h5", __assign({ className: "col-8" }, { children: name })), _jsx("div", __assign({ className: "col-4 text-end fs--1" }, { children: itemTypes[type] }))] })), _jsx("p", __assign({ className: "mt-1 mb-1" }, { children: description })), data.type === ItemType.SKILLBOOK && skills ? (_jsx("div", __assign({ className: "mt-1 mb-2" }, { children: skills.map(function (skill, i) {
                        return _jsx(SkillComponent, { data: skill }, i);
                    }) }))) : (_jsx(_Fragment, {})), EQUIP_TYPE.includes(data.type) ? (_jsx("div", __assign({ className: "mt-1 mb-2" }, { children: Object.entries(parsed).map(function (_a) {
                        var _b = __read(_a, 2), key = _b[0], val = _b[1];
                        var status = Battle.statusData[key];
                        var value = +val;
                        if (!status)
                            return;
                        return (_jsxs("div", { children: [status.name, " : ", value > 0 ? "".concat(value) : "-".concat(value)] }));
                    }) }))) : (_jsx(_Fragment, {})), _jsxs("p", __assign({ className: "mt-1 mb-1 fs--1" }, { children: [buy >= 0 ? "\uAD6C\uB9E4\uAC00: ".concat(buy, "G") : "비매품", " /", " ", sell >= 0 ? "\uD310\uB9E4\uAC00: ".concat(sell, "G") : "판매불가"] }))] })));
    };
    return ItemPopup;
}(React.Component));
export { ItemPopup };
