import { Explore } from "src/structure";

export type BlockPosition = {
  x: number;
  y: number;
  block: Explore.Block;
};

export class BlockMap {
  blockMap: BlockPosition[] = [];

  constructor(private blocks: Explore.Block[]) {
    const startBlock = this.getStartBlock();
    if (startBlock) {
      this.mapBlock([0, 0], startBlock.id);
    }
  }

  getBlockMap() {
    return this.blockMap;
  }

  addBlocks(blocks: Explore.Block[]) {
    blocks.forEach((block) => {
      if (this.blocks.find((b) => b.id === block.id)) return;
      this.blocks.push(block);
    });
    this.blockMap = [];
    this.mapBlock([0, 0], this.getStartBlock().id);
  }

  mapBlock([x, y]: number[], id: number) {
    const block = this.blocks.find((b) => b.id === id);
    if (!block) return;

    if (this.blockMap.find((v) => v.x === x && v.y === y)) {
      return;
    }

    this.blockMap.push({ x, y, block: { ...block } });

    const { top, left, bottom, right } = block;

    this.mapBlock([x + 1, y], right);
    this.mapBlock([x, y + 1], bottom);
    this.mapBlock([x, y - 1], top);
    this.mapBlock([x - 1, y], left);
  }

  getPosById(id: number) {
    const { x, y } = this.blockMap.find((v) => v.block.id === id) || {};
    return [x, y];
  }

  getBlockById(id: number) {
    return this.getById(id)?.block;
  }

  getById(id: number) {
    return this.blockMap.find(({ block }) => block.id === id);
  }

  getStartBlock() {
    return this.blocks.find((v) => v.type === "start");
  }

  getLastBlock() {
    return this.blocks.find((v) => v.type === "end");
  }

  getNearBlock(dir: "top" | "left" | "bottom" | "right", blockId: number) {
    return this.getBlockById(this.getBlockById(blockId)[dir]);
  }

  getNearBlocks(blockId) {
    const block = this.getBlockById(blockId);
    const blocks = [block.top, block.bottom, block.left, block.right]
      .filter((v) => !!v)
      .map((id) => this.getBlockById(id));
    return blocks;
  }

  move(x: number, y: number, blockId: number) {
    const target = this.blockMap.find((v) => v.block.id === blockId);
    const moveX = target.x + x;
    const moveY = target.y + y;
    return this.blockMap.find((v) => v.x === moveX && v.y == moveY)?.block;
  }

  moveable(x: number, y: number) {
    return !!this.blockMap.find((v) => v.x === x && v.y == y)?.block;
  }
}
