var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import Effect from "./Effect";
import EffectText from "./EffectText";
import Targeting from "./Targeting";
export default function Unit(_a) {
    var isSelected = _a.isSelected, couldSelect = _a.couldSelect, selectTarget = _a.selectTarget, unitKey = _a.unitKey, contextId = _a.contextId, type = _a.type, effects = _a.effects, buffs = _a.buffs, actions = _a.actions, onAnimationEnd = _a.onAnimationEnd, children = _a.children;
    var animationEndFlag = useRef(0);
    var _b = __read(useState([]), 2), runActions = _b[0], setRunActions = _b[1];
    var effectActions = runActions.filter(function (a) { return a.data.effectId; });
    useEffect(function () {
        if (contextId === 0)
            return;
        var effects = actions
            .filter(function (a) { return a.type === "effect"; })
            .filter(function (a) { return a.contextId === contextId; })
            .filter(function (_a) {
            var data = _a.data;
            return data.targetKey === unitKey;
        });
        if (effects.length === 0) {
            onAnimationEnd();
        }
        else {
            setRunActions(effects);
            animationEndFlag.current = 0;
        }
    }, [contextId]);
    var effectAnimationEnd = function () {
        animationEndFlag.current += 1;
        if (animationEndFlag.current === effectActions.length + 1) {
            onAnimationEnd();
        }
    };
    return (_jsxs("div", __assign({ className: "unit ".concat(type) }, { children: [children, _jsx(Targeting, { type: type, isSelected: isSelected, couldSelect: couldSelect, selectTarget: selectTarget }), effectActions
                .filter(function (a) { return a.data.effectId; })
                .map(function (action, i) {
                return (_jsx(Effect, { type: type, action: action, effects: effects, onAnimationEnd: effectAnimationEnd }, i));
            }), _jsx(EffectText, { type: type, actions: runActions, buffs: buffs, onAnimationEnd: effectAnimationEnd })] })));
}
