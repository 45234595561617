var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { connect } from "../structure";
import BattleLobby from "./battle/BattleLobby";
import ExploreLobby from "./explore/ExploreLobby";
import { Link, Route, Switch } from "react-router-dom";
import "./game.scss";
import { useSelector } from "react-redux";
import NeedLogin from "../components/NeedLogin";
import Title from "../components/Title";
import Quest from "./quest/Quest";
function Game() {
    var isLogin = useSelector(function (v) { return v.root.user.id; });
    return (_jsxs(Switch, { children: [_jsx(Route, { path: "/journey/explore", component: isLogin ? ExploreLobby : NeedLogin }), _jsx(Route, { path: "/journey/battle", component: isLogin ? BattleLobby : NeedLogin }), _jsx(Route, { path: "/journey/quest", component: isLogin ? Quest : NeedLogin }), _jsx(Route, __assign({ path: "/journey/" }, { children: _jsxs(_Fragment, { children: [_jsx(Title, { text: "JOURNEY", links: [] }), _jsxs("div", __assign({ className: "menu-panels" }, { children: [_jsx("div", __assign({ className: "panel top" }, { children: _jsx(Link, __assign({ to: "/journey/explore" }, { children: _jsx("img", { src: "/static/img/panel_explore.png", alt: "explore" }) })) })), _jsxs("div", __assign({ className: "justify" }, { children: [_jsx("div", __assign({ className: "panel" }, { children: _jsx(Link, __assign({ to: "/journey/battle", className: "panel" }, { children: _jsx("img", { src: "/static/img/panel_battle.png", alt: "explore" }) })) })), _jsx("div", __assign({ className: "panel" }, { children: _jsx(Link, __assign({ to: "/journey/quest", className: "panel" }, { children: _jsx("img", { src: "/static/img/panel_quest.png", alt: "explore" }) })) }))] }))] }))] }) }))] }));
}
export default connect()(Game);
