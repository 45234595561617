export var Macro = [
    {
        command: "@등장",
        type: "appear",
    },
    {
        command: "@퇴장",
        type: "disappear",
    },
    {
        command: "@평소",
        type: "idle",
    },
    {
        command: "@미소",
        type: "smile",
    },
    {
        command: "@슬픔",
        type: "sad",
    },
    {
        command: "@화남",
        type: "upset",
    },
    {
        command: "@놀람",
        type: "surprise",
    },
];
export function separateMacro(text) {
    if (text === void 0) { text = ""; }
    var res = { macro: "", text: text };
    if (!text)
        return res;
    Macro.forEach(function (_a) {
        var command = _a.command, type = _a.type;
        var tokens = res.text.split(command);
        if (tokens.length > 1) {
            res.macro = type;
        }
        res.text = tokens.join("");
    });
    return res;
}
