var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SiteComponent, connect } from "../structure";
import { Battle, Character } from "@iter-acillba/core";
import moment from "moment";
import { encodeText, hexToRGBA } from "../../utils";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { openPopup } from "../store/actions";
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            job: [],
            race: [],
        };
        _this.delete = function () {
            _this.dispatch(openPopup({
                type: "confirm",
                message: "신청서는 삭제하면 복구할 수 없습니다. 정말 삭제하시겠습니까?",
                confirmFunction: function (res) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!res) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.callAPI({
                                        url: "/api/character/delete/" + this.props.character.id,
                                        method: "post",
                                    })];
                            case 1:
                                _a.sent();
                                this.props.updateCharacter();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); },
            }));
        };
        return _this;
    }
    Main.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, job, race;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/character/statistic",
                            method: "get",
                        })];
                    case 1:
                        _a = _b.sent(), job = _a.job, race = _a.race;
                        this.setState({ job: job, race: race });
                        return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.render = function () {
        var _a = this.state, job = _a.job, race = _a.race;
        var _b = this.props, character = _b.character, config = _b.config;
        var name = character.name, state = character.state, update_count = character.update_count, updated_timestamp = character.updated_timestamp;
        var jobs = Object.keys(Battle.jobs);
        var jobColor = Battle.jobColor;
        var raceColor = ["#68bee4", "#ec8557", "#664d86", "#50be63", "#cdcdcd"];
        var chartOptions = {
            responsive: false,
            plugins: {
                legend: {
                    position: "right",
                    labels: {
                        color: "#ddd",
                        font: {
                            size: 14,
                        },
                    },
                },
            },
        };
        return (_jsxs("div", __assign({ className: "main" }, { children: [_jsxs("div", __assign({ className: "status" }, { children: [_jsxs("div", __assign({ className: "column" }, { children: [_jsx("h3", { children: "\uB0B4 \uC2E0\uCCAD \uD604\uD669" }), character.id ? (_jsxs("div", __assign({ className: "character-name" }, { children: [_jsx("div", __assign({ className: "state" }, { children: state === Character.State.DRAFT ? "임시저장" : "제출완료" })), name] }))) : (_jsx("div", __assign({ className: "character-name" }, { children: "\uC544\uC9C1 \uC2E0\uCCAD\uC11C\uB97C \uC791\uC131\uD558\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4." })))] })), character.id ? (_jsxs("div", __assign({ className: "column info" }, { children: [_jsxs("div", __assign({ className: "last-update" }, { children: ["\uCD5C\uC885 \uC5C5\uB370\uC774\uD2B8:", " ", moment(updated_timestamp).format("YYYY/MM/DD HH:mm")] })), state === Character.State.DRAFT ? (_jsx(_Fragment, {})) : (_jsxs("div", __assign({ className: "update-count" }, { children: ["\uC794\uC5EC \uC218\uC815\uD69F\uC218 ", _jsx("strong", { children: update_count || 0 }), "\uD68C"] }))), _jsx("div", { children: _jsx("span", __assign({ className: "delete", onClick: this.delete }, { children: "\uC2E0\uCCAD\uC11C \uC0AD\uC81C" })) })] }))) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "bottom-area" }, { children: [_jsxs("div", __assign({ className: "notice" }, { children: [_jsx("h3", { children: "\uACF5\uC9C0\uC0AC\uD56D" }), _jsxs("span", { children: ["\uCD5C\uC885 \uC5C5\uB370\uC774\uD2B8:", " ", moment(config.updated_timestamp).format("YYYY/MM/DD HH:mm")] }), _jsx("p", { dangerouslySetInnerHTML: {
                                        __html: encodeText(config.application_notice),
                                    } })] })), _jsxs("div", __assign({ className: "statistic" }, { children: [_jsx("h3", { children: "\uC2E0\uCCAD\uC11C \uD1B5\uACC4" }), _jsx("span", { children: "\uC81C\uCD9C \uC644\uB8CC\uB41C \uC2E0\uCCAD\uC11C \uD1B5\uACC4\uC785\uB2C8\uB2E4. \uC2E4\uC2DC\uAC04\uC73C\uB85C \uBC18\uC601\uB429\uB2C8\uB2E4." }), _jsxs("div", __assign({ className: "statistic-content" }, { children: [_jsxs("div", __assign({ className: "chart" }, { children: [_jsx("h4", { children: "1\uC9C0\uB9DD \uD074\uB798\uC2A4 \uD1B5\uACC4" }), _jsx(Pie, { width: 350, height: 200, data: {
                                                        labels: Object.values(Battle.jobs),
                                                        datasets: [
                                                            {
                                                                label: "1지망 신청서 수",
                                                                data: job,
                                                                backgroundColor: jobs.map(function (v) {
                                                                    return hexToRGBA(jobColor[v], 0.3);
                                                                }),
                                                                borderColor: jobs.map(function (v) { return jobColor[v]; }),
                                                                borderWidth: 1,
                                                            },
                                                        ],
                                                    }, options: chartOptions })] })), _jsxs("div", __assign({ className: "chart" }, { children: [_jsx("h4", { children: "\uC885\uC871 \uD1B5\uACC4" }), _jsx(Pie, { width: 350, height: 200, data: {
                                                        labels: Character.raceType.map(function (v) { return v.name; }),
                                                        datasets: [
                                                            {
                                                                label: "1지망 신청서 수",
                                                                data: race,
                                                                backgroundColor: raceColor.map(function (v) {
                                                                    return hexToRGBA(v, 0.3);
                                                                }),
                                                                borderColor: raceColor,
                                                                borderWidth: 1,
                                                            },
                                                        ],
                                                    }, options: chartOptions })] }))] }))] }))] }))] })));
    };
    return Main;
}(SiteComponent));
export default connect()(Main);
