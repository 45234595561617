var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { connectState, ReactComponent } from "../../structure";
import "./item.scss";
import { ItemPopup } from "./ItemPopup";
var ItemIcon = /** @class */ (function (_super) {
    __extends(ItemIcon, _super);
    function ItemIcon() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showPopup: false,
            visiblePopup: false,
            showTool: false,
            visibleTool: false,
            offset: { x: 0, y: 0, width: 0, height: 0 },
            toolPosition: { left: 0, top: 0 },
            mouseX: 0,
            mouseY: 0,
            popupWidth: 0,
            popupHeight: 0,
            toolX: 0,
            toolY: 0,
        };
        _this.ref = React.createRef();
        _this.onMouseMove = function (e) {
            var _a;
            var clientX = e.clientX, clientY = e.clientY;
            var _b = ((_a = _this.props.offset) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || { left: 0, top: 0 }, critX = _b.left, critY = _b.top, critW = _b.width, critH = _b.height;
            _this.setState({
                showPopup: true,
                mouseX: clientX,
                mouseY: clientY,
                offset: { x: critX, y: critY, width: critW, height: critH },
            });
        };
        _this.onMouseOut = function (e) {
            if (_this.state.showPopup) {
                _this.setState({
                    showPopup: false,
                    visiblePopup: false,
                });
            }
        };
        _this.showTool = function () {
            if (_this.props.onClick) {
                _this.props.onClick(_this.props.item);
                return;
            }
            if (!_this.props.tool)
                return;
            var _a = _this.state, mouseX = _a.mouseX, mouseY = _a.mouseY, _b = _a.offset, critX = _b.x, critY = _b.y;
            var x = mouseX - critX;
            var y = mouseY - critY;
            _this.setState({ showTool: true, toolPosition: { left: x, top: y } });
            window.addEventListener("click", _this.hideTool);
        };
        _this.hideTool = function (e) {
            e.preventDefault();
            var target = e.target;
            if (target && target.closest(".item-wrap") === _this.ref.current) {
                return;
            }
            _this.setState({ showTool: false });
            window.removeEventListener("click", _this.hideTool);
        };
        _this.setPopupPosition = function (_a) {
            var boxWidth = _a.width, boxHeight = _a.height;
            _this.setState({
                popupWidth: boxWidth,
                popupHeight: boxHeight,
                visiblePopup: true,
            });
        };
        return _this;
    }
    ItemIcon.prototype.render = function () {
        var _a = this.props, equiped = _a.equiped, showId = _a.showId;
        var _b = this.props
            .item, name = _b.name, type = _b.type, icon = _b.icon, amount = _b.amount, item_id = _b.item_id, id = _b.id;
        return (_jsxs("div", __assign({ className: "item-wrap", role: this.props.tool || this.props.onClick ? "button" : "", ref: this.ref, onClick: this.showTool }, { children: [_jsxs("div", __assign({ onMouseMove: this.onMouseMove, onMouseOut: this.onMouseOut, className: "item-icon ".concat(type) }, { children: [_jsxs("div", __assign({ className: "left" }, { children: [_jsx("img", { alt: name, src: icon || "/static/img/item_default.png" }), name] })), _jsxs("div", __assign({ className: "right" }, { children: [showId ? _jsx("span", { children: id }) : _jsx(_Fragment, {}), equiped ? _jsx("div", __assign({ className: "equip-badge" }, { children: "\uCC29\uC6A9\uC911" })) : _jsx(_Fragment, {}), amount > 1 ? _jsxs("span", { children: ["x ", amount] }) : _jsx(_Fragment, {})] }))] })), this.state.showPopup && !this.state.showTool ? (_jsx(ItemPopup, { onShow: this.setPopupPosition, visible: this.state.visiblePopup, data: this.props.item, style: this.getPopupPos() })) : null, this.state.showTool && this.props.tool ? (_jsx("div", __assign({ className: "tool", style: this.state.toolPosition }, { children: this.props.tool }))) : null] })));
    };
    ItemIcon.prototype.getPopupPos = function () {
        var windowHeight = window.innerHeight;
        var windowWidth = window.innerWidth;
        var _a = this.state, mouseX = _a.mouseX, mouseY = _a.mouseY, popupWidth = _a.popupWidth, popupHeight = _a.popupHeight, _b = _a.offset, critX = _b.x, critY = _b.y, critW = _b.width, critH = _b.height;
        var left, right, top, bottom;
        var x = mouseX - critX;
        var y = mouseY - critY;
        if (mouseY + popupHeight >= windowHeight) {
            top = "";
            bottom = "".concat((critH || windowHeight) - y, "px");
        }
        else {
            top = "".concat(y, "px");
            bottom = "";
        }
        if (mouseX + popupWidth >= windowWidth) {
            left = "";
            right = "".concat((critW || windowWidth) - x, "px");
        }
        else {
            left = "".concat(x, "px");
            right = "";
        }
        return { top: top, left: left, bottom: bottom, right: right };
    };
    return ItemIcon;
}(ReactComponent));
export default connectState()(ItemIcon);
