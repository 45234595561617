var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, Route, Switch } from "react-router-dom";
import World from "./World";
import "./document.scss";
import System from "./System";
import CharacterGuide from "./CharacterGuide";
import { connect } from "../structure";
import Title from "../components/Title";
function Document(props) {
    return (_jsx("div", __assign({ className: "document" }, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/document/system", component: System }), _jsx(Route, { path: "/document/world", component: World }), _jsx(Route, { path: "/document/character", component: CharacterGuide }), _jsx(Route, __assign({ path: "/document/", exact: true }, { children: _jsxs(_Fragment, { children: [_jsx(Title, { text: "DOCUMENT", links: [] }), _jsx("div", __assign({ className: "menu-panels" }, { children: _jsxs("div", __assign({ className: "justify" }, { children: [_jsx("div", __assign({ className: "panel" }, { children: _jsx(Link, __assign({ to: "/document/system" }, { children: _jsx("img", { src: "/static/img/panel_system.png", alt: "system" }) })) })), _jsx("div", __assign({ className: "panel" }, { children: _jsx(Link, __assign({ to: "/document/world" }, { children: _jsx("img", { src: "/static/img/panel_world.png", alt: "world" }) })) })), _jsx("div", __assign({ className: "panel" }, { children: _jsx(Link, __assign({ to: "/document/character" }, { children: _jsx("img", { src: "/static/img/panel_character.png", alt: "character" }) })) }))] })) }))] }) }))] }) })));
}
export default connect()(Document);
