import { DBRecord } from "./common";

export enum Level {
  BLOCKED = -10,
  TEST = -3,
  WAITING = -1,
  NEED_LOGIN = 0,
  ASSOCIATE = 1,
  REGULAR = 2,
  STAFF = 9,
  ADMIN = 10,
}

export const levelTitle = {
  0: "미로그인",
  [Level.BLOCKED]: "제한됨",
  [Level.TEST]: "테스트",
  [Level.WAITING]: "대기",
  [Level.ASSOCIATE]: "준회원",
  [Level.REGULAR]: "정회원",
  [Level.STAFF]: "스태프",
  [Level.ADMIN]: "관리자",
};

export const levels = [
  Level.BLOCKED,
  Level.TEST,
  Level.WAITING,
  Level.ASSOCIATE,
  Level.REGULAR,
  Level.STAFF,
  Level.ADMIN,
];

export type User = {
  username: string;
  email: string;
  password: string;
  level: Level;
  cert: string;
  birth: string;
  twitter: string;
  last_login: string;
} & DBRecord;
