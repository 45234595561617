var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
var TIMEOUT = 2500;
export default function Command(_a) {
    var actions = _a.actions, data = _a.data, contextId = _a.contextId, skills = _a.skills, monsters = _a.monsters, players = _a.players, onAnimationEnd = _a.onAnimationEnd;
    var _b = __read(useState(), 2), commandData = _b[0], setCommandData = _b[1];
    useEffect(function () {
        var _a, _b;
        if (!contextId || !data)
            return;
        var action = actions.find(function (v) { return v.contextId === contextId && v.type === "command"; });
        if (!action) {
            onAnimationEnd();
            return;
        }
        var _c = action.data, unitKey = _c.unitKey, skillId = _c.skillId, option = _c.option;
        var units = __spreadArray(__spreadArray([], __read(data.allies), false), __read(data.enemies), false);
        var actor = units.find(function (v) { return v.key === unitKey; });
        var mezed = (option === null || option === void 0 ? void 0 : option.meze) ? "행동불가" : undefined;
        if (actor.type === "ally") {
            var player = players.find(function (v) { return v.character.id === actor.characterId; });
            setCommandData({
                commandName: mezed || ((_a = skills.find(function (v) { return v.id === skillId; })) === null || _a === void 0 ? void 0 : _a.name),
                portrait: player.character.battle_img,
                actorName: player.character.nickname,
            });
        }
        else {
            var monster = monsters.find(function (v) { return v.id === actor.monsterId; });
            setCommandData({
                commandName: mezed || ((_b = skills.find(function (v) { return v.id === skillId; })) === null || _b === void 0 ? void 0 : _b.name),
                portrait: "/static/img/monster_portrait.png",
                actorName: monster.name,
            });
        }
        setTimeout(function () {
            setCommandData(undefined);
            onAnimationEnd();
        }, TIMEOUT);
    }, [contextId]);
    return commandData ? (_jsxs("div", __assign({ className: "command-notify" }, { children: [_jsx("div", __assign({ className: "portrait" }, { children: _jsx("img", { src: commandData.portrait, alt: "\uC720\uB2DB \uC0C1\uBC18\uC2E0" }) })), _jsxs("div", __assign({ className: "text" }, { children: [_jsx("div", __assign({ className: "command-name" }, { children: commandData.commandName })), _jsx("div", __assign({ className: "actor-name" }, { children: commandData.actorName }))] }))] }))) : (_jsx(_Fragment, {}));
}
