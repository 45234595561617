import { DBRecord } from "./common";
import { Place } from "./explore";
import { ItemReward } from "./item";

export enum QuestType {
  EXPLORE = "explore",
  BATTLE = "battle",
  SUBMIT_ITEM = "submitItem",
  USE_ITEM = "useItem",
}

export type Quest<T = ItemReward[], U = QuestType> = {
  name: string;
  open_timestamp: string;
  close_timestamp: string;
  description: string;
  reward: T;
  type: U;
  amount: number; // 조건 만족 횟수
  condition_description: string; // 퀘스트 조건 서술
  condition_target_id: number; // 조건 만족 대상 ID(스테이지, 아이템 ID)
  place_id: number;
  place?: Place;
} & DBRecord;

export type QuestInstance = {
  character_id: number;
  user_id: number;
  quest_id: number;
  completed: 1 | 0;
  completable?: number;
  quest?: Quest;
} & DBRecord;
