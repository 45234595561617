var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "@reduxjs/toolkit";
import { closePopup, login, logout, openPopup, playBgm, playMainBgm, previewPage, setCharacter, setCharacters, setConfig, setMainBgm, setRunBgm, showMessage, showNavigation, showSetting, startControl, stopBgm, } from "./actions";
import { Site } from "@iter-acillba/core";
export var rootReducer = createReducer({
    user: {},
    popup: {
        message: "",
        type: "alert",
    },
    showSetting: false,
    showNavigation: true,
    runBgm: null,
    mainBgm: null,
    bgmPause: false,
    config: Site.defaultConfig,
    startControl: false,
    previewPage: false,
    characters: [],
    character: {},
    showMessage: 0,
}, function (builder) {
    builder.addCase(login, function (state, action) {
        state.user = __assign({}, action.payload);
    });
    builder.addCase(logout, function (state) {
        state.user = {};
    });
    builder.addCase(showNavigation, function (state, _a) {
        var payload = _a.payload;
        state.showNavigation = payload;
    });
    builder.addCase(openPopup, function (state, _a) {
        var payload = _a.payload;
        state.popup = payload;
    });
    builder.addCase(showSetting, function (state, _a) {
        var payload = _a.payload;
        state.showSetting = payload;
    });
    builder.addCase(closePopup, function (state) {
        state.popup = { message: "", type: "alert" };
    });
    builder.addCase(startControl, function (state, _a) {
        var payload = _a.payload;
        state.startControl = payload;
    });
    builder.addCase(setConfig, function (state, _a) {
        var payload = _a.payload;
        state.config = payload;
    });
    builder.addCase(setMainBgm, function (state, _a) {
        var payload = _a.payload;
        state.mainBgm = payload;
        state.runBgm = payload;
        state.bgmPause = false;
    });
    builder.addCase(setRunBgm, function (state, _a) {
        var payload = _a.payload;
        state.runBgm = payload;
        state.bgmPause = false;
    });
    builder.addCase(playBgm, function (state) {
        state.bgmPause = false;
    });
    builder.addCase(playMainBgm, function (state) {
        state.runBgm = state.mainBgm;
        state.bgmPause = false;
    });
    builder.addCase(stopBgm, function (state) {
        state.bgmPause = true;
    });
    builder.addCase(previewPage, function (state) {
        state.previewPage = true;
    });
    builder.addCase(setCharacters, function (state, _a) {
        var payload = _a.payload;
        state.characters = payload;
    });
    builder.addCase(setCharacter, function (state, _a) {
        var payload = _a.payload;
        state.character = payload;
    });
    builder.addCase(showMessage, function (state, _a) {
        var payload = _a.payload;
        state.showMessage = payload;
    });
});
