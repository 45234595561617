import { DBRecord } from "./common";

export enum Role {
  MEMBER = "member",
  CHARACTER = "character",
  MAIN = "main",
  DESIGN = "design",
  TIMELINE = "timeline",
  BOARD = "board",
  DIARY = "diary",
  ITEM = "item",
  EXPLORE = "explore",
  COMBAT = "combat",
  SKILL = "skill",
}

export const Roles: Role[] = [
  Role.MEMBER,
  Role.CHARACTER,
  Role.MAIN,
  Role.DESIGN,
  Role.TIMELINE,
  Role.BOARD,
  Role.DIARY,
  Role.ITEM,
  Role.EXPLORE,
  Role.COMBAT,
  Role.SKILL,
];

export type Admin = {
  user_id: number;
  Role: Role;
} & DBRecord;

export type Config = {
  id: number;
  join_start: string;
  join_end: string;
  need_login: 0 | 1;
  title: string;
  max_upload_size: number;
  application_notice: string;
  updated_timestamp: string;
  application_start: string;
  application_end: string;
  show_application: 0 | 1;
  max_ap: number;
};

export type Bgm = {
  url: string;
  youtube: string;
  is_main: 0 | 1;
  name: string;
} & DBRecord;

export const defaultConfig: Config = {
  id: 1,
  need_login: 0,
  show_application: 0,
  max_upload_size: 2000,
  application_end: "",
  application_start: "",
  join_end: "",
  join_start: "",
  title: "Iter Acillba",
  application_notice: "",
  updated_timestamp: "",
  max_ap: 10,
};

export type LogType = "encounter" | "useItem" | "getItem";

export type Log<T = EncounterLog | ItemLog, U = LogType> = {
  character_id: number;
  type: U;
  data: T;
} & DBRecord;

export type EncounterLog = {
  stage_id: number;
  encounter_id: number;
};
export type ItemLog = {
  id: number;
  amount: number;
  from: "explore" | "battle" | "shop" | "dicebot";
};
