var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NoticeType } from "@iter-acillba/core/dist/structure/api";
import axios from "axios";
import React from "react";
import { toast } from "./store";
import querystring from "querystring";
var ReactComponent = /** @class */ (function (_super) {
    __extends(ReactComponent, _super);
    function ReactComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputState = function (key) {
            return function (e) {
                var _a, _b, _c;
                var target = e.target;
                var value = target.value, name = target.name, files = target.files, checked = target.checked, type = target.type;
                var setValue = type === "checkbox" ? (checked ? 1 : 0) : (files && files[0]) || value;
                if (!key) {
                    _this.setState((_a = {}, _a[name] = setValue, _a));
                }
                else {
                    var prev = _this.state[key];
                    _this.setState((_b = {}, _b[key] = __assign(__assign({}, prev), (_c = {}, _c[name] = setValue, _c)), _b));
                }
            };
        };
        return _this;
    }
    ReactComponent.prototype.redirect = function (location) {
        if (this.props.history) {
            this.props.history.push(location);
        }
        else {
            console.warn("Component isn't routed!");
        }
    };
    ReactComponent.prototype.matchPath = function (path, exact) {
        var _a;
        if (exact === void 0) { exact = false; }
        if (!this.props.location) {
            console.warn("Component isn't routed!");
        }
        var pathname = ((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.pathname) || "";
        if (exact && pathname === path) {
            return true;
        }
        var regex = new RegExp("^".concat(path));
        return regex.test(pathname);
    };
    ReactComponent.prototype.setQuery = function (queryObj) {
        var _a;
        if (!this.props.history) {
            console.warn("Component isn't routed!");
        }
        (_a = this.props.history) === null || _a === void 0 ? void 0 : _a.replace("?".concat(querystring.stringify(queryObj)));
    };
    ReactComponent.prototype.getQuery = function (queryName) {
        var _a;
        if (!this.props.location) {
            console.warn("Component isn't routed!");
        }
        var parsed = querystring.parse(((_a = this.props.location) === null || _a === void 0 ? void 0 : _a.search.replace("?", "")) || "");
        return parsed[queryName];
    };
    ReactComponent.prototype.dispatch = function (action) {
        if (this.props.dispatch) {
            this.props.dispatch(action);
        }
        else {
            console.warn("Component isn't connected store!");
        }
    };
    ReactComponent.prototype.callAPI = function (config, ignoreError, ignoreMessage) {
        if (ignoreError === void 0) { ignoreError = false; }
        if (ignoreMessage === void 0) { ignoreMessage = false; }
        return __awaiter(this, void 0, Promise, function () {
            var noticed, noticeError, res, _a, notice, success, data, e_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        noticed = false;
                        noticeError = function () {
                            return _this.dispatch(toast({
                                type: NoticeType.DANGER,
                                text: "서버에 데이터 요청중 에러가 발생했습니다.",
                            }));
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios(config)];
                    case 2:
                        res = _b.sent();
                        _a = res.data, notice = _a.notice, success = _a.success, data = _a.data;
                        if (notice && !ignoreMessage) {
                            if (notice.type === NoticeType.LOG) {
                                console.error(notice.text);
                            }
                            else {
                                this.dispatch(toast({ type: notice.type, text: notice.text }));
                                noticed = true;
                            }
                        }
                        if (success) {
                            return [2 /*return*/, data];
                        }
                        if (!ignoreError) {
                            if (!noticed)
                                noticeError();
                            throw new Error("\uB370\uC774\uD130 \uC694\uCCAD\uC5D0 \uC2E4\uD328\uD588\uC2B5\uB2C8\uB2E4. api:".concat(config.url));
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        if (!ignoreError) {
                            if (!noticed)
                                noticeError();
                            throw new Error(e_1 || "Error occured when calling api: ".concat(config.url));
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, undefined];
                }
            });
        });
    };
    return ReactComponent;
}(React.Component));
export { ReactComponent };
