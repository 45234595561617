var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockMap } from "@iter-acillba/core";
import { ReactComponent, WatchState } from "../../structure";
import EventScriptWindow from "../EventScript/EventScriptWindow";
import "./explore.scss";
import { ExploreStage } from "./ExploreStage";
import InventoryIcon from "@mui/icons-material/Inventory";
import MenuIcon from "@mui/icons-material/Menu";
import { Banner } from "../Banner/Banner";
import RoomPopup from "../RoomPopup";
import { uniq, times } from "lodash";
import moment from "moment";
var ExploreWindow = /** @class */ (function (_super) {
    __extends(ExploreWindow, _super);
    function ExploreWindow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.blockMap = new BlockMap([]);
        _this.state = {
            visibleBlocks: [],
            bgm: undefined,
            showMenu: false,
            isLoading: false,
            isMoving: false,
            characters: [],
            stage: undefined,
            place: undefined,
            instance: undefined,
            pos: [0, 0],
            trapped: false,
            keys: [],
            showBanner: "",
            waitingInstance: undefined,
            showInventory: false,
        };
        _this.exitBattle = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({ instance: this.state.waitingInstance });
                this.props.changeMusic(this.state.bgm);
                return [2 /*return*/];
            });
        }); };
        _this.finishBattle = function () { return __awaiter(_this, void 0, void 0, function () {
            var instance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/explore/finishBattle/",
                            method: "post",
                        })];
                    case 1:
                        instance = (_a.sent()).instance;
                        this.setState({ waitingInstance: instance });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.useItem = function (inventoryId) { return __awaiter(_this, void 0, void 0, function () {
            var instance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/explore/useItem/" + inventoryId,
                            method: "post",
                        })];
                    case 1:
                        instance = (_a.sent()).instance;
                        this.setState({ instance: instance });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.bannerAnimationEnd = function () {
            _this.setState({ showBanner: "" });
        };
        _this.exit = function () {
            _this.props.onFinish(_this.state.stage, _this.state.instance);
        };
        _this.onFinishEvent = function (eventQueue) { return __awaiter(_this, void 0, void 0, function () {
            var _a, instance, blocks;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({
                            instance: __assign(__assign({}, this.state.instance), { event_id: null }),
                        });
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/explore/finishEvent",
                                params: { test: this.props.test ? 1 : 0 },
                                data: { eventQueue: eventQueue },
                                method: "post",
                            })];
                    case 1:
                        _a = _b.sent(), instance = _a.instance, blocks = _a.blocks;
                        this.forceUpdate(function () {
                            _this.setState({
                                instance: instance,
                                visibleBlocks: uniq(__spreadArray(__spreadArray([], __read(_this.state.visibleBlocks), false), __read(blocks), false)),
                            });
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onMoveEnd = function () {
            _this.setState({ isMoving: false });
            if (_this.state.isLoading)
                return;
            if (_this.state.waitingInstance) {
                _this.setState({ instance: _this.state.waitingInstance });
            }
            _this.setState({
                waitingInstance: undefined,
            });
        };
        _this.onMove = function (dir, x, y) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.blockMap.moveable(x, y))
                            return [2 /*return*/];
                        if (((_a = this.state.instance) === null || _a === void 0 ? void 0 : _a.state) !== "start")
                            return [2 /*return*/];
                        this.setState({ isMoving: true, pos: [x, y] });
                        return [4 /*yield*/, this.move(dir)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ExploreWindow.prototype.watchBgm = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.props.changeMusic(this.state.bgm);
                return [2 /*return*/];
            });
        });
    };
    ExploreWindow.prototype.watchInstance = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var instance, lastBlock, pos, trap_time, trap_timestamp, now, release;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.state.instance)
                            return [2 /*return*/];
                        instance = this.state.instance;
                        lastBlock = instance.paths[((_a = instance.paths) === null || _a === void 0 ? void 0 : _a.length) - 1];
                        pos = this.blockMap.getPosById(lastBlock);
                        trap_time = instance.trap_time, trap_timestamp = instance.trap_timestamp;
                        if (trap_timestamp) {
                            now = moment();
                            release = moment(trap_timestamp).add(trap_time, "s");
                            if (now.isBefore(release)) {
                                this.setState({ trapped: true });
                                setTimeout(function () {
                                    _this.setState({ trapped: false });
                                }, release.diff(now, "ms"));
                            }
                        }
                        this.setState({
                            pos: pos,
                        });
                        if (!(instance.place_id !== ((_b = this.state.place) === null || _b === void 0 ? void 0 : _b.id))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updatePlace(instance.place_id)];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        if (!(instance.bgm_id && instance.bgm_id !== ((_c = this.state.bgm) === null || _c === void 0 ? void 0 : _c.id))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.updateBgm(instance.bgm_id)];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        if (instance.state === "success") {
                            this.setState({ showBanner: "finish" });
                        }
                        else if (instance.state === "fail") {
                            this.setState({ showBanner: "fail" });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ExploreWindow.prototype.updateBgm = function (bgm_id) {
        return __awaiter(this, void 0, void 0, function () {
            var bgm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/site/bgm/" + bgm_id,
                            method: "get",
                        })];
                    case 1:
                        bgm = _a.sent();
                        this.setState({ bgm: bgm });
                        return [2 /*return*/, bgm];
                }
            });
        });
    };
    ExploreWindow.prototype.updatePlace = function (place_id) {
        return __awaiter(this, void 0, void 0, function () {
            var place;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/explore/place/" + place_id,
                            method: "get",
                        })];
                    case 1:
                        place = _a.sent();
                        this.setState({ place: place });
                        return [2 /*return*/, place];
                }
            });
        });
    };
    ExploreWindow.prototype.componentDidMount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var characters, _b, instance, stage, blocks, place;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/explore/npc/",
                                method: "get",
                            })];
                    case 1:
                        characters = _c.sent();
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/explore/start/" + this.props.stageId,
                                params: { test: this.props.test ? 1 : 0 },
                                method: "get",
                            })];
                    case 2:
                        _b = _c.sent(), instance = _b.instance, stage = _b.stage, blocks = _b.blocks;
                        this.blockMap.addBlocks(stage.blocks);
                        return [4 /*yield*/, this.updatePlace(instance.place_id)];
                    case 3:
                        place = _c.sent();
                        return [4 /*yield*/, this.updateBgm(instance.bgm_id || place.bgm_id)];
                    case 4:
                        _c.sent();
                        this.setState({
                            characters: characters,
                            instance: instance,
                            stage: stage,
                            visibleBlocks: blocks,
                            isLoading: false,
                        });
                        if (((_a = instance.paths) === null || _a === void 0 ? void 0 : _a.length) <= 1) {
                            this.setState({ showBanner: "start" });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ExploreWindow.prototype.move = function (direction) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, eventId, instance, blocks;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/explore/move/" + direction,
                                method: "post",
                            })];
                    case 1:
                        _a = _b.sent(), eventId = _a.eventId, instance = _a.instance, blocks = _a.blocks;
                        if (this.state.isMoving) {
                            this.setState({
                                waitingInstance: instance,
                                waitingEventId: eventId,
                            });
                        }
                        else {
                            this.setState({
                                instance: instance,
                                event: eventId,
                            });
                        }
                        this.setState({
                            visibleBlocks: uniq(__spreadArray(__spreadArray([], __read(this.state.visibleBlocks), false), __read(blocks), false)),
                            isLoading: false,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ExploreWindow.prototype.callCharacters = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/explore/npc",
                            method: "GET",
                        })];
                    case 1:
                        data = (_a.sent()).data;
                        this.setState({ characters: data });
                        return [2 /*return*/];
                }
            });
        });
    };
    ExploreWindow.prototype.render = function () {
        var _this = this;
        var _a = this.state, stage = _a.stage, place = _a.place, pos = _a.pos, trapped = _a.trapped, instance = _a.instance, visibleBlocks = _a.visibleBlocks;
        if (!(stage && place && pos && instance))
            return _jsx(_Fragment, {});
        if (instance.battle_id) {
            return this.props.BattleComponent({
                roomId: instance.battle_id,
                onExit: this.exitBattle,
                onError: this.props.onError,
                user: this.props.user,
                onGameEnd: this.finishBattle,
                changeMusic: this.props.changeMusic,
            });
        }
        return (_jsxs("div", __assign({ className: "explore-window" }, { children: [_jsxs("div", __assign({ className: "background" }, { children: [_jsx("img", { src: place.background_image, alt: "background" }), _jsx("div", { className: "pattern" })] })), _jsxs("div", __assign({ className: "explore-container" }, { children: [_jsx(ExploreStage, { paths: instance.paths, blocks: visibleBlocks.map(function (id) { return _this.blockMap.getById(id); }), pos: pos, trapped: trapped, event: instance.event_id, preventMove: this.state.isLoading, onMove: this.onMove, onMoveEnd: this.onMoveEnd }), _jsx("div", __assign({ className: "info" }, { children: _jsxs("div", __assign({ className: "character" }, { children: [_jsxs("div", __assign({ className: "hp" }, { children: [_jsx("div", { className: "hp-bar", style: {
                                                    width: "".concat((instance.status.hp / instance.status.max_hp) * 280, "px"),
                                                } }), _jsxs("span", __assign({ className: "hp-text" }, { children: [_jsx("strong", { children: instance.status.hp }), " /", " ", instance.status.max_hp] })), _jsx("div", __assign({ className: "sp-bar" }, { children: times(instance.status.max_sp, function (i) {
                                                    var sp = instance.status.sp;
                                                    var fill = i < sp;
                                                    return (_jsx("div", __assign({ className: "sp-dot" }, { children: fill ? _jsx("div", { className: "fill" }) : _jsx(_Fragment, {}) }), i));
                                                }) }))] })), _jsx("div", __assign({ className: "name" }, { children: this.props.character.nickname })), _jsxs("div", __assign({ className: "emblem" }, { children: [_jsx("div", { className: "emblem-img", style: {
                                                    backgroundImage: "url('".concat(this.props.character.thumbnail_img, "')"),
                                                } }), _jsx("div", { className: "emblem-overlay" })] }))] })) })), _jsxs("div", __assign({ className: "menu" }, { children: [_jsxs("div", __assign({ className: "ap" }, { children: [_jsx("span", __assign({ className: "cur" }, { children: this.props.character.ap })), _jsxs("span", __assign({ className: "max" }, { children: ["/", this.props.max_ap] }))] })), _jsx("div", __assign({ className: "menu-icon", onClick: function () { return _this.setState({ showMenu: true }); } }, { children: _jsx(MenuIcon, {}) })), instance.character_id ? (_jsx("div", __assign({ className: "menu-icon", onClick: function () { return _this.setState({ showInventory: true }); } }, { children: _jsx(InventoryIcon, {}) }))) : (_jsx(_Fragment, {}))] })), _jsx("div", { className: "banner" })] })), _jsx(Banner, { rewards: stage.reward, type: "explore", stageName: stage.name, onAnimationEnd: this.bannerAnimationEnd, onClickExit: this.exit, isAnimating: false, showBanner: this.state.showBanner }), instance.event_id ? (_jsx(EventScriptWindow, { characters: this.state.characters, eventId: instance.event_id, onFinish: this.onFinishEvent })) : null, _jsx(RoomPopup, __assign({ height: 118, show: this.state.showMenu, setShow: function (showMenu) { return _this.setState({ showMenu: showMenu }); } }, { children: _jsx("div", __assign({ className: "menu-button", onClick: function () {
                            _this.surrender();
                            _this.setState({ showMenu: false });
                        } }, { children: "\uD0D0\uD5D8 \uD3EC\uAE30" })) })), this.inventory()] })));
    };
    ExploreWindow.prototype.inventory = function () {
        var _this = this;
        var instance = this.state.instance;
        if (!instance || !instance.character_id)
            return;
        return (_jsx(RoomPopup, __assign({ height: 500, width: 400, show: this.state.showInventory, setShow: function (showInventory) { return _this.setState({ showInventory: showInventory }); } }, { children: _jsx("div", __assign({ className: "explore-inventory" }, { children: _jsx("div", __assign({ className: "scroller" }, { children: _jsx("div", __assign({ className: "scroll-contents" }, { children: this.props.inventory(instance.character_id, this.useItem) })) })) })) })));
    };
    ExploreWindow.prototype.surrender = function () {
        return __awaiter(this, void 0, void 0, function () {
            var instance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/explore/surrender",
                            method: "post",
                        })];
                    case 1:
                        instance = (_a.sent()).instance;
                        this.setState({
                            instance: instance,
                            showBanner: "fail",
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        WatchState("bgm")
    ], ExploreWindow.prototype, "watchBgm", null);
    __decorate([
        WatchState("instance")
    ], ExploreWindow.prototype, "watchInstance", null);
    return ExploreWindow;
}(ReactComponent));
export default ExploreWindow;
