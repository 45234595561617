export enum NoticeType {
  SUCCESS = "success",
  DANGER = "danger",
  WARNING = "warning",
  LOG = "log",
}

export type HTTPResponse<T = any> = {
  success?: boolean;
  notice?: {
    type?: NoticeType;
    text?: string;
    redirect?: string;
  };
  data?: T;
};

// export type WebSocketRequest = {};
// export type WebSocketResponse = {};
