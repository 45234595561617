var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "./structure";
function Background() {
    var ref = useRef(null);
    var _a = __read(useState(false), 2), showImage = _a[0], setShowImage = _a[1];
    var location = useLocation();
    useEffect(function () {
        if (location.pathname === "/") {
            setShowImage(true);
        }
        else {
            setShowImage(false);
        }
    }, [location]);
    return (_jsxs("div", __assign({ className: "video-container" }, { children: [_jsx("video", __assign({ ref: ref, onEnded: function () {
                    ref.current.currentTime = 10;
                    ref.current.play();
                }, className: "bg-video", muted: true, autoPlay: true }, { children: _jsx("source", { src: "/static/img/bg_video.mp4", type: "video/mp4" }) })), _jsx("div", { className: "bg-image", style: { opacity: showImage ? 1 : 0 } })] })));
}
export default connect()(Background);
