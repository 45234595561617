var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dungeon } from "@iter-acillba/core";
import { useEffect, useMemo, useRef, useState } from "react";
var TIMEOUT = 300;
var KEYBOARD = ["Q", "W", "E", "R", "T"];
var KEY_MAPPING = [
    ["Q", "q", "ㅂ"],
    ["W", "w", "ㅈ"],
    ["E", "e", "ㄷ"],
    ["R", "r", "ㄱ"],
    ["T", "t", "ㅅ"],
];
function Spinner() {
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "40px", height: "40px", viewBox: "0 0 100 100", preserveAspectRatio: "xMidYMid" }, { children: _jsx("circle", __assign({ cx: "50", cy: "50", r: "35", strokeWidth: "12", stroke: "#ffffff", strokeDasharray: "54.97787143782138 54.97787143782138", fill: "none", strokeLinecap: "round" }, { children: _jsx("animateTransform", { attributeName: "transform", type: "rotate", repeatCount: "indefinite", dur: "2s", keyTimes: "0;1", values: "0 50 50;360 50 50" }) })) })));
}
export default function CommandList(_a) {
    var roomState = _a.roomState, playerSkills = _a.playerSkills, selectedSkill = _a.selectedSkill, isAnimating = _a.isAnimating, onSelectSkill = _a.onSelectSkill, onSelectWait = _a.onSelectWait, confirmSkill = _a.confirmSkill, disableKey = _a.disableKey, readyCommand = _a.readyCommand;
    var _b = __read(useState(-1), 2), touchedCommand = _b[0], setTouchedCommand = _b[1];
    var _c = __read(useState(false), 2), showSkillList = _c[0], setShowSkillList = _c[1];
    var sfx = useMemo(function () {
        var audio = new Audio("/static/audio/selection.wav");
        audio.loop = false;
        audio.volume = 1;
        return audio;
    }, []);
    var afterFn = useRef(function () { });
    var normalAttack = playerSkills[0];
    var cancelId = playerSkills.length - 1;
    var _d = __read(useState(), 2), timeoutId = _d[0], setTimeoutId = _d[1];
    var commandList = [];
    if (selectedSkill) {
        commandList = [
            function () {
                return onClickCommand(0, function () {
                    setShowSkillList(false);
                    confirmSkill();
                });
            },
            function () {
                return onClickCommand(1, function () {
                    onSelectSkill(-1);
                });
            },
        ];
    }
    else if (showSkillList) {
        commandList = playerSkills.slice(1).map(function (_a, i) {
            var id = _a.id;
            return function () {
                onClickCommand(i, function () {
                    onSelectSkill(id);
                });
            };
        });
        commandList.push(function () {
            onClickCommand(cancelId, function () {
                setShowSkillList(false);
            });
        });
    }
    else {
        commandList = [
            function () {
                return onClickCommand(0, function () {
                    onSelectSkill(normalAttack.id);
                });
            },
            function () {
                return onClickCommand(1, function () {
                    setShowSkillList(true);
                });
            },
            function () {
                return onClickCommand(2, function () {
                    onSelectWait();
                });
            },
        ];
    }
    function onClickCommand(idx, fn) {
        setTouchedCommand(idx);
        sfx.currentTime = 0;
        sfx.play();
        afterFn.current = fn;
    }
    function isSelected(idx) {
        return idx === touchedCommand ? "selected" : "";
    }
    useEffect(function () {
        var handler = function (e) {
            if (disableKey || isAnimating || readyCommand)
                return;
            var find = KEY_MAPPING.find(function (keys) { return keys.includes(e.key); });
            var idx = KEY_MAPPING.indexOf(find);
            if (idx >= 0) {
                commandList[idx]();
            }
        };
        window.addEventListener("keydown", handler);
        return function () {
            window.removeEventListener("keydown", handler);
        };
    }, [commandList]);
    useEffect(function () {
        if (touchedCommand < 0)
            return;
        if (timeoutId)
            clearTimeout(timeoutId);
        var timeout = setTimeout(function () {
            setTouchedCommand(-1);
            afterFn.current();
        }, TIMEOUT);
        setTimeoutId(timeout);
    }, [touchedCommand]);
    if (roomState !== Dungeon.State.READY) {
        return _jsx(_Fragment, {});
    }
    if (isAnimating) {
        return (_jsxs("div", __assign({ className: "command-waiting" }, { children: [_jsx(Spinner, {}), "\uB77C\uC6B4\uB4DC \uC9C4\uD589\uC911..."] })));
    }
    if (readyCommand) {
        return (_jsxs("div", __assign({ className: "command-waiting" }, { children: [_jsx(Spinner, {}), "\uB2E4\uB978 \uBA64\uBC84\uC758 \uCEE4\uB9E8\uB4DC \uB300\uAE30\uC911..."] })));
    }
    if (selectedSkill) {
        return (_jsxs("div", __assign({ className: "commands" }, { children: [_jsxs("div", __assign({ className: "command ".concat(isSelected(0)), onClick: commandList[0] }, { children: [_jsx("div", __assign({ className: "key-icon" }, { children: "Q" })), _jsx("div", __assign({ className: "key-icon selected" }, { children: "Q" })), _jsx("div", __assign({ className: "text" }, { children: "\uC120\uD0DD\uC644\uB8CC" }))] })), _jsxs("div", __assign({ className: "command ".concat(isSelected(1)), onClick: commandList[1] }, { children: [_jsx("div", __assign({ className: "key-icon" }, { children: "W" })), _jsx("div", __assign({ className: "key-icon selected" }, { children: "W" })), _jsx("div", __assign({ className: "text" }, { children: "\uCDE8\uC18C" }))] }))] })));
    }
    if (showSkillList) {
        return (_jsxs("div", __assign({ className: "commands" }, { children: [playerSkills.slice(1).map(function (_a, i) {
                    var id = _a.id, name = _a.name, cooldown = _a.cooldown, sp = _a.sp;
                    return (_jsxs("div", __assign({ className: "command ".concat(isSelected(i)), onClick: commandList[i] }, { children: [_jsx("div", __assign({ className: "key-icon" }, { children: KEYBOARD[i] })), _jsx("div", __assign({ className: "key-icon selected" }, { children: KEYBOARD[i] })), _jsxs("div", __assign({ className: "text" }, { children: [name, " ", "⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁".slice(0, sp), " ", cooldown ? _jsxs("small", { children: [cooldown, "\uB77C\uC6B4\uB4DC"] }) : _jsx(_Fragment, {})] }))] }), id));
                }), _jsxs("div", __assign({ className: "command ".concat(isSelected(cancelId)), onClick: commandList[cancelId] }, { children: [_jsx("div", __assign({ className: "key-icon" }, { children: KEYBOARD[cancelId] })), _jsx("div", __assign({ className: "key-icon selected" }, { children: KEYBOARD[cancelId] })), _jsx("div", __assign({ className: "text" }, { children: "\uCDE8\uC18C" }))] }))] })));
    }
    return (_jsxs("div", __assign({ className: "commands" }, { children: [_jsxs("div", __assign({ className: "command ".concat(isSelected(0)), onClick: commandList[0] }, { children: [_jsx("div", __assign({ className: "key-icon" }, { children: "Q" })), _jsx("div", __assign({ className: "key-icon selected" }, { children: "Q" })), _jsx("div", __assign({ className: "text" }, { children: "\uC77C\uBC18\uACF5\uACA9" }))] })), _jsxs("div", __assign({ className: "command ".concat(isSelected(1)), onClick: commandList[1] }, { children: [_jsx("div", __assign({ className: "key-icon" }, { children: "W" })), _jsx("div", __assign({ className: "key-icon selected" }, { children: "W" })), _jsx("div", __assign({ className: "text" }, { children: "\uC2A4\uD0AC \uC0AC\uC6A9" }))] })), _jsxs("div", __assign({ className: "command ".concat(isSelected(2)), onClick: commandList[2] }, { children: [_jsx("div", __assign({ className: "key-icon" }, { children: "E" })), _jsx("div", __assign({ className: "key-icon selected" }, { children: "E" })), _jsx("div", __assign({ className: "text" }, { children: "\uD134 \uB118\uAE30\uAE30" }))] }))] })));
}
