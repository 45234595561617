import { createAction } from "@reduxjs/toolkit";
export var login = createAction("login");
export var logout = createAction("logout");
export var openPopup = createAction("openPopup");
export var closePopup = createAction("closePopup");
export var showSetting = createAction("showSetting");
export var showNavigation = createAction("showNavigation");
export var setConfig = createAction("setConfig");
export var startControl = createAction("startControl");
export var setMainBgm = createAction("setMainBgm");
export var setRunBgm = createAction("setRunBgm");
export var playMainBgm = createAction("playMainBgm");
export var stopBgm = createAction("stopBgm");
export var playBgm = createAction("playBgm");
export var previewPage = createAction("previewPage");
export var setCharacters = createAction("setCharacters");
export var setCharacter = createAction("setCharacter");
export var showMessage = createAction("showMessage");
