var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Character } from "@iter-acillba/core";
import { BattleStatusInput, ImageInput } from "../../components/Inputs";
import InfoIcon from "@mui/icons-material/Info";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SiteComponent, connect } from "../structure";
import CloseIcon from "@mui/icons-material/Close";
import { openPopup } from "../store/actions";
import moment from "moment";
import WarningIcon from "@mui/icons-material/Warning";
import ButtonSpinner from "../../components/ButtonSpinner";
import { WatchProp } from "../../structure";
var Form = /** @class */ (function (_super) {
    __extends(Form, _super);
    function Form(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            is_draft: false,
            preview: "",
            is_loading: false,
            commit: {
                commit1: 0,
                commit2: 0,
                commit3: 0,
                commit4: 0,
            },
        };
        _this.onClickUpdate = function () {
            var update_count = _this.props.character.update_count;
            _this.dispatch(openPopup({
                type: "confirm",
                message: (_jsxs(_Fragment, { children: ["\uC218\uC815\uD69F\uC218\uAC00 \uCC28\uAC10\uB429\uB2C8\uB2E4.", _jsx("br", {}), " (\uC794\uC5EC \uC218\uC815\uD69F\uC218: ", update_count, ")"] })),
                confirmFunction: function (res) {
                    if (res) {
                        _this.submitForm();
                    }
                },
            }));
        };
        if (props.character.id) {
            _this.state.commit = {
                commit1: 1,
                commit2: 1,
                commit3: 1,
                commit4: 1,
            };
        }
        return _this;
    }
    Form.prototype.updateCharacter = function () {
        if (this.props.character.id) {
            this.setState({
                commit: {
                    commit1: 1,
                    commit2: 1,
                    commit3: 1,
                    commit4: 1,
                },
            });
        }
        this.setState({ is_loading: false });
    };
    Form.prototype.submitForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var enableSubmit, character, is_draft, id, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.state.is_loading) {
                            return [2 /*return*/];
                        }
                        enableSubmit = Object.values(this.state.commit).every(function (v) { return +v === 1; });
                        if (!enableSubmit) {
                            this.dispatch(openPopup({ message: "러닝 서약 동의가 필요합니다.", type: "alert" }));
                            return [2 /*return*/];
                        }
                        character = this.props.character;
                        is_draft = this.state.is_draft;
                        id = character.id;
                        this.setState({ is_loading: true });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.callAPI({
                                url: "/api/character/".concat(id ? "update/".concat(id) : "add"),
                                method: "post",
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                                data: __assign(__assign(__assign({}, character), { state: is_draft ? Character.State.DRAFT : Character.State.SUCCESS }), (id ? {} : { is_application: true })),
                            })];
                    case 2:
                        _b.sent();
                        this.props.updateCharacter();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        this.setState({ is_loading: false });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Form.prototype.render = function () {
        var _this = this;
        var _a = this.props.character, update_count = _a.update_count, state = _a.state;
        var _b = this.props.config, application_start = _b.application_start, application_end = _b.application_end;
        var enable_success = moment().isBetween(application_start, application_end);
        var commit = this.state.commit;
        var commitments = [
            "<strong>&lt;\uC774\uD14C\uB974 \uC544\uD0AC\uBC14&gt;</strong>\uB294 \uC218\uCC9C\uB9CC\uB144\uAC04 \uC774\uC5B4\uC9C4 \uC2E0\uACFC \uC778\uB958 \uCD5C\uD6C4\uC758 \uC2F8\uC6C0,\n        \uADF8 \uD558\uC774\uB77C\uC774\uD2B8\uC758 \uC8FC\uC778\uACF5\uC774 \uB418\uB294 \uC2A4\uD1A0\uB9AC \uC9C4\uD589 \uC911\uC2EC\uC758 \uB9C8\uC2A4\uD1A0\uB3C8 \uCEE4\uBBA4\uB2C8\uD2F0\uC785\uB2C8\uB2E4.<br />\n        \uCEE4\uBBA4\uB2C8\uD2F0\uC758 \uC804\uCCB4\uC801 \uD750\uB984\uC774 \uC774\uC5D0 \uB9DE\uCD94\uC5B4\uC838 \uC788\uB2E4\uB294 \uAC83,<br />\n        \uC2A4\uD1A0\uB9AC \uC9C4\uD589\uC5D0 \uB300\uD558\uC5EC \uB7EC\uB108\uC9C4 \uC804\uCCB4\uB97C \uBE44\uB86F\uD558\uC5EC \uB7EC\uB108 \uAC1C\uC778\uC758 \uD611\uB825\uC774<br />\n        \uBB34\uC5C7\uBCF4\uB2E4\uB3C4 \uC911\uC694\uD568\uC744 \uC778\uC9C0\uD558\uACE0 \uB3D9\uC758\uD558\uC2ED\uB2C8\uAE4C?",
            "<strong>&lt;\uC774\uD14C\uB974 \uC544\uD0AC\uBC14&gt;</strong>\uB294 \uC124\uC815, \uC2A4\uD1A0\uB9AC \uC2A4\uD06C\uB9BD\uD2B8 \uB4F1\uC5D0 \n        [\uC790\uC0C1, \uC720\uD608, \uC790\uD574, \uC790\uACB0, \uD76C\uC0DD, \uC2E0\uC131\uBAA8\uB3C5, \uC6D0\uCE58\uC54A\uB294 \uC120\uD0DD\uC744 \uAC15\uC694\uB2F9\uD568 \uB4F1. <br />\n            \uD2B9\uD788, \uCEE4\uBBA4\uB2C8\uD2F0\uC758 \uD2B9\uC131\uC0C1 \uC804\uD22C\uB97C \uC704\uD574\uC11C\uB294 \uC790\uD574\uAC00 \uD544\uC218\uC694\uC18C]\uC758 \uC694\uC18C\uB97C \uD3EC\uD568\uD569\uB2C8\uB2E4. <br />\n            \uC774\uB7EC\uD55C \uC694\uC18C\uB294 \uB7EC\uB2DD \uC804\uBC18\uC5D0 \uB4F1\uC7A5\uD560 \uC608\uC815\uC784\uC744 \uD655\uC778\uD558\uC168\uC2B5\uB2C8\uAE4C?",
            "<strong>&lt;\uC774\uD14C\uB974 \uC544\uD0AC\uBC14&gt;</strong>\uB294 \uBCF8 \uCEE4\uBBA4\uB2C8\uD2F0\uC758 \uC804\uC2E0\uC774 \uB418\uBA70 \uC138\uACC4\uAD00\uC744 \uBBF8\uBBF8\uD558\uAC8C \uACF5\uC720\uD560 \uBFD0, <br />\n            \uC644\uC804 \uBCC4\uAC1C\uC758 \uCEE4\uBBA4\uB2C8\uD2F0\uC778 <strong>&lt;\uC774\uD14C\uB974 \uB808\uAC00&gt;</strong> \uAD00\uB828 \uC5B8\uAE09\uC744 \uBE44\uB86F\uD55C \uC9C0\uC778\uD50C\uACFC \uB7EC\uB2DD \uC911 \uD3B8\uD30C \uB4F1 <br />\n            \uD2B9\uC815 \uBA64\uBC84\uAC00 \uC18C\uC678\uAC10\uC744 \uB290\uB07C\uAC8C \uB9CC\uB4DC\uB294 \uD589\uB3D9\uC744 \uC5C4\uACA9\uD788 \uAE08\uC9C0\uD569\uB2C8\uB2E4. <br />\n            \uC774\uB97C \uC5B4\uAE38 \uC2DC \uACBD\uACE0 \uC5C6\uC774 \uBB34\uD1B5\uBCF4 \uC81C\uBA85 \uCC98\uB9AC\uC5D0 \uB3D9\uC758\uD558\uC2ED\uB2C8\uAE4C?\n            ",
            "<strong>&lt;\uC774\uD14C\uB974 \uC544\uD0AC\uBC14&gt;</strong>\uC758 \uACF5\uC9C0\uC0AC\uD56D\uC744 \uAF3C\uAF3C\uD788 \uC815\uB3C5\uD588\uC73C\uBA70,<br />\n            \uC2E0\uCCAD\uC11C \uC81C\uCD9C\uC2DC \uBAA8\uB4E0 \uACF5\uC9C0\uC0AC\uD56D\uC5D0 \uB3D9\uC758\uD568\uC73C\uB85C \uAC04\uC8FC\uD568\uC744 \uC778\uC815\uD558\uC2ED\uB2C8\uAE4C? ",
        ];
        return (_jsxs("div", __assign({ className: "form" }, { children: [_jsx("h3", { children: "\uC2E0\uCCAD\uC11C \uC791\uC131 & \uC218\uC815" }), !enable_success ? (_jsxs("p", { children: [_jsx(WarningIcon, {}), "\uD604\uC7AC \uC2E0\uCCAD\uAE30\uAC04\uC774 \uC544\uB2C8\uBBC0\uB85C \uC784\uC2DC\uC800\uC7A5\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4."] })) : (_jsx(_Fragment, {})), _jsx("p", { children: "\uC774\uBBF8\uC9C0\uB294 \uC784\uC2DC\uC800\uC7A5 \uD6C4 \uBBF8\uB9AC\uBCF4\uAE30\uAC00 \uAC00\uB2A5\uD569\uB2C8\uB2E4." }), _jsxs("p", { children: ["\uAE34 \uD14D\uC2A4\uD2B8 \uD56D\uBAA9\uC5D0\uC11C ", _jsx("strong", { children: "#!https://imgur.com/EQWVo2e.png" }), " ", "\uD3EC\uB9F7\uC744 \uC785\uB825\uD558\uC5EC \uC774\uBBF8\uC9C0\uB97C \uCCA8\uBD80\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4."] }), state === Character.State.SUCCESS ? (update_count == 0 ? (_jsxs("p", { children: [_jsx(WarningIcon, {}), "\uC218\uC815\uD69F\uC218\uAC00 \uC804\uBD80 \uC18C\uC9C4\uB418\uC5B4 \uC5F4\uB78C\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4."] })) : (_jsxs("p", { children: [_jsx(WarningIcon, {}), "\uC794\uC5EC \uC218\uC815\uD69F\uC218 ", update_count, "\uD68C"] }))) : (_jsx(_Fragment, {})), _jsxs("form", __assign({ onSubmit: function (e) {
                        e.preventDefault();
                        _this.submitForm();
                    } }, { children: [_jsx(CharacterForm, { showPreview: function (preview) { return _this.setState({ preview: preview }); }, onChange: this.props.onChangeData, character: this.props.character }), _jsx("h4", { children: "\uB7EC\uB2DD \uC11C\uC57D\uC11C" }), commitments.map(function (v, i) {
                            var id = i + 1;
                            return (_jsxs("div", __assign({ className: "row commitment" }, { children: [_jsx("p", { className: "col-10", dangerouslySetInnerHTML: { __html: v } }), _jsx("div", __assign({ className: "col-1" }, { children: _jsxs("div", __assign({ className: "radio-box form-check" }, { children: [_jsx("input", { className: "form-check-input", onChange: _this.inputState("commit"), value: 1, checked: !!+commit["commit".concat(id)], type: "radio", name: "commit".concat(id), id: "commit".concat(id, "-yes") }), _jsx("label", __assign({ className: "form-check-label", htmlFor: "commit".concat(id, "-yes") }, { children: "\uC608" }))] })) })), _jsx("div", __assign({ className: "col-1" }, { children: _jsxs("div", __assign({ className: "radio-box form-check" }, { children: [_jsx("input", { className: "form-check-input", onChange: _this.inputState("commit"), value: 0, type: "radio", name: "commit".concat(id), id: "commit".concat(id, "-no") }), _jsx("label", __assign({ className: "form-check-label", htmlFor: "commit".concat(id, "-no") }, { children: "\uC544\uB2C8\uC624" }))] })) }))] }), i));
                        }), _jsx("div", __assign({ className: "form-buttons" }, { children: this.buttons() }))] })), this.state.preview ? this.previewWindow() : _jsx(_Fragment, {})] })));
    };
    Form.prototype.buttons = function () {
        var _this = this;
        var is_loading = this.state.is_loading;
        var _a = this.props.character, state = _a.state, update_count = _a.update_count;
        var _b = this.props.config, application_start = _b.application_start, application_end = _b.application_end;
        var enable_success = moment().isBetween(application_start, application_end);
        if (state === Character.State.SUCCESS) {
            if (!enable_success) {
                return _jsx("span", { children: "\uC2E0\uCCAD\uAE30\uAC04\uC774 \uC885\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4." });
            }
            if (update_count > 0) {
                return is_loading ? (_jsx("button", __assign({ type: "button" }, { children: _jsx(ButtonSpinner, {}) }))) : (_jsx("button", __assign({ type: "button", onClick: this.onClickUpdate }, { children: "\uC218\uC815\uD558\uAE30" })));
            }
            return _jsx("span", { children: "\uC218\uC815 \uAC00\uB2A5 \uD69F\uC218\uAC00 \uC804\uBD80 \uC18C\uC9C4\uB418\uC5C8\uC2B5\uB2C8\uB2E4." });
        }
        return (_jsxs(_Fragment, { children: [is_loading ? (_jsx("button", __assign({ type: "button" }, { children: _jsx(ButtonSpinner, {}) }))) : (_jsx("button", __assign({ type: "submit", onClick: function () { return _this.setState({ is_draft: true }); } }, { children: "\uC784\uC2DC\uC800\uC7A5" }))), enable_success ? (is_loading ? (_jsx("button", __assign({ type: "button" }, { children: _jsx(ButtonSpinner, {}) }))) : (_jsx("button", __assign({ type: "submit", onClick: function () { return _this.setState({ is_draft: false }); } }, { children: "\uC81C\uCD9C\uD558\uAE30" })))) : (_jsx(_Fragment, {}))] }));
    };
    Form.prototype.previewWindow = function () {
        var _this = this;
        var character = this.props.character;
        var preview = this.state.preview;
        var element;
        switch (preview) {
            case "body_img":
                element = (_jsxs(_Fragment, { children: [_jsx("h5", { children: "\uC804\uC2E0 \uC774\uBBF8\uC9C0 \uBBF8\uB9AC\uBCF4\uAE30" }), _jsx("p", { children: "\uC0C1\uD558 \uC5EC\uBC31 100px \uBBF8\uB9CC\uC744 \uAD8C\uC7A5\uD569\uB2C8\uB2E4." }), _jsx("div", __assign({ className: "image" }, { children: _jsx("img", { src: character.body_img, alt: "\uC804\uC2E0 \uC774\uBBF8\uC9C0" }) }))] }));
                break;
            case "thumbnail_img":
                element = (_jsxs(_Fragment, { children: [_jsx("h5", { children: "\uC378\uB124\uC77C \uC774\uBBF8\uC9C0 \uBBF8\uB9AC\uBCF4\uAE30" }), _jsx("p", { children: "\uCE90\uB9AD\uD130\uC758 \uC5BC\uAD74\uC774 \uC6D0 \uC548\uC5D0 \uAC00\uB4DD \uCC28\uB3C4\uB85D \uC870\uC815\uD574\uC8FC\uC138\uC694." }), _jsxs("div", __assign({ className: "image" }, { children: [_jsx("img", { src: character.thumbnail_img, alt: "\uC378\uB124\uC77C \uC774\uBBF8\uC9C0" }), _jsx("div", { className: "overlay" })] }))] }));
                break;
            case "battle_img":
                element = (_jsxs(_Fragment, { children: [_jsx("h5", { children: "\uBC18\uC2E0 \uC774\uBBF8\uC9C0 \uBBF8\uB9AC\uBCF4\uAE30" }), _jsx("p", { children: "\uCE90\uB9AD\uD130\uC758 \uC5BC\uAD74\uC774 \uC6D0 \uC548\uC5D0 \uAC00\uB4DD \uCC28\uB3C4\uB85D \uC870\uC815\uD574\uC8FC\uC138\uC694." }), _jsxs("div", __assign({ className: "image" }, { children: [_jsx("img", { src: character.battle_img, alt: "\uC378\uB124\uC77C \uC774\uBBF8\uC9C0" }), _jsx("div", { className: "overlay" })] }))] }));
                break;
            case "cutscene_img":
                element = (_jsxs(_Fragment, { children: [_jsx("h5", { children: "\uCEF7\uC778 \uC774\uBBF8\uC9C0 \uBBF8\uB9AC\uBCF4\uAE30" }), _jsxs("div", __assign({ className: "image" }, { children: [_jsx("img", { src: character.cutscene_img, alt: "\uCEF7\uC778 \uC774\uBBF8\uC9C0" }), _jsx("div", { className: "overlay" })] }))] }));
                break;
            default:
                element = _jsx(_Fragment, {});
        }
        return (_jsx("div", __assign({ className: "preview-container" }, { children: _jsxs("div", __assign({ className: "preview ".concat(preview) }, { children: [_jsxs("div", __assign({ className: "close", onClick: function () { return _this.setState({ preview: "" }); } }, { children: ["\uB2EB\uAE30", _jsx(CloseIcon, {})] })), element] })) })));
    };
    __decorate([
        WatchProp("character")
    ], Form.prototype, "updateCharacter", null);
    return Form;
}(SiteComponent));
function makeInput(_a) {
    var key = _a.key, character = _a.character, onChange = _a.onChange, name = _a.name, values = _a.values, required = _a.required, type = _a.type, _b = _a.cols, cols = _b === void 0 ? 1 : _b, tooltip = _a.tooltip, maxLength = _a.maxLength, placeholder = _a.placeholder, showPreview = _a.showPreview, defaultValue = _a.defaultValue;
    var input;
    if (key === "battle_status") {
        input = (_jsx(BattleStatusInput, { data: character, name: "\uC2A4\uD14C\uC774\uD130\uC2A4", fieldKey: "status", onChange: onChange }));
        name = "스테이터스";
        tooltip =
            "캐릭터의 특성을 나타내는 값입니다. 실제 전투에 사용되는 능력치에 미미한 영향을 미칩니다.";
    }
    switch (type) {
        case "text":
        case "number":
            input = (_jsx("input", { type: "text", id: key, name: key, value: character[key] || defaultValue || "", onChange: onChange, required: required, className: "form-control", maxLength: maxLength, placeholder: placeholder }));
            break;
        case "image":
            input = (_jsx(ImageInput, { showImage: showPreview, value: character[key], name: name, fieldKey: key, onChange: onChange }));
            break;
        case "textarea":
            input = (_jsx("textarea", { id: key, name: key, value: character[key] || defaultValue || "", onChange: onChange, className: "form-control", placeholder: placeholder }));
            break;
        case "select":
            input = (_jsxs("select", __assign({ className: "form-select", value: character[key] || "", required: required, name: key, onChange: onChange }, { children: [_jsx("option", __assign({ value: "" }, { children: "\uC120\uD0DD" })), values.map(function (_a) {
                        var _b = __read(_a, 2), key = _b[0], val = _b[1];
                        return (_jsx("option", __assign({ value: key }, { children: val }), key));
                    })] })));
            break;
    }
    return (_jsxs("div", __assign({ className: "col-".concat(Math.ceil(12 / cols), " input-box") }, { children: [_jsx(OverlayTrigger, __assign({ placement: "bottom", overlay: tooltip ? _jsx(Tooltip, __assign({ id: "button-tooltip-2" }, { children: tooltip })) : _jsx(_Fragment, {}) }, { children: function (_a) {
                    var ref = _a.ref, triggerHandler = __rest(_a, ["ref"]);
                    return (_jsxs("label", __assign({}, triggerHandler, { className: "form-label", htmlFor: key }, { children: [name, tooltip ? (_jsx("span", __assign({ ref: ref }, { children: _jsx(InfoIcon, {}) }))) : (_jsx(_Fragment, {}))] })));
                } })), input] }), key));
}
export function CharacterForm(_a) {
    var onChange = _a.onChange, character = _a.character, showPreview = _a.showPreview;
    var characterFields = [
        ["name", "nickname", "title", "eng_name"],
        ["body", "race", "gender", "age"],
        ["job", "job2"],
        "battle_status",
        "quote",
        ["thumbnail_img", "body_img"],
        ["battle_img", "cutscene_img"],
        ["outfit", "personality"],
        ["society", "description"],
        "secret",
        "running",
    ];
    return (_jsx("div", __assign({ className: "character-form" }, { children: characterFields.map(function (keys, i) {
            var cols;
            if (Array.isArray(keys)) {
                cols = keys.map(function (key) {
                    var _a = (Character.dataFields[key] || {}), values = _a.values, fieldOption = __rest(_a, ["values"]);
                    return makeInput(__assign(__assign({}, fieldOption), { values: values, showPreview: showPreview, key: key, character: character, onChange: onChange, cols: keys.length }));
                });
            }
            else {
                var fieldOption = Character.dataFields[keys];
                cols = makeInput(__assign(__assign({}, fieldOption), { character: character, showPreview: showPreview, key: keys, onChange: onChange }));
            }
            return (_jsx("div", __assign({ className: "row g-2 mb-2" }, { children: cols }), i));
        }) })));
}
export default connect()(Form);
