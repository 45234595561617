var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent } from "../../structure";
import "./eventScriptWindow.scss";
import NotesIcon from "@mui/icons-material/Notes";
import CloseIcon from "@mui/icons-material/Close";
import LetterAnimation from "./LetterAnimation";
import { separateMacro } from "./macro";
var EventScriptWindow = /** @class */ (function (_super) {
    __extends(EventScriptWindow, _super);
    function EventScriptWindow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            finishEventQueue: [],
            eventStack: [],
            stackPointer: [],
            characterId: undefined,
            speakerId: undefined,
            portrait: "",
            script: "",
            comment: undefined,
            finishedScript: false,
            skipScript: false,
            logs: [],
            showLog: false,
        };
        _this.keyboardHandler = function (e) {
            var keys = [" ", "Spacebar", 32];
            if (!keys.includes(e.key))
                return;
            _this.nextScript();
        };
        _this.runResult = function (result) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var currentEvent, stackPointer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(result === "continue")) return [3 /*break*/, 1];
                        this.increasePointer();
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(result === "end")) return [3 /*break*/, 2];
                        currentEvent = this.getCurrentEvent();
                        stackPointer = __spreadArray([], __read(this.state.stackPointer), false);
                        stackPointer.splice(-1, 1, currentEvent.script.length);
                        this.setState({ stackPointer: stackPointer });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.callEvent(result)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.setScript();
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.nextScript = function () {
            var _a = _this.state, finished_script = _a.finishedScript, skip_script = _a.skipScript, comment = _a.comment;
            if ((!finished_script && !skip_script) || comment) {
                _this.setState({ skipScript: true });
                return;
            }
            _this.increasePointer();
            _this.setScript();
        };
        return _this;
    }
    EventScriptWindow.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callEvent(this.props.eventId)];
                    case 1:
                        _a.sent();
                        this.setScript();
                        window.addEventListener("keydown", this.keyboardHandler);
                        return [2 /*return*/];
                }
            });
        });
    };
    EventScriptWindow.prototype.componentWillUnmount = function () {
        window.removeEventListener("keydown", this.keyboardHandler);
    };
    EventScriptWindow.prototype.callEvent = function (event_id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var event;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/explore/event",
                            params: {
                                test: this.props.test ? 1 : 0,
                                event_id: event_id,
                                root_event_id: (_a = this.state.eventStack[0]) === null || _a === void 0 ? void 0 : _a.id,
                            },
                            method: "post",
                        })];
                    case 1:
                        event = (_b.sent()).event;
                        if (event) {
                            this.setState({
                                eventStack: __spreadArray(__spreadArray([], __read(this.state.eventStack), false), [
                                    __assign(__assign({}, event), { script: JSON.parse(event.script) || [] }),
                                ], false),
                                stackPointer: __spreadArray(__spreadArray([], __read(this.state.stackPointer), false), [0], false),
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EventScriptWindow.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.state, characterId = _b.characterId, skipScript = _b.skipScript, showLog = _b.showLog, script = _b.script, portrait = _b.portrait, comment = _b.comment, speakerId = _b.speakerId;
        var currentEvent = this.getCurrentEvent();
        if (!currentEvent) {
            return _jsx(_Fragment, {});
        }
        var currentScript = currentEvent.script[this.getCurrentPointer()] || {};
        var character = this.props.characters.find(function (_a) {
            var id = _a.id;
            return id === Number(characterId);
        });
        var speaker = this.props.characters.find(function (_a) {
            var id = _a.id;
            return id === Number(speakerId);
        });
        return (_jsxs("div", __assign({ className: "event-script-window" }, { children: [character && (_jsx("div", __assign({ className: "character ".concat(characterId !== currentScript.character_id ? "inactive" : "") }, { children: portrait ? (_jsx("img", { src: character[portrait], alt: "portrait" })) : (_jsx("img", { src: character.idle, alt: "portrait" })) }))), _jsxs("div", __assign({ className: "script", onClick: this.nextScript }, { children: [speaker && (_jsxs("div", __assign({ className: "title" }, { children: [speaker === null || speaker === void 0 ? void 0 : speaker.name, _jsx("img", { src: "/static/img/script_bar.png" })] }))), _jsx("div", { className: "script-bg" }), _jsx("div", __assign({ className: "script-contents" }, { children: _jsx("div", __assign({ className: "text" }, { children: _jsx(LetterAnimation, { text: script || "", skip: skipScript, onFinish: function () { return _this.setState({ finishedScript: true }); } }) })) }))] })), comment ? (_jsx("div", __assign({ className: "selection-modal" }, { children: (_a = comment.comments) === null || _a === void 0 ? void 0 : _a.map(function (_a, i) {
                        var text = _a.text, result = _a.result;
                        return (_jsx("div", __assign({ onClick: _this.runResult(result), className: "selection" }, { children: text }), i));
                    }) }))) : null, _jsx("div", __assign({ className: "script-tools" }, { children: _jsx("div", __assign({ className: "tool-btn btn-log" }, { children: _jsx(NotesIcon, { onClick: function () { return _this.setState({ showLog: true }); } }) })) })), showLog && (_jsxs("div", __assign({ className: "log-modal" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [_jsx("h5", { children: "\uC2A4\uD06C\uB9BD\uD2B8" }), _jsx(CloseIcon, { className: "close", onClick: function () { return _this.setState({ showLog: false }); } })] })), _jsx("div", __assign({ className: "logs" }, { children: this.state.logs.map(function (_a, i) {
                                var character_id = _a.character_id, text = _a.text;
                                var character = _this.props.characters.find(function (_a) {
                                    var id = _a.id;
                                    return id === character_id;
                                });
                                var name = (character === null || character === void 0 ? void 0 : character.name) || "내레이션";
                                return (_jsxs("div", __assign({ className: "log" }, { children: [_jsx("div", __assign({ className: "title" }, { children: name })), _jsx("div", __assign({ className: "text" }, { children: text }))] }), i));
                            }) }))] })))] })));
    };
    EventScriptWindow.prototype.getCurrentEvent = function () {
        return this.state.eventStack[this.state.eventStack.length - 1];
    };
    EventScriptWindow.prototype.getCurrentPointer = function () {
        return this.state.stackPointer[this.state.stackPointer.length - 1];
    };
    EventScriptWindow.prototype.increasePointer = function () {
        var current = this.getCurrentPointer();
        var stackPointer = __spreadArray([], __read(this.state.stackPointer), false);
        stackPointer.splice(-1, 1, current + 1);
        this.setState({ stackPointer: stackPointer });
    };
    EventScriptWindow.prototype.popEventStack = function () {
        var eventStack = __spreadArray([], __read(this.state.eventStack), false);
        var stackPointer = __spreadArray([], __read(this.state.stackPointer), false);
        var finishEventQueue = __spreadArray([], __read(this.state.finishEventQueue), false);
        var lastEvent = eventStack.pop();
        if (lastEvent) {
            finishEventQueue.push(lastEvent.id);
        }
        stackPointer.pop();
        if (eventStack.length === 0) {
            this.props.onFinish(finishEventQueue);
            return;
        }
        var lastPointer = stackPointer.pop();
        stackPointer.push(lastPointer + 1);
        this.setState({
            finishEventQueue: finishEventQueue,
            eventStack: eventStack,
            stackPointer: stackPointer,
        });
        this.setScript();
    };
    EventScriptWindow.prototype.setScript = function () {
        var _this = this;
        this.forceUpdate(function () {
            var _a = _this.state, characterId = _a.characterId, portrait = _a.portrait, script = _a.script, logs = _a.logs, speakerId = _a.speakerId;
            var event = _this.getCurrentEvent();
            var pointer = _this.getCurrentPointer();
            var nextPortrait = portrait, nextCharacterId = characterId;
            if (event.script.length <= pointer) {
                _this.popEventStack();
                return;
            }
            var nextScript = event.script[pointer];
            var _b = separateMacro(nextScript === null || nextScript === void 0 ? void 0 : nextScript.text), macro = _b.macro, text = _b.text;
            var isComment = nextScript.comments && !nextScript.text;
            if (macro === "appear") {
                nextCharacterId = nextScript.character_id;
            }
            if (macro === "disappear") {
                nextCharacterId = undefined;
            }
            if (["sad", "smile", "upset", "surprise"].indexOf(macro) >= 0) {
                nextPortrait = macro;
            }
            if (macro === "idle") {
                nextPortrait = "";
            }
            _this.setState({
                skipScript: false,
                finishedScript: false,
                portrait: nextPortrait,
                characterId: nextCharacterId,
                speakerId: isComment ? speakerId : nextScript.character_id,
                script: isComment ? script : text,
                comment: isComment ? nextScript : undefined,
                logs: __spreadArray(__spreadArray([], __read(logs), false), [nextScript], false),
            });
            if (!isComment && !text) {
                _this.forceUpdate(function () {
                    _this.increasePointer();
                    _this.setScript();
                });
            }
        });
    };
    return EventScriptWindow;
}(ReactComponent));
export default EventScriptWindow;
