var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
var scrollTop = function () {
    window.scrollTo(0, 0);
};
export default function Title(_a) {
    var text = _a.text, links = _a.links;
    return (_jsxs("div", __assign({ className: "page-title" }, { children: [_jsx("div", { className: "title-icon" }), _jsx("div", __assign({ className: "title-text" }, { children: text })), _jsx("div", __assign({ className: "title-links" }, { children: links.map(function (_a, i) {
                    var text = _a.text, link = _a.link;
                    return (_jsx("div", __assign({ className: "link" }, { children: _jsx(Link, __assign({ to: link, onClick: scrollTop }, { children: text })) }), i));
                }) }))] })));
}
