var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, Route, Switch } from "react-router-dom";
import { SiteComponent, connect } from "../structure";
import Main from "./Main";
import Form from "./Form";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./application.scss";
import { Character } from "@iter-acillba/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
var DEFAULT_CHARACTER = {
    strong: 1,
    health: 1,
    int: 1,
    dex: 1,
    will: 1,
};
var Application = /** @class */ (function (_super) {
    __extends(Application, _super);
    function Application() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            character: DEFAULT_CHARACTER,
            category: undefined,
            character_save: true,
            loading: true,
        };
        _this.updateCharacter = function () {
            _this.updateData();
        };
        _this.onChangeForm = function (e) {
            _this.setState({ character_save: false });
            _this.inputState("character")(e);
        };
        return _this;
    }
    Application.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var category;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/character/applicationCategory",
                            method: "get",
                        })];
                    case 1:
                        category = _a.sent();
                        this.setState({ category: category, loading: false });
                        if (!category) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.updateData()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Application.prototype.updateData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var character;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callAPI({
                            url: "/api/character/application",
                            method: "get",
                        }, true, true)];
                    case 1:
                        character = _a.sent();
                        this.setState({
                            character_save: true,
                            character: character || DEFAULT_CHARACTER,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Application.prototype.render = function () {
        var _this = this;
        var _a = this.props.state.root, user = _a.user, config = _a.config;
        var _b = this.state, character = _b.character, category = _b.category, character_save = _b.character_save, loading = _b.loading;
        if (!user.id) {
            return (_jsxs("div", __assign({ className: "page-404" }, { children: ["\uB85C\uADF8\uC778 \uD6C4 \uC811\uADFC \uAC00\uB2A5\uD569\uB2C8\uB2E4.", _jsx("br", {}), _jsx("br", {}), _jsx(Link, __assign({ to: "/login" }, { children: "\uB85C\uADF8\uC778 \uD398\uC774\uC9C0\uB85C" }))] })));
        }
        if (loading) {
            return _jsx(_Fragment, {});
        }
        if (!category || !config.show_application) {
            return (_jsx("div", __assign({ className: "page-404" }, { children: "\uC2E0\uCCAD \uD398\uC774\uC9C0\uB97C \uC5F4\uB78C\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uAD00\uB9AC\uC790\uC5D0\uAC8C \uBB38\uC758\uD558\uC138\uC694." })));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "page-title" }, { children: [_jsx("div", { className: "title-icon" }), _jsx("div", __assign({ className: "title-text" }, { children: "APPLICATION" }))] })), _jsxs("div", __assign({ className: "application" }, { children: [_jsx("div", __assign({ className: "lnb-cover" }, { children: _jsxs("div", __assign({ className: "lnb-container" }, { children: [_jsx(Link, __assign({ to: "/application" }, { children: _jsx("div", __assign({ className: "lnb" }, { children: "\uACF5\uC9C0&\uD604\uD669" })) })), _jsx(Link, __assign({ to: "/application/form" }, { children: _jsxs("div", __assign({ className: "lnb" }, { children: ["\uB0B4 \uC2E0\uCCAD\uC11C", character_save ? _jsx(_Fragment, {}) : _jsx("div", __assign({ className: "badge" }, { children: "\uBBF8\uC800\uC7A5" }))] })) })), character.state === Character.State.SUCCESS ? (_jsx("a", __assign({ href: "/preview", target: "_blank" }, { children: _jsxs("div", __assign({ className: "lnb" }, { children: ["\uBBF8\uB9AC\uBCF4\uAE30", _jsx(OpenInNewIcon, {})] })) }))) : (_jsx(OverlayTrigger, __assign({ placement: "bottom", overlay: _jsx(Tooltip, { children: "\uC2E0\uCCAD\uC11C \uC81C\uCD9C\uD6C4 \uD655\uC778 \uAC00\uB2A5\uD569\uB2C8\uB2E4." }) }, { children: _jsxs("div", __assign({ className: "lnb" }, { children: ["\uBBF8\uB9AC\uBCF4\uAE30", _jsx(OpenInNewIcon, {})] })) })))] })) })), _jsx("div", __assign({ className: "contents" }, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/application/form", render: function (props) { return (_jsx(Form, __assign({}, props, { onChangeData: _this.onChangeForm, character: character, config: config, updateCharacter: _this.updateCharacter }))); } }), _jsx(Route, { path: "/application", render: function (props) { return (_jsx(Main, __assign({}, props, { updateCharacter: _this.updateCharacter, character: character, config: config }))); } })] }) }))] }))] }));
    };
    return Application;
}(SiteComponent));
export default connect()(Application);
