var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { SiteComponent, connect } from "../structure";
import "./character.scss";
import CharacterView from "./CharacterView";
import CharacterList from "./CharacterList";
import { showNavigation } from "../store/actions";
import { Spinner } from "../components/Spinner";
import Title from "../components/Title";
var CharacterPage = /** @class */ (function (_super) {
    __extends(CharacterPage, _super);
    function CharacterPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            character: undefined,
            selected: 0,
            imageProgress: 0,
            loading: true,
        };
        _this.selectCharacter = function (id) {
            _this.setState({ selected: id });
        };
        _this.deSelectCharacter = function () {
            _this.setState({ selected: 0 });
        };
        return _this;
    }
    CharacterPage.prototype.componentDidMount = function () {
        this.dispatch(showNavigation(false));
        this.loadImage();
    };
    CharacterPage.prototype.loadImage = function () {
        var _this = this;
        var images = this.props.state.root.characters
            .map(function (_a) {
            var thumbnail_img = _a.thumbnail_img, body_img = _a.body_img;
            return [thumbnail_img, body_img];
        })
            .flat();
        var imageLength = images.length;
        var imageLoaded = 0;
        if (imageLength === 0) {
            this.setState({ loading: false });
            return;
        }
        var loadImage = function () {
            imageLoaded++;
            _this.setState({ imageProgress: Math.round(imageLoaded / imageLength) });
            if (imageLength === imageLoaded) {
                _this.setState({ loading: false });
            }
        };
        images.forEach(function (src) {
            var image = new Image();
            image.src = src;
            image.onload = loadImage;
            image.onerror = loadImage;
        });
    };
    CharacterPage.prototype.render = function () {
        var _this = this;
        return (_jsxs("div", { children: [_jsx(Title, { text: "CHARACTER", links: [{ text: "LIST", link: "/character" }] }), _jsx(Spinner, __assign({ loading: this.state.loading, progress: this.state.imageProgress }, { children: _jsx("div", __assign({ className: "character-container" }, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/character/:id", exact: true, render: function (props) { return (_jsx(CharacterView, __assign({}, props, { character: _this.getCharacter(), select: _this.selectCharacter }))); } }), _jsx(Route, { path: "/character", render: function (props) { return (_jsx(CharacterList, __assign({}, props, { select: _this.selectCharacter, deselect: _this.deSelectCharacter, character: _this.getCharacter(), characters: _this.props.state.root.characters }))); } })] }) })) }))] }));
    };
    CharacterPage.prototype.getCharacter = function () {
        var _this = this;
        return this.props.state.root.characters.find(function (c) { return +c.id === +_this.state.selected; });
    };
    return CharacterPage;
}(SiteComponent));
export default connect()(CharacterPage);
