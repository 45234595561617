var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
export default function Targeting(_a) {
    var type = _a.type, isSelected = _a.isSelected, couldSelect = _a.couldSelect, selectTarget = _a.selectTarget;
    return couldSelect ? (_jsx("div", __assign({ className: "targeting ".concat(type, " ").concat(isSelected ? "active" : "") }, { children: _jsxs("div", __assign({ className: "circle", onClick: selectTarget }, { children: [_jsx("div", { className: "circle-inner" }), _jsx("div", { className: "circle-outer" })] })) }))) : (_jsx(_Fragment, {}));
}
