var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
export function ChatList(_a) {
    var chats = _a.chats, onSubmit = _a.onSubmit, focus = _a.focus;
    var _b = __read(useState(""), 2), message = _b[0], setMessage = _b[1];
    var _c = __read(useState(false), 2), scrollUp = _c[0], setScrollUp = _c[1];
    var scroller = useRef(null);
    var scrollFn = useCallback(debounce(function () {
        var el = scroller.current;
        if (!el)
            return;
        var scrollPos = el.scrollTop + el.clientHeight;
        var isScrollUp = scrollPos < el.scrollHeight;
        setScrollUp(isScrollUp);
    }, 32), []);
    useEffect(function () {
        if (scrollUp || !scroller.current)
            return;
        var el = scroller.current;
        scroller.current.scrollTo(0, el.scrollTop + el.clientHeight);
    }, [chats.length]);
    return (_jsxs("div", __assign({ className: "chat" }, { children: [_jsx("div", __assign({ className: "chat-container" }, { children: _jsx("div", __assign({ className: "scroller", ref: scroller, onScroll: scrollFn }, { children: _jsx("ul", { children: chats.map(function (_a) {
                            var characterName = _a.characterName, message = _a.message, timestamp = _a.timestamp;
                            return (_jsxs("li", { children: [_jsx("div", __assign({ className: "name" }, { children: characterName })), _jsx("div", __assign({ className: "text" }, { children: message }))] }, timestamp));
                        }) }) })) })), _jsx("div", __assign({ className: "chat-input" }, { children: _jsxs("form", __assign({ onSubmit: function (e) {
                        e.preventDefault();
                        onSubmit(message);
                        setMessage("");
                    } }, { children: [_jsx("input", { onFocus: function () { return focus(true); }, onBlur: function () { return focus(false); }, value: message, onChange: function (e) {
                                setMessage(e.currentTarget.value);
                            }, type: "text", maxLength: 100 }), _jsx("button", __assign({ type: "submit" }, { children: "\uBCF4\uB0B4\uAE30" }))] })) }))] })));
}
