var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
export default function Effect(_a) {
    var type = _a.type, action = _a.action, onAnimationEnd = _a.onAnimationEnd, effects = _a.effects;
    var _b = __read(useState(-1), 2), time = _b[0], setTime = _b[1];
    var _c = __read(useState(undefined), 2), timer = _c[0], setTimer = _c[1];
    var _d = effects.find(function (_a) {
        var id = _a.id;
        return id === action.data.effectId;
    }), frame_rate = _d.frame_rate, total_frame = _d.total_frame, cols = _d.cols, enemy_width = _d.enemy_width, enemy_height = _d.enemy_height, ally_height = _d.ally_height, ally_width = _d.ally_width, sprite = _d.sprite, sound = _d.sound;
    var callback = useRef(function () { });
    var audio = useMemo(function () { return new Audio(sound); }, [sound]);
    function updateTime() {
        if (time < total_frame - 1) {
            setTime(time + 1);
        }
        else {
            clearInterval(timer);
            setTime(-1);
            onAnimationEnd();
        }
    }
    useEffect(function () {
        callback.current = updateTime;
    });
    useEffect(function () {
        clearInterval(timer);
        if (!action)
            return;
        var interval = setInterval(function () {
            callback.current();
        }, Math.floor(1000 / frame_rate));
        setTime(0);
        setTimer(interval);
        if (sound) {
            audio.currentTime = 0;
            audio.play();
        }
    }, [action]);
    var _e = type === "ally"
        ? { width: ally_width, height: ally_height }
        : { width: enemy_width, height: enemy_height }, width = _e.width, height = _e.height;
    var style = {
        backgroundImage: "url('".concat(sprite, "')"),
        backgroundSize: "".concat(width * cols, "px"),
        backgroundPosition: "-".concat((time % cols) * width, "px -").concat(Math.floor(time / cols) * height, "px"),
        width: "".concat(width, "px"),
        height: "".concat(height, "px"),
    };
    return time === -1 ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "effect ".concat(type) }, { children: _jsx("div", { className: "effect-sprite", style: style }) })));
}
