var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
export default function NeedLogin() {
    return (_jsxs("div", __assign({ className: "page-404" }, { children: ["\uB85C\uADF8\uC778 \uD6C4 \uC811\uADFC \uAC00\uB2A5\uD569\uB2C8\uB2E4.", _jsx("br", {}), _jsx("br", {}), _jsx(Link, __assign({ to: "/login" }, { children: "\uB85C\uADF8\uC778 \uD398\uC774\uC9C0\uB85C" }))] })));
}
