var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createAction, configureStore, createReducer, combineReducers, } from "@reduxjs/toolkit";
import axios from "axios";
import { connect } from "react-redux";
import { combineEpics, createEpicMiddleware, } from "redux-observable";
import { catchError, concat, from, of } from "rxjs";
import { map } from "rxjs/operators";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export var toast = createAction("toast");
var toastReducer = createReducer({ data: {} }, function (builder) {
    builder.addCase(toast, function (state, action) {
        state.data = action.payload;
    });
});
export function createStore(reducers, epics, persist) {
    var epicMiddleware = createEpicMiddleware();
    var reducer = combineReducers(__assign({ toast: toastReducer }, reducers));
    if (persist) {
        var persistConfig = {
            key: persist,
            storage: storage,
        };
        reducer = persistReducer(persistConfig, reducer);
    }
    var store = configureStore({
        reducer: reducer,
        middleware: [epicMiddleware],
    });
    if (epics) {
        var rootEpic = function (action$, state$, dep) {
            return combineEpics.apply(void 0, __spreadArray([], __read(epics), false))(action$, state$, dep).pipe(catchError(function (e, caught) {
                if (e.notice) {
                    return concat(of(toast(e.notice)), caught);
                }
                console.error(e);
                return caught;
            }));
        };
        epicMiddleware.run(rootEpic);
    }
    return store;
}
export function connectState() {
    return connect(function (state) { return ({ state: state }); });
}
var RequestException = /** @class */ (function (_super) {
    __extends(RequestException, _super);
    function RequestException(notice) {
        var _this = _super.call(this, "요청에 실패했습니다.") || this;
        _this.notice = notice;
        return _this;
    }
    return RequestException;
}(Error));
export { RequestException };
export function callAPI(axiosConfig) {
    return from(axios(axiosConfig)).pipe(map(function (_a) {
        var data = _a.data;
        var success = data.success, notice = data.notice;
        if (!success && notice) {
            throw new RequestException(notice);
        }
        return data;
    }));
}
