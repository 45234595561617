var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, NavLink } from "react-router-dom";
import { connect } from "./structure";
import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PlayArrow from "@mui/icons-material/PlayArrow";
import PersonIcon from "@mui/icons-material/Person";
import StopIcon from "@mui/icons-material/Stop";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { openPopup, playBgm, setCharacter, showMessage, showNavigation, showSetting, stopBgm, } from "./store/actions";
var CHECK_MAIL_INTERVAL = 1000 * 60 * 5;
function Navigation() {
    var dispatch = useDispatch();
    var open = useSelector(function (state) { return state.root.showNavigation; });
    var character = useSelector(function (state) { return state.root.character; });
    var loginId = useSelector(function (state) { return state.root.user.id; });
    var myLink = !loginId
        ? "/login"
        : !(character === null || character === void 0 ? void 0 : character.id)
            ? "/application"
            : "/character/".concat(character.id);
    var _a = __read(useState(false), 2), hasNewMail = _a[0], setHasNewMail = _a[1];
    var needLogin = function (fn) { return function () {
        if (!loginId)
            dispatch(openPopup({ message: "로그인이 필요합니다.", type: "alert" }));
        else
            fn();
    }; };
    var checkMessage = function () {
        axios({
            url: "/api/message/check",
            method: "get",
        }).then(function (_a) {
            var data = _a.data.data;
            setHasNewMail(data);
        });
    };
    var mailBox = function () {
        setHasNewMail(false);
        needLogin(function () { return dispatch(showMessage((character === null || character === void 0 ? void 0 : character.id) || 0)); })();
    };
    useEffect(function () {
        if (!loginId)
            return;
        var interval;
        axios({
            url: "/api/character/rep/".concat(loginId),
            method: "get",
        }).then(function (_a) {
            var data = _a.data.data;
            dispatch(setCharacter(data));
            if (data) {
                checkMessage();
                interval = setInterval(checkMessage, CHECK_MAIL_INTERVAL);
            }
        });
        return function () {
            clearTimeout(interval);
        };
    }, [loginId]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "tools" }, { children: [_jsx(Link, __assign({ to: myLink }, { children: _jsx(PersonIcon, {}) })), hasNewMail ? (_jsx(MarkEmailUnreadIcon, { onClick: mailBox })) : (_jsx(EmailIcon, { onClick: mailBox })), _jsx(SettingsIcon, { onClick: needLogin(function () { return dispatch(showSetting(true)); }) }), _jsx(PlayArrow, { onClick: function () { return dispatch(playBgm()); } }), _jsx(StopIcon, { onClick: function () { return dispatch(stopBgm()); } })] })), _jsx("div", __assign({ className: "nav-btn ".concat(open ? "hide" : "open"), onClick: function () { return dispatch(showNavigation(!open)); } }, { children: _jsx(KeyboardArrowUpIcon, {}) })), _jsxs("nav", __assign({ className: "navigation ".concat(open ? "" : "close") }, { children: [_jsx("div", __assign({ className: "nav-item" }, { children: _jsx(NavLink, __assign({ to: "/document" }, { children: _jsx("img", { src: "/static/img/nav_doc.png", alt: "document" }) })) })), _jsx("div", __assign({ className: "nav-item" }, { children: _jsx(NavLink, __assign({ to: "/character" }, { children: _jsx("img", { src: "/static/img/nav_char.png", alt: "character" }) })) })), _jsx("div", __assign({ className: "nav-item" }, { children: _jsx(NavLink, __assign({ exact: true, to: "/" }, { children: _jsx("img", { src: "/static/img/nav_main.png", alt: "main" }) })) })), _jsx("div", __assign({ className: "nav-item" }, { children: _jsx(NavLink, __assign({ to: "/journey" }, { children: _jsx("img", { src: "/static/img/nav_jour.png", alt: "journey" }) })) })), _jsx("div", __assign({ className: "nav-item" }, { children: _jsx(NavLink, __assign({ to: "/shop" }, { children: _jsx("img", { src: "/static/img/nav_shop.png", alt: "shop" }) })) }))] }))] }));
}
export default connect()(Navigation);
