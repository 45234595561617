import { DBRecord } from "./common";

export enum BuffType {
  DAMAGE = "damage",
  HEAL = "heal",
  RECOVER_SP = "recoverSp",
  ENHANCE = "enhance",
  TAUNT = "taunt",
  MEZE = "meze",
  INVINCIBLE = "invincible",
  CANCEL = "cancel",
  REFLECT = "reflect",
  INCHANT = "inchant",
  SHIELD = "shield",
  MASSIVABLE = "massivable",
  CURSE = "curse",
}

export type SkillBuffValueType =
  | "skill"
  | "buff"
  | "string"
  | "buff_type"
  | "skill_type"
  | "number"
  | "base_status"
  | "boolean"
  | "status";
export type SkillBuffMeta = {
  name: string;
  description: string;
  valueDescription: string[];
  valueArray: SkillBuffValueType[];
  optionValues?: number[];
};

export const buffTypeMeta: {
  [key in BuffType]: SkillBuffMeta;
} = {
  [BuffType.DAMAGE]: {
    name: "대미지",
    description: "턴 종료시 버프 보유자에게 대미지를 가함",
    valueDescription: [
      "버프 사용자의 기반 스테이터스",
      "스택당 대미지 배율(%)",
    ],
    valueArray: ["base_status", "number"],
  },
  [BuffType.HEAL]: {
    name: "힐",
    description: "턴 종료시 버프 보유자를 회복함",
    valueDescription: ["버프 사용자의 기반 스테이터스", "스택당 힐 배율(%)"],
    valueArray: ["base_status", "number"],
  },
  [BuffType.ENHANCE]: {
    name: "스탯 조정",
    description: "버프 보유자의 스탯 변동",
    valueDescription: [
      "변동 스테이터스",
      "버프 사용자의 기반 스테이터스",
      "스택당 변동 배율(%)",
    ],
    valueArray: ["status", "base_status", "number"],
  },
  [BuffType.TAUNT]: {
    name: "도발",
    description: "버프 보유자의 공격 대상을 고정",
    valueDescription: [],
    valueArray: [],
  },
  [BuffType.RECOVER_SP]: {
    name: "SP회복",
    description: "턴 종료시 버프 보유자의 SP를 회복함.",
    valueDescription: ["sp 회복량(절대값)"],
    valueArray: ["number"],
  },
  [BuffType.MEZE]: {
    name: "군중제어",
    description: "버프 보유자를 행동할 수 없게 함",
    valueDescription: [],
    valueArray: [],
  },
  [BuffType.INVINCIBLE]: {
    name: "불굴",
    description: "HP가 0이 되는 일격을 받아도 쓰러지지 않음.",
    valueDescription: ["HP 유지량"],
    valueArray: ["number"],
  },
  [BuffType.REFLECT]: {
    name: "반사",
    description:
      "공격받았을 때 대미지의 일부 반사. 방어력 적용 이전 대미지로 계산.",
    valueDescription: [
      "반사 배율(%)",
      "반사 한계 기반 스탯",
      "반사 한계 스탯 배율",
    ],
    valueArray: ["number", "status", "number"],
  },
  [BuffType.INCHANT]: {
    name: "인챈트",
    description: "보유자가 스킬 사용시마다 스킬 대상에게 버프 부여",
    valueDescription: ["적용 대상(ally, enemy, all)", "부여할 버프 ID"],
    valueArray: ["string", "buff"],
  },
  [BuffType.MASSIVABLE]: {
    name: "광역화",
    description: "보유자의 스킬이 광역 스킬로 변경됨",
    valueDescription: [],
    valueArray: [],
  },
  [BuffType.SHIELD]: {
    name: "보호막",
    description:
      "보유자는 보호막을 획득, 보호막이 여러 개일 경우 모든 보호막이 동시에 영향받음",
    valueDescription: [
      "버프 사용자의 기반 스테이터스",
      "스택당 보호막 배율(%)",
    ],
    valueArray: ["status", "number"],
  },
  [BuffType.CANCEL]: {
    name: "무효화",
    description: "자신에게 가해지는 대미지를 무효화",
    valueDescription: [],
    valueArray: [],
  },
  [BuffType.CURSE]: {
    name: "저주",
    description: "버프가 지속시간 종료로 해제될 때 보유자를 사망시킴",
    valueDescription: [],
    valueArray: [],
  },
};

export enum SkillType {
  COMBINATION = "combination",
  DAMAGE = "damage",
  HEAL = "heal",
  BUFF = "buff",
  RECOVER_SP = "recoverSp",
  RESURRECTION = "resurrection",
  REMOVE_BUFF = "removeBuff",
}

export const skillTypeMeta: {
  [key in SkillType]: SkillBuffMeta;
} = {
  [SkillType.COMBINATION]: {
    name: "조합",
    description: "기존 스킬을 최대 3개까지 조합",
    valueDescription: ["조합할 스킬", "조합할 스킬", "[옵션] 조합할 스킬"],
    optionValues: [2],
    valueArray: ["skill", "skill", "skill"],
  },
  [SkillType.DAMAGE]: {
    name: "대미지",
    description: "대상에게 대미지를 가함",
    valueDescription: ["사용자의 기반 스테이터스", "대미지 배율(%)"],
    valueArray: ["base_status", "number"],
  },
  [SkillType.HEAL]: {
    name: "힐",
    description: "대상의 HP를 회복",
    valueDescription: ["사용자의 기반 스테이터스", "힐 배율(%)"],
    valueArray: ["base_status", "number"],
  },
  [SkillType.BUFF]: {
    name: "버프",
    description: "대상에게 버프를 부여(최대 3개)",
    valueDescription: [
      "부여할 버프ID",
      "[옵션] 부여할 버프ID",
      "[옵션] 부여할 버프ID",
    ],
    optionValues: [1, 2],
    valueArray: ["buff", "buff", "buff"],
  },
  [SkillType.RESURRECTION]: {
    name: "부활",
    description: "대상을 부활시킴",
    valueDescription: ["사용자의 기반 스테이터스", "회복할 HP 배율(%)"],
    valueArray: ["status", "number"],
  },
  [SkillType.REMOVE_BUFF]: {
    name: "버프 제거",
    description: "버프 제거",
    valueDescription: ["제거할 버프 그룹(good, bad, neutral, all)"],
    valueArray: ["string"],
  },
  [SkillType.RECOVER_SP]: {
    name: "sp 회복",
    description: "대상의 sp회복",
    valueDescription: ["sp 회복량(절대값)"],
    valueArray: ["number"],
  },
};

export type Buff<T = (string | number)[]> = {
  name: string;
  type: BuffType;
  values: T;
  stackable: number;
  expire_type: "round" | "count" | "ever";
  expire_value: number;
  stack_refresh: 0 | 1;
  effect_id: number;
  group: "good" | "bad" | "neutral";
  description: string;
  icon_img: string;
  hidden: 0 | 1;
} & DBRecord;

export const skillSlotLevel = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
};

export const skillSlotName = {
  1: "일반공격",
  2: "패시브",
  3: "액티브1",
  4: "액티브2",
  5: "액티브3",
  6: "액티브4",
};

export type Skill<T = SkillType, U = (string | number)[]> = DBRecord & {
  name: string;
  description: string;
  passive: 0 | 1;
  type: T;
  slot: number;
  values: U;
  sp: number;
  cooldown: number;
  target_type: "ally" | "enemy" | "all" | "self";
  condition_type: "random" | "free" | "min_hp" | "all";
  target_count: number;
  effect_id: number;
  enable_taunt: 0 | 1;
  icon_img: string;
  is_default: 0 | 1;
  job: string;
  cutin: 0 | 1;
};

export type CharacterSkill = {
  activated: 0 | 1;
  character_id: number;
  skill_id: number;
} & Skill;

export type SkillEffect = DBRecord & {
  name: string;
  frame_rate: number;
  total_frame: number;
  sound: string;
  cols: number;
  sprite: string;
  enemy_width: number;
  enemy_height: number;
  ally_width: number;
  ally_height: number;
};
