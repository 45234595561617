import { Status } from "./battle";
import { DBRecord } from "./common";
import { Skill } from "./skill";

export enum ItemType {
  CONSUME = "consume",
  ARMOR = "armor",
  WEAPON = "weapon",
  ACCESSORY = "accessory",
  INGREDIANT = "ingrediant",
  QUEST = "quest",
  EXCHANGE = "exchange",
  SKILLBOOK = "skillbook",
  GACHA = "gacha",
  OUTFIT = "outfit",
  OTHER = "other",
}

export enum InventoryType {
  ALL = "all",
  EQUIP = "eqip",
  CONSUME = "consume",
  INGREDIANT = "ingrediant",
  QUEST = "quest",
  OTHER = "other",
}

export const inventoryData: {
  type: InventoryType;
  name: string;
  filter: ItemType[];
}[] = [
  {
    type: InventoryType.ALL,
    name: "전체",
    filter: [
      ItemType.CONSUME,
      ItemType.WEAPON,
      ItemType.ACCESSORY,
      ItemType.ARMOR,
      ItemType.EXCHANGE,
      ItemType.GACHA,
      ItemType.INGREDIANT,
      ItemType.OTHER,
      ItemType.QUEST,
      ItemType.SKILLBOOK,
      ItemType.OUTFIT,
    ],
  },
  {
    type: InventoryType.EQUIP,
    name: "장비",
    filter: [ItemType.WEAPON, ItemType.ACCESSORY, ItemType.ARMOR],
  },
  {
    type: InventoryType.CONSUME,
    name: "소모품",
    filter: [
      ItemType.CONSUME,
      ItemType.SKILLBOOK,
      ItemType.GACHA,
      ItemType.OUTFIT,
    ],
  },
  {
    type: InventoryType.INGREDIANT,
    name: "재료",
    filter: [ItemType.INGREDIANT],
  },
  {
    type: InventoryType.QUEST,
    name: "퀘스트",
    filter: [ItemType.QUEST],
  },
  {
    type: InventoryType.OTHER,
    name: "기타",
    filter: [ItemType.OTHER, ItemType.EXCHANGE],
  },
];

export const itemTypes: { [key in ItemType]: string } = {
  [ItemType.CONSUME]: "소비",
  [ItemType.ARMOR]: "갑옷",
  [ItemType.ACCESSORY]: "악세서리",
  [ItemType.WEAPON]: "무기",
  [ItemType.INGREDIANT]: "재료",
  [ItemType.QUEST]: "퀘스트",
  [ItemType.EXCHANGE]: "환전",
  [ItemType.SKILLBOOK]: "스킬북",
  [ItemType.OUTFIT]: "의상",
  [ItemType.GACHA]: "뽑기",
  [ItemType.OTHER]: "기타",
};

export type Item<T = ItemData, U = ItemType> = {
  type: U;
  data: T;
  name: string;
  description: string;
  icon: string;
  sell: number;
  buy: number;
} & DBRecord;

export type ItemData = ConsumeItemData | SkillBookItemData | GachaItemData;

export type GachaItemData = {
  items: {
    item_id: number;
    amount: number;
    rate: number;
  }[];
};
export type ConsumeItemData = Partial<
  { custom: string; lifetime: number } & Status
>;
export type SkillBookItemData = {
  skills: number[];
};

export type Recipe = {
  item_id: number;
  description: string;
  time: number;
  show: 0 | 1;
} & DBRecord;

export type ActiveRecipe = Item &
  Recipe & { ingrediants: ActiveIngrediant[]; item_desc: string };

export type Ingrediant = {
  recipe_id: number;
  item_id: number;
  amount: number;
} & DBRecord;
export type ActiveIngrediant = Item & Ingrediant;

export type Craft = {
  recipe_id: number;
  user_id: number;
  character_id: number;
  completed: 0 | 1;
  recipe?: ActiveRecipe;
} & DBRecord;

export type Shop = {
  item_id: number;
  category: string;
  comment: string;
  discount: number;
  limit: number;
  limit_rule: string;
  start_timestamp: string;
  end_timestamp: string;
} & DBRecord;

export enum ShopItemLimitRule {
  PERSON = "p",
  DAY = "d",
  STOCK = "s",
  PERSON_DAY = "pd",
}

export const shopItemLimitRules: { [key in ShopItemLimitRule]: string } = {
  [ShopItemLimitRule.PERSON]: "한 사람당",
  [ShopItemLimitRule.DAY]: "하루에",
  [ShopItemLimitRule.STOCK]: "총 재고",
  [ShopItemLimitRule.PERSON_DAY]: "하루 한 사람당",
};

export type ActiveShop = Item & Shop & { item_id: number; stock?: number };

export type Inventory = {
  user_id: number;
  character_id: number;
  item_id: number;
  amount: number;
} & DBRecord;

export type Enforce = {
  inventory_id: number;
  enforce_id: number;
} & DBRecord;

export type ActiveEnforce = Item & Enforce & { item_id: number };

export type ActiveInventory = Item &
  Inventory & { enforces?: ActiveEnforce[]; skills?: Skill[] };

export type ItemReward = {
  item_id: number;
  amount: number;
  item?: Item;
};
